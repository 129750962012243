export const validateLogin = (values) => {
  console.log('login detail', values);
  const loginerrors = {};
  let isValid = true;
  const EmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!values.email.replace(/ /g, '')) {
    loginerrors.email = 'Email is required';
    isValid = false;
  } else if (!EmailRegex.test(values.email)) {
    loginerrors.email = 'Email address is invalid';
    isValid = false;
  }
  if (!values.password.replace(/ /g, '')) {
    loginerrors.password = 'Password is required';

    isValid = false;
  } else if (values.password.length < 5) {
    loginerrors.password = 'Please Enter a valid Password';
    isValid = false;
  } else if (values.password.length > 15) {
    loginerrors.password = 'Please Enter a valid Password';
    isValid = false;
  }
  return { isValid, loginerrors };
};
export const validateRegister = (values) => {
  const errors = {};
  let isValid = true;
  const EmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  if (!values.firstname.replace(/ /g, '')) {
    errors.firstname = 'firstname is required';
    isValid = false;
  } else if (values.firstname.length < 2) {
    errors.firstname = 'firstname should be greater than 2 characters';
    isValid = false;
  } else if (values.firstname.length > 25) {
    errors.firstname = 'firstname should be less than 25 characters';
    isValid = false;
  }
  if (!values.lastname.replace(/ /g, '')) {
    errors.lastname = 'lastname is required';
    isValid = false;
  } else if (values.lastname.length < 2) {
    errors.lastname = 'lastname should be greater than 2 characters';
    isValid = false;
  } else if (values.lastname.length > 25) {
    errors.lastname = 'lastname should be less than 25 characters';
    isValid = false;
  }
  if (!values.email.replace(/ /g, '')) {
    errors.email = 'Email is required';
    isValid = false;
  } else if (
    !isNaN(values.email.trim() ? values.email.split('@')[0] : null) &&
    !isNaN(
      values.email.trim() ? values.email.split('@')[1].split('.')[0] : null
    )
  ) {
    errors.email = 'Invalid Email';
    isValid = false;
  } else if (!EmailRegex.test(values.email)) {
    errors.email = 'Invalid Email';
    isValid = false;
  }
  if (!values.password.replace(/ /g, '')) {
    errors.password = 'Password is required';
    isValid = false;
  } else if (!passwordRegex.test(values.password)) {
    errors.password =
      'The password must be at least 8 characters long, and you must use at least one uppercase and one number';
    isValid = false;
  }
  if (!values.checked2) {
    console.log('come herere');
    errors.checked2 = 'Please accept terms & conditions';
    isValid = false;
  }

  console.log(values.checked2);
  return { isValid, errors };
};
// Partner validate
export const validatePartnerRegister = (values) => {
  const errors = {};
  let isValid = true;
  const EmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  if (!values.firstname.replace(/ /g, '')) {
    errors.firstname = 'firstname is required';
    isValid = false;
  } else if (values.firstname.length < 2) {
    errors.firstname = 'firstname should be greater than 2 characters';
    isValid = false;
  } else if (values.firstname.length > 25) {
    errors.firstname = 'firstname should be less than 25 characters';
    isValid = false;
  }
  if (!values.lastname.replace(/ /g, '')) {
    errors.lastname = 'lastname is required';
    isValid = false;
  } else if (values.lastname.length < 2) {
    errors.lastname = 'lastname should be greater than 2 characters';
    isValid = false;
  } else if (values.lastname.length > 25) {
    errors.lastname = 'lastname should be less than 25 characters';
    isValid = false;
  }
  if (!values.email.replace(/ /g, '')) {
    errors.email = 'Email is required';
    isValid = false;
  } else if (
    !isNaN(values.email.trim() ? values.email.split('@')[0] : null) &&
    !isNaN(
      values.email.trim() ? values.email.split('@')[1].split('.')[0] : null
    )
  ) {
    errors.email = 'Invalid Email';
    isValid = false;
  } else if (!EmailRegex.test(values.email)) {
    errors.email = 'Invalid Email';
    isValid = false;
  }
  if (!values.password.replace(/ /g, '')) {
    errors.password = 'Password is required';
    isValid = false;
  } else if (!passwordRegex.test(values.password)) {
    errors.password =
      'The password must be at least 8 characters long, and you must use at least one uppercase and one number';
    isValid = false;
  }
  if (!values.checked2) {
    console.log('come herere');
    errors.checked2 = 'Please accept terms & conditions';
    isValid = false;
  }
  if (!values.companyName.replace(/ /g, '')) {
    errors.companyName = 'companyName is required';
    isValid = false;
  } else if (values.companyName.length < 3) {
    errors.companyName = 'companyname should be greater than 2 characters';
    isValid = false;
  } else if (values.companyName.length > 60) {
    errors.companyName = 'companyname should be less than 60 characters';
    isValid = false;
  }
  console.log(values.checked2);
  return { isValid, errors };
};

export const validateForget = (values) => {
  const errors = {};
  let isValid = true;
  const EmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!values.email.replace(/ /g, '')) {
    errors.email = 'Email is required';
    isValid = false;
  } else if (
    !isNaN(values.email.trim() ? values.email.split('@')[0] : null) &&
    !isNaN(
      values.email.trim() ? values.email.split('@')[1].split('.')[0] : null
    )
  ) {
    errors.email = 'Invalid Email';
    isValid = false;
  } else if (!EmailRegex.test(values.email)) {
    errors.email = 'Invalid Email';
    isValid = false;
  }
  return { isValid, errors };
};

export const validateReset = (values) => {
  const reseterrors = {};
  let isValid = true;
  // const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  if (!values.password.replace(/ /g, '')) {
    reseterrors.password = 'Password is required';
    isValid = false;
  } else if (!passwordRegex.test(values.password)) {
    reseterrors.password =
      'The password must be at least 8 characters long, and you must use at least one uppercase and one number';
    isValid = false;
  }
  if (!values.repeatPassword) {
    reseterrors.repeatPassword = 'Confirm password is required';
    isValid = false;
  } else if (values.password !== values.repeatPassword) {
    reseterrors.repeatPassword = 'Confirm password does not match';
    isValid = false;
  }
  return { isValid, reseterrors };
};

export const validateService = (values) => {
  console.log(values);
  const errors = {};
  let isValid = true;
  if (!values.category) {
    errors.category = 'Please select a Category';
    isValid = false;
  }
  if (!values.servicename.replace(/ /g, '')) {
    errors.servicename = 'Servicename is required';
    isValid = false;
  }
  // if (!values.category.replace(/ /g, '')) {
  //   errors.category = 'Service Category is required';
  //   isValid = false;
  // }
  if (values.staff.length === 0) {
    errors.staff = 'Staff is required';
    isValid = false;
  }

  if (!values.priceFrom.replace(/ /g, '')) {
    errors.priceFrom = 'Price is required';
    isValid = false;
  }
  // if (!values.staff.trim()) {
  // errors.staff = 'Please Select Minimum One Staff';
  // isValid = false;
  // }

  return { isValid, errors };
};
export const validateShiftTiming = (values) => {
  const errors = {};
  let isValid = true;
  const startarr = [];
  const endarr = [];

  values.shiftPanel.map((el) => {
    console.log(el);
    startarr.push(el.starttime);
    endarr.push(el.endtime);
  });
  console.log('startarr & endarr', startarr, endarr);
  if (startarr[1] <= endarr[0] && startarr[1] >= startarr[0]) {
    errors.shiftPanel = 'Shifts are overlapping';
    isValid = false;
  }
  console.log('errors', errors);
  return { isValid, errors };
};

export const validateStaff = (values) => {
  const errors = {};
  let isValid = true;
  if (!values.firstname.replace(/ /g, '')) {
    errors.firstname = 'Firstname is required';
    isValid = false;
  } 
  if (!values.email.replace(/ /g, '')) {
    errors.email = 'Email is required';
    isValid = false;
  }

  return { isValid, errors };
};
export const validateInvoice = (values) => {
  const errors = {};
  let isValid = true;
  if (!values.title) {
    errors.title = 'Title is required';
    isValid = false;
  }

  return { isValid, errors };
};

export const validateTax = (values) => {
  const errors = {};
  let isValid = true;
  if (!values.taxName.replace(/ /g, '')) {
    errors.taxName = 'Tax name is required';
    isValid = false;
  }
  if (!values.taxRate.replace(/ /g, '')) {
    errors.taxRate = 'Tax rate is required';
    isValid = false;
  }

  return { isValid, errors };
};
export const validateDiscountType = (values) => {
  const errors = {};
  let isValid = true;

  if (!isNaN(values.discountName)) {
    errors.discountName = 'Discount name should be a alphabet';
    isValid = false;
  }
  if (!values.discountName.replace(/ /g, '')) {
    errors.discountName = 'Discount name is required';
    isValid = false;
  }

  if (!values.discountValue.replace(/ /g, '')) {
    errors.discountValue = 'Discount value is required';
    isValid = false;
  }

  if (isNaN(values.discountValue)) {
    errors.discountValue = 'Discount Value should be a number';
    isValid = false;
  }

  return { isValid, errors };
};

export const validateAccountSetting = (values) => {
  const errors = {};
  let isValid = true;
  if (!values.companyName.replace(/ /g, '')) {
    errors.companyName = 'Business Name is required';
    isValid = false;
  }

  return { isValid, errors };
};

export const validateInvoiceSequencing = (values) => {
  const errors = {};
  let isValid = true;
  if (!values.invoiceno) {
    errors.invoiceno = 'Invoice No. is required';
    isValid = false;
  }

  if (isNaN(values.invoiceno)) {
    errors.invoiceno = 'Please Enter Valid Invoice Number';
    isValid = false;
  }

  if (!values.invoiceprefix) {
    errors.invoiceprefix = 'Invoice Prefix is required';
    isValid = false;
  }

  return { isValid, errors };
};

export const validatePackage = (values) => {
  const errors = {};
  let isValid = true;

  if (!values.packagename.replace(/ /g, '')) {
    errors.packagename = 'Packagename is required';
    isValid = false;
  }
  if (!values.category) {
    errors.category = 'Package Category is required';
    isValid = false;
  }
  // if (!values.servicename.replace(/ /g, '')) {
  // errors.servicename = 'servicename is required';
  // isValid = false;
  // }
  if (values.servicePanel.length < 2) {
    errors.servicePanel = 'Atleast 2 services must be added';
    isValid = false;
  }
  // if (!values.priceFrom.replace(/ /g, '')) {
  // errors.priceFrom = 'Price is required';
  // isValid = false;
  // }
  // if (!values.staff.trim()) {
  // errors.staff = 'Please Select Minimum One Staff';
  // isValid = false;
  // }

  return { isValid, errors };
};
export const validatePaymentType = (values) => {
  const errors = {};
  let isValid = true;
  if (!values.paymentname.replace(/ /g, '')) {
    errors.paymentname = 'Payment name is required';
    isValid = false;
  }

  if (!isNaN(values.paymentname)) {
    errors.paymentname = 'Please Enter Valid Payment Name';
    isValid = false;
  }

  return { isValid, errors };
};

export const validateCategory = (values) => {
  const errors = {};
  let isValid = true;
  if (!values.categoryname.replace(/ /g, '')) {
    errors.categoryname = 'category name is required';
    isValid = false;
  } else if (values.categoryname.length < 2) {
    errors.categoryname = 'category name should be greater than 1 character';
    isValid = false;
  } else if (values.categoryname.length > 50) {
    errors.categoryname = 'category name should be less than 25 characters';
    isValid = false;
  }

  return { isValid, errors };
};
export const validatePopup = (values) => {
  const regExp = /(\d{1,2})\/(\d{1,2})\/(\d{2,4})/;
  const errors = {};
  let isValid = true;
  if (!values.startDate) {
    errors.startDate = 'Start Date is required';
    isValid = false;
  }
  if (!values.endDate) {
    errors.endDate = 'End Date is required';
    isValid = false;
  } else if (
    Math.round(
      (new Date(values.endDate) - new Date(values.startDate)) /
        (1000 * 60 * 60 * 24)
    ) < 0
  ) {
    errors.endDate = 'End Date Should be greater than Start Date';
    isValid = false;
  }
  if (!values.description) {
    errors.description = 'Description is required';
    isValid = false;
  }
  return { isValid, errors };
};
