import React, { useState } from 'react';
import '../partners.css';
import PartnerHeader from '../partnerheader/header';
import PartnerMenu from '../partnermenu/menu';
import Helmet from 'react-helmet';

const PartnerWrapper = (props) => {
  const [menutoggle, setMenuToggle] = useState(false);
  // const [username, setUsername] = useState('');
  // const [isPartnerLogin, setIsPartnerLogin] = useState(
  //   localStorage.getItem('partnertoken')
  // );
  // const PartnerLogOut = () => {
  //   localStorage.removeItem('partnertoken');
  //   setIsPartnerLogin(null);
  //   history.push('/partners/login');
  // };
  // useEffect(() => {
  //   const getPartnerData = async () => {
  //     const partnerdata = await partnerInstance().get('/validateToken');
  //     console.log(partnerdata);
  //     let { code, userData } = partnerdata.data;
  //     if (code === 200) {
  //       console.log('userData', userData);
  //       setUsername(userData.firstname);
  //     }
  //   };
  //   getPartnerData();

  return (
    <div className="admin-wrapper">
      <Helmet>
        <body className="admin-view" />
      </Helmet>

      <div className="admin-content-view">
        <PartnerMenu menutoggle={menutoggle} />

        <div
          className={`admin-container ${menutoggle ? `wrapper-expand` : ``}`}
        >
          <div className="header-view">
            <PartnerHeader
              setMenuToggle={setMenuToggle}
              menutoggle={menutoggle}
            />
          </div>

          <div className="admin-content">{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default PartnerWrapper;
