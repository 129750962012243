import React from "react";
import Card from "react-bootstrap/Card";
import PartnerWrapper from "../partnerwrapper/wrapper";
import { Link } from "react-router-dom";

import "./style.css";

const SetUp = () => {
  return (
    <PartnerWrapper>
      <div className="setup">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="account-card">
                        <h2>Configuration</h2>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    <Link className="text-Primary" to="/partners/accountsetting">Business Partner Setup </Link>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="account-card">
                        <h2>Billing Configuration</h2>
                                <Card>
                                    <Card.Body>
                                        <Link className="text-Primary " to="/partners/invoice">Invoice </Link>    
                                    </Card.Body>
                                </Card>          
                                            {/* <Link className="text-Primary " to="/partners/invoice-sequencing">Invoice Sequencing</Link> */}
                                                
                                <Card>
                                    <Card.Body>
                                         <Link className="text-Primary " to="/partners/taxes">Taxes</Link>    
                                    </Card.Body>
                                </Card>

                                <Card>
                                    <Card.Body>
                                         <Link className="text-Primary " to="/partners/payment-taxes">Payment Types</Link>   
                                    </Card.Body>
                                </Card>  

                                 <Card>
                                    <Card.Body>
                                        <Link className="text-Primary " to="/partners/discount-type">Discount</Link>  
                                    </Card.Body>
                                </Card>            

                                            
                                                
                   
                          
                    </div>
                </div>
            </div>
        </div>
    </div>

    </PartnerWrapper>
  );
};

export default SetUp;
