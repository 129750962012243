import React, { useEffect, useState } from 'react';
import { Form, Button, Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { partnerInstance } from '../../../config/axios';
import { validatePartnerRegister } from '../../../function/validate';
import { countrycode } from './code';

const PartnerRegister = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [country, setCountry] = useState('');
  const [language, setLanguage] = useState('English');
  const [currency, setCurrency] = useState('EUR');
  const [currencyName, setCurrencyName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [errors, setErrors] = useState({});
  const [checked2, setChecked2] = useState(false);
  const [countries, setCountries] = useState({});
  const [timezone, setTimezone] = useState('');
  const [timezones, setTimezones] = useState({});
  const [passType, setPassType] = useState('password');
  const [businessTypes, setBusinessTypes] = useState([]);
  const [businessType, setBusinessType] = useState('Hair Saloon');
  const [staffShift, setStaffShift] = useState([]);
  const currencyDataa = [
    'European euro - EUR',
    'Albanian lek - ALL',
    'Afghan afghani - AFN',
    'Algerian dinar - DZD',
    'United States dollar - USD',
    'Angolan kwanza - AOA',
    'East Caribbean dollar - XCD',
    'Argentine peso - ARS',
    'Armenian dram - AMD',
    'Aruban florin - AWG',
    'Saint Helena pound - SHP',
    'Australian dollar - AUD',
    'Azerbaijan manat - AZN',
    'Bahamian dollar - BSD',
    'Bahraini dinar - BHD',
    'Bangladeshi taka - BDT',
    'Barbadian dollar - BBD',
    'Belarusian ruble - BYN',
    'Belize dollar - BZD',
    'West African CFA franc - XOF',
    'Bermudian dollar - BMD',
    'Bhutanese ngultrum - BTN',
    'Bolivian boliviano - BOB',
    'United States dollar - USD',
    'Bosnia and Herzegovina convertible mark - BAM',
    'Botswana pula - BWP',
    'Brazilian real - BRL',
    'Brunei dollar - BND',
    'Bulgarian lev - BGN',
    'West African CFA franc - XOF',
    'Burundi franc - BIF',
    'Cabo Verdean escudo - CVE',
    'Cambodian riel - KHR',
    'SCentral African CFA franc - XAF',
    'Canadian dollar - CAD',
    'Cayman Islands dollar - KYD',
    'Central African CFA franc - XAF',
    'Central African CFA franc - XAF',
    'New Zealand dollar - NZD',
    'Chilean peso - CLP',
    'Chinese Yuan Renminbi - CNY',
    'Australian dollar - AUD',
    'Colombian peso - COP',
    'Comorian franc - KMF',
    'Congolese franc - CDF',
    'Central African CFA franc - XAF',
    'Costa Rican colon - CRC',
    'West African CFA franc - XOF',
  ];
  useEffect(() => {
    const getBusinessType = async () => {
      const businessData = await partnerInstance().get('/getBusinessType');
      let { code, businessType } = businessData.data;
      if (code === 200) {
        setBusinessTypes(businessType);
      }
    };

    const getCountries = async () => {
      const countryData = await partnerInstance().get('/countries');
      let { code, countriesdata } = countryData.data;
      if (code === 200) {
        setCountries(countriesdata);
      }
    };
    const getTimezones = async () => {
      const timezoneData = await partnerInstance().get('/timezones');
      let { code, timezonesdata } = timezoneData.data;
      if (code === 200) {
        setTimezones(timezonesdata);
      }
    };
    const getPhoneCode = async () => {
      countrycode.map((el) => {
        if (el.name === country) {
          setPhoneCode(el.code);
        }
      });
    };
    const getCountry = async () => {
      // const currTimezone = timezone;
      const payload2 = {
        name: currentTimeZone,
      };

      const TimezoneData = await partnerInstance().post(
        '/countryByTimezone',
        payload2
      );
      let { code, countrydata } = TimezoneData.data;
      if (code === 200) {
        const countryid = countrydata.country;
        const payload3 = {
          id: countryid,
        };
        const countryName = await partnerInstance().post(
          '/timezoneByCountry',
          payload3
        );
        let { code, timezone } = countryName.data;
        if (code === 200) {
          const countryname = timezone.name;
          setCountry(countryname);
        }
      }
    };
    getPhoneCode();
    getBusinessType();
    getTimezones();
    getCountries();
    let currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    getCountry();
    setTimezone(currentTimeZone);
  }, [country]);

  const handleCountrySelect = async (e) => {
    e.preventDefault();
    const countryName = e.target.value;
    setCountry(countryName);
    Object.keys(countries).map((country) => {
      if (countries[country].name === countryName) {
        setTimezone(countries[country].timezones[0]);
      }
    });
  };
  const handleCurrency = (e) => {
    const x = e.split('-');
    const currencyCode = x[1];
    setCurrency(currencyCode);
    setCurrencyName(e);
  };
  const selectPhoneCode = (item) => {
    setPhoneCode(item);
  };

  const signUp = async (e) => {
    e.preventDefault();
    // Default Working Hour
    const arr = [];
    const getDaysArray = function (start, end) {
      for (
        const dt = new Date(start);
        dt <= end;
        dt.setDate(dt.getDate() + 1)
      ) {
        arr.push(new Date(dt));
      }
      return arr;
    };

    const daylist = getDaysArray(
      new Date('2020-05-03'),
      new Date('2020-07-01')
    );
    const oldArray = [...staffShift];
    daylist.forEach((el) => {
      const timing = [{ starttime: '09:00AM', endtime: '06:00PM' }];
      const staffshift = { date: new Date(el).toDateString(), timing };
      oldArray.push(staffshift);
    });
    const payload = {
      firstname,
      lastname,
      email,
      password,
      country,
      language,
      currency,
      timezone,
      companyName,
      businessType,
      phone,
      phoneCode,
      shift: oldArray,
    };
    const validation = validatePartnerRegister({
      firstname: firstname,
      lastname: lastname,
      email: email,
      phone: phone,
      password: password,
      checked2: checked2,
      companyName: companyName,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    const saveData = await partnerInstance().post('/partnerSignup', payload);
    const { code, msg } = saveData.data;
    if (code === 200) {
      toast.success(msg, { containerId: 'B' });
      clearValues();
    } else if (code === 409) {
      toast.error(msg, { containerId: 'B' });
    } else {
      toast.error(msg, { containerId: 'B' });
    }
  };
  const clearValues = () => {
    setEmail('');
    setPassword('');
    setFirstname('');
    setLastname('');
    setChecked2('');
    setPhone('');
  };

  return (
    <div className="signup">
      <div className="register-section">
        <div className="container back-color">
          <div className="row">
            <div className="col-md-12">
              <div className="register-form">
                <h1>Create your free partner account</h1>
                <div className="rigister-tab">
                  <Form onSubmit={signUp}>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group controlId="formBasicFirst">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Your first name"
                            name="firstName"
                            autocomplete="off"
                            onChange={(e) => setFirstname(e.target.value)}
                            value={firstname}
                          />
                          {errors.firstname && (
                            <p className="error">{errors.firstname}</p>
                          )}
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Your last name"
                            name="nickName"
                            autocomplete="off"
                            onChange={(e) => setLastname(e.target.value)}
                            value={lastname}
                          />
                          {errors.lastname && (
                            <p className="error">{errors.lastname}</p>
                          )}
                        </Form.Group>
                      </div>

                      <div className="col-md-12">
                        <Form.Group controlId="formBasicPassword">
                          <Form.Label>Email address</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Your email address"
                            name="email"
                            autocomplete="off"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                          />
                          {errors.email && (
                            <p className="error">{errors.email}</p>
                          )}
                        </Form.Group>
                      </div>

                      <div className="col-md-12">
                        <Form.Group controlId="formBasicPassword">
                          <Form.Label>Mobile number</Form.Label>
                          <div className="number-fields">
                            <Dropdown>
                              <Dropdown.Toggle id="dropdown-basic">
                                {phoneCode}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {countrycode.map((el) => {
                                  return (
                                    <Dropdown.Item
                                      key={el.id}
                                      onClick={() => {
                                        selectPhoneCode(el.code);
                                      }}
                                    >
                                      {' '}
                                      {el.code}
                                    </Dropdown.Item>
                                  );
                                })}
                              </Dropdown.Menu>
                            </Dropdown>
                            <Form.Control
                              type="number"
                              placeholder="Your mobile number"
                              name="email"
                              autocomplete="off"
                              onChange={(e) => setPhone(e.target.value)}
                              value={phone}
                            />
                          </div>
                          {errors.phone && (
                            <p className="error">{errors.phone}</p>
                          )}
                        </Form.Group>
                      </div>

                      <div className="col-md-12">
                        <Form.Group controlId="formBasicPassword">
                          <Form.Label>Password</Form.Label>
                          <div className="password-show">
                            <Form.Control
                              type={passType}
                              placeholder="Create a password"
                              name="password"
                              autocomplete="off"
                              onChange={(e) => setPassword(e.target.value)}
                              value={password}
                            />
                            <i
                              className={
                                passType === 'password'
                                  ? 'fa fa-eye-slash'
                                  : 'fa fa-eye'
                              }
                              onClick={() =>
                                setPassType(
                                  passType === 'password' ? 'text' : 'password'
                                )
                              }
                            ></i>
                          </div>
                          {errors.password && (
                            <p className="error">{errors.password}</p>
                          )}
                        </Form.Group>
                      </div>

                      <div className="col-md-6">
                        <Form.Group controlId="formBasicFirst">
                          <Form.Label>Company Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Your company name"
                            name="companyName"
                            autocomplete="off"
                            onChange={(e) => setCompanyName(e.target.value)}
                            value={companyName}
                          />
                          {errors.companyName && (
                            <p className="error">{errors.companyName}</p>
                          )}
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Business type</Form.Label>
                          <Form.Control
                            as="select"
                            defaultValue={businessType}
                            placeholder="Business Type"
                            onChange={(e) => setBusinessType(e.target.value)}
                            value={businessType}
                            selected={businessType}
                          >
                            <option value="Hair Saloon">Hair Saloon</option>
                            {businessTypes.map((item, i) => (
                              <option key={i} value={item._id}>
                                {item.businessname}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group controlId="formBasicFirst">
                          <Form.Label>Country</Form.Label>
                          <Form.Control
                            as="select"
                            defaultValue={country}
                            placeholder="Select Country"
                            selected={country}
                            value={country}
                            onChange={(e) => handleCountrySelect(e)}
                            // onSelect={(value) => handleCountrySelect(value)}
                          >
                            {Object.keys(countries).map((el, i) => (
                              <option>{countries[el].name}</option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>TimeZone</Form.Label>
                          <Form.Control
                            as="select"
                            defaultValue={timezone}
                            selected={timezone}
                            value={timezone}
                            onChange={(e) => setTimezone(e.target.value)}
                          >
                            {Object.keys(timezones).map((el, i) => (
                              <option value={timezones[el].name}>
                                {timezones[el].name}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Currency</Form.Label>
                          <Form.Control
                            as="select"
                            defaultValue={currencyName}
                            onChange={(e) => handleCurrency(e.target.value)}
                            value={currencyName}
                            selected={currencyName}
                          >
                            {currencyDataa.map((item, i) => (
                              <option key={i}>{item}</option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                        {/* {currency && (
                          <SelectCurrency
                            value={currency}
                            defaultValue={currency}
                            onCurrencySelected={currency}
                            onChange={(e) => setCurrency(e.target.value)}
                            selected={currency}
                            placeholder="ggg"
                          />
                        )} */}
                      </div>
                      <div className="col-md-6">
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Language</Form.Label>
                          <Form.Control
                            as="select"
                            onChange={(e) => setLanguage(e.target.value)}
                            value={language}
                            selected="English"
                          >
                            <option value="English" selected>
                              English
                            </option>
                            <option value="Soumi">Soumi</option>
                            <option value="Polski">Polski</option>
                            <option value="Netherlands">Netherlands</option>
                          </Form.Control>
                        </Form.Group>
                      </div>

                      <div className="col-md-12">
                        <Form.Group
                          controlId="formBasicCheckbox"
                          className="dis-show"
                          name="tnc"
                          checked=""
                        >
                          <Form.Check
                            type="checkbox"
                            name="terms"
                            checked={checked2}
                            onChange={() => setChecked2(!checked2)}
                          />
                          <p>
                            Yes, I agree to the{' '}
                            <Link className="terms-link" to={'/'}>
                              Terms of Service
                            </Link>{' '}
                            and{' '}
                            <Link className="terms-link" to={'/'}>
                              Privacy Policy
                            </Link>
                            .
                          </p>
                        </Form.Group>
                        {errors.checked2 && (
                          <span className="error">{errors.checked2}</span>
                        )}
                      </div>

                      <div className="col-md-12">
                        <div className="text-center">
                          <Button className="s-btn" type="submit">
                            Register
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>

                  <p>
                    Already have partner account?{' '}
                    <Link className="signup-link" to={'/partners/login'}>
                      Log in
                    </Link>{' '}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerRegister;
