import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import logo from '../../../assets/partner/logo.png';

const PartnerMenu = ({ menutoggle }) => (
  <div className={`admin-menu ${menutoggle ? 'menu-hide' : ''}`}>
    <div className="menu-logo">
      <h2>
        <img src={logo} alt="" />
      </h2>
    </div>

    <div className="admin-menu-box">
      <div className="menu-list">
        <ul>
        <Nav>
          <NavLink to="/partners/dashboard">
            <i className="fa fa-home" /> Dashboard
          </NavLink>

          <NavLink to="/partners/appointments">
            <i className="fa fa-calendar" /> Appointments
          </NavLink>

          <NavLink to="/partners/services">
            <i className="fa fa-file-alt" /> Services
          </NavLink>

          <NavLink to="/partners/staff">
            <i className="fa fa-briefcase" /> Associates
          </NavLink>

          <NavLink to="/partners/voucher">
            <i className="fa fa-gift" /> Voucher
          </NavLink>

          <NavLink to="/partners/revenue">
            <i className="fa fa-chart-bar" /> Revenue
          </NavLink>

          <Link to="/partners/customers">
            <i className="fa fa-user" /> Customers
          </Link>

          <Link to="/partners/inventory">
            <i className="fa fa-store" /> Inventory
          </Link>


          <NavLink to="/partners/setup">
            <i className="fa fa-cog" /> Configuration
          </NavLink>

          

          </Nav>
        </ul>
      </div>
    </div>
  </div>
);

export default PartnerMenu;
