import React, { useState, useEffect, useCallback } from 'react';
import {
  Form,
  Table,
  Dropdown,
  Button,
  Tab,
  FormControl,
} from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { toast } from 'react-toastify';
import moment from 'moment';
import RangePicker from 'react-range-picker';
import PartnerWrapper from '../partnerwrapper/wrapper';
import { partnerInstance } from '../../../config/axios';
// import { countrycode } from '../partners/register/code';
import { validateStaff } from '../../../function/validate';
import history from '../../../config/history';
import { countrycode } from '../register/code';

const array = [];
const AddStaff = () => {
  const [email, setEmail] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [staffTitle, setStaffTitle] = useState('');
  const [note, setNote] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [errors, setErrors] = useState({});
  const [checked2, setChecked2] = useState(false);
  const [userPermission, setUserPermission] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [appointmentBooking, setAppointmentBooking] = useState(false);
  const [serviceid, setServiceId] = useState([]);
  const [services, setServices] = useState([]);
  const [serviceCommission, setServiceCommission] = useState('');
  const [voucherCommission, setVoucherCommission] = useState('');
  const [selectAll, setSelectAll] = useState(true);
  const [checked, setChecked] = useState(false);
  const currentDate = moment(new Date()).format('YYYY-MM-DD');
  const [startEmployementDate, setStartEmployementDate] = useState(currentDate);
  const [endEmployementDate, setEndEmployementDate] = useState('');
  const [country, setCountry] = useState('');
  const [address, setAddress] = useState([]);
  const [staffShift, setStaffShift] = useState([]);
  const [weekStart, setWeekStart] = useState('');
  const [shiftStartDate, setShiftStartDate] = useState('');

  useEffect(() => {
    const currentUser = async () => {
      const countryy = await partnerInstance().get('/getPartnerAccount');
      const { code, partnerData } = countryy.data;
      if (code === 200) {
        setWeekStart(partnerData.weekStart);
        setAddress(partnerData.address);
        /* set start day for asssociate working hour */
        if (partnerData.weekStart) {
          const weekstart =
            partnerData.weekStart.charAt(0).toUpperCase() +
            partnerData.weekStart.slice(1);
          const a = new Date('2020-05-03');
          console.log('a', a);
          const b = new Date(a);
          b.setDate(a.getDate() + 1);
          const aa = new Date(b);
          const bb = new Date(aa);
          bb.setDate(aa.getDate() + 1);
          const aaa = new Date(bb);
          const bbb = new Date(aaa);
          bbb.setDate(aaa.getDate() + 1);

          const aaaa = new Date(bbb);
          const bbbb = new Date(aaaa);
          bbbb.setDate(aaaa.getDate() + 1);

          const aaaaa = new Date(bbbb);
          const bbbbb = new Date(aaaaa);
          bbbbb.setDate(aaaa.getDate() + 1);

          const aaaaaa = new Date(bbbbb);
          const bbbbbb = new Date(aaaaaa);
          bbbbbb.setDate(aaaaa.getDate() + 1);

          if (weekstart.substring(0, 3) === a.toString().substring(0, 3)) {
            setShiftStartDate(a);
            console.log('sunday');
          } else if (
            weekstart.substring(0, 3) === b.toString().substring(0, 3)
          ) {
            setShiftStartDate(b);
            console.log('Monday', b);
          } else if (
            weekstart.substring(0, 3) === bb.toString().substring(0, 3)
          ) {
            setShiftStartDate(bb);
            console.log('Tuesday', bb);
          } else if (
            weekstart.substring(0, 3) === bbb.toString().substring(0, 3)
          ) {
            setShiftStartDate(bbb);
            console.log('Wednesday', bbb);
          } else if (
            weekstart.substring(0, 3) === bbbb.toString().substring(0, 3)
          ) {
            setShiftStartDate(bbbb);
            console.log('Thursday', bbbb);
          } else if (
            weekstart.substring(0, 3) === bbbbb.toString().substring(0, 3)
          ) {
            setShiftStartDate(bbbbb);
            console.log('Friday', bbbbb);
          } else {
            console.log('Saturday');
          }
        }
        /* END set Start day for associate working hour */

        countrycode.map((el, i) => {
          partnerData.address.forEach((e) => {
            if (el.name === e.country) {
              setPhoneCode(el.code);
            }
          });
        });
      }
    };

    // const getPhoneCode = async () => {
    //   // address.map((el, i) => {
    //   //   setCountry(el.country);
    //   // });
    // };
    const getServices = async () => {
      const servicesData = await partnerInstance().get('/getServicesOnly');
      const { code, services: Services } = servicesData.data;
      if (code === 200) {
        Services.forEach((el) => {
          el.checked = true;
          // sarray.push(el._id); there is no need to push ids in another array
        });
        // setServiceId(sarray);
        setServices(Services);
        //   const data = [];
        //   Services.forEach((el) => {
        //     data.push(el.serviceid);
        //   });
        //   setServices(data);
        // }
      }
    };
    currentUser();
    getServices();
    // getPhoneCode();
  }, []);

  const addStaff = async (e) => {
    const arrayofid = [];
    e.preventDefault();

    // Default Working Hour
    const arr = [];
    const getDaysArray = function (start, end) {
      for (
        const dt = new Date(start);
        dt <= end;
        dt.setDate(dt.getDate() + 1)
      ) {
        arr.push(new Date(dt));
      }
      return arr;
    };

    // const a = new Date('2020-05-03');
    // const b = new Date(a);
    // b.setDate(a.getDate() + i);
    // const c = b;
    // console.log('nextdate', c);

    // console.log(
    //   'INITIAL DATE',
    //   new Date('2020-05-03').toString().substring(0, 3)
    // );
    // const a = new Date('2020-05-03');
    // const b = new Date(a);
    // b.setDate(a.getDate() + 1);
    // const c = b;
    // console.log('nextdate', c);
    console.log('shiftStartDate =>', shiftStartDate);
    let start;
    if (shiftStartDate) {
      console.log('shift start datecomes');
      start = moment(shiftStartDate).format('YYYY-MM-DD');
    } else {
      console.log('shift start not datecomes');
      start = '2020-05-01';
    }

    console.log('start', start);

    const daylist = getDaysArray(
      new Date('2020-05-01'),
      new Date('2020-07-01')
    );
    const oldArray = [...staffShift];
    daylist.forEach((el) => {
      const timing = [{ starttime: '09:00AM', endtime: '06:00PM' }];
      const staffshift = { date: new Date(el).toDateString(), timing };
      oldArray.push(staffshift);
    });
    console.log('oldArray', oldArray);

    // Try to understand this code simple stuff
    const filterSelectedIds = services.filter((el) => el.checked);
    filterSelectedIds.forEach((el) => {
      arrayofid.push(el._id);
    });

    const payload = {
      firstname,
      lastname,
      email,
      staffTitle,
      note,
      phone,
      phoneCode,
      userPermission: userPermission || 'No Access',
      startDate,
      endDate,
      appointmentBooking,
      serviceid: arrayofid,
      serviceCommission,
      voucherCommission,
      startEmployementDate,
      endEmployementDate,
      shift: oldArray,
      // checked: false,
    };
    console.log('paayload', payload);
    const validation = validateStaff({
      firstname,
      email,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    const saveData = await partnerInstance().post('/addStaff', payload);
    const { code, msg } = saveData.data;
    if (code === 200) {
      toast.success(msg, { containerId: 'B' });
      history.push('/partners/staff');
      clearValues();
    } else if (code === 409) {
      toast.error(msg, { containerId: 'B' });
    } else {
      toast.error(msg, { containerId: 'B' });
    }
  };
  const clearValues = () => {
    setEmail('');
    setFirstname('');
    setLastname('');
    setChecked2('');
    setPhone('');
  };
  function useUpdate() {
    const [, setTick] = useState(0);
    const update = useCallback(() => {
      setTick((tick) => tick + 1);
    }, []);
    return update;
  }

  const update = useUpdate();
  const selectPhoneCode = (item) => {
    setPhoneCode(item);
  };
  const handleCheck = (e, el) => {
    const oldState = [...services];
    oldState.forEach((element) => {
      const { checked, _id } = element;
      if (el._id === _id) {
        element.checked = !checked;
      }
    });
    setServices(services);
    update();
  };
  const handleSelectAll = (e) => {
    setSelectAll(!selectAll);
    services.forEach((el) => {
      if (selectAll) {
        services.forEach((element) => {
          el.checked = false;
          array.pop(element);
        });
        setServices(array);
      } else {
        services.forEach((el) => {
          el.checked = true;
          array.push(el._id);
        });
        setServiceId(services);
        update();
      }
    });
    setServices(services);
    update();
    // setSelectAll(!selectAll);
    // if (e.target.value === 'true') {
    //   services.forEach((element) => {
    //     // element.checked = false;
    //     setChecked(false);
    //     array.pop(element);
    //   });
    //   setServices(services);

    //   // setCheckedBooking([]);
    // } else {
    //   // setSelectAllCheck(true);
    //   services.forEach((el) => {
    //     // el.checked = true;
    //     setChecked(true);
    //     array.push(el._id);
    //   });
    //   setServices(services);
    // }
    // console.log('select all array', array);
  };

  // const handleMenu = (e) => {
  //   console.log(e.target.checked, 'checkedddd');
  // };

  return (
    <PartnerWrapper>
      <div className="add-staff">
        <div className="container">
          <h2 className="admin-title">Add Staff</h2>

          <div className="add-staff-content">
            <Form onSubmit={addStaff}>
              <Tabs defaultActiveKey="0" id="staff-tab-example">
                <Tab eventKey="0" title="Details">
                  <div className="add-staff-details">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6">
                            <Form.Group controlId="formBasicFirst">
                              <Form.Label>First Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Your first name"
                                name="firstName"
                                autocomplete="off"
                                onChange={(e) => setFirstname(e.target.value)}
                                value={firstname}
                              />
                              {errors.firstname && (
                                <p className="error">{errors.firstname}</p>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-md-6">
                            <Form.Group controlId="formBasicEmail">
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Your last name"
                                name="nickName"
                                autocomplete="off"
                                onChange={(e) => setLastname(e.target.value)}
                                value={lastname}
                              />
                            </Form.Group>
                          </div>

                          <div className="col-md-12">
                            <Form.Group controlId="formBasicPassword">
                              <Form.Label>Mobile number</Form.Label>
                              <div className="number-fields">
                                <Dropdown>
                                  <Dropdown.Toggle id="dropdown-basic">
                                    {phoneCode}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {countrycode.map((el) => (
                                      <Dropdown.Item
                                        key={el.id}
                                        onClick={() => {
                                          selectPhoneCode(el.code);
                                        }}
                                      >
                                        {' '}
                                        {el.code}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                                <Form.Control
                                  type="number"
                                  placeholder="Your mobile number"
                                  name="email"
                                  autocomplete="off"
                                  onChange={(e) => setPhone(e.target.value)}
                                  value={phone}
                                />
                              </div>
                              {errors.phone && (
                                <p className="error">{errors.phone}</p>
                              )}
                            </Form.Group>
                          </div>

                          <div className="col-md-12">
                            <Form.Group controlId="formBasicPassword">
                              <Form.Label>Email address</Form.Label>
                              <Form.Control
                                type="email"
                                placeholder="Your email address"
                                name="email"
                                autoComplete="off"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                              />
                              {errors.email && (
                                <p className="error">{errors.email}</p>
                              )}
                            </Form.Group>
                          </div>

                          <div className="col-md-12">
                            <Form.Group controlId="formBasicEmail">
                              <Form.Label>Permission Level</Form.Label>
                              <Form.Control
                                as="select"
                                onChange={(e) =>
                                  setUserPermission(e.target.value)
                                }
                              >
                                <option value="no">No Access</option>
                                <option value="basic">Basic</option>
                                <option value="low">Low</option>
                                <option value="medium">Medium</option>
                                <option value="high">High</option>
                              </Form.Control>
                            </Form.Group>
                          </div>

                          <div className="col-md-12">
                            <div className="online-booking">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    setAppointmentBooking(!appointmentBooking)
                                  }
                                  checked={appointmentBooking}
                                  // onClick={handleMenu}
                                />
                                <span className="slider round" />
                              </label>
                              Enable Appointments Booking
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-12">
                            <Form.Group controlId="formBasicPassword">
                              <Form.Label>Staff Title</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Staff Title"
                                name="text"
                                autoComplete="off"
                                onChange={(e) => setStaffTitle(e.target.value)}
                                value={staffTitle}
                              />
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Notes</Form.Label>
                              <textarea
                                className="form-control text optional employee-modal-note"
                                rows="12"
                                placeholder="Add Notes"
                                name="employee[notes]"
                                id="employee_notes"
                                onChange={(e) => setNote(e.target.value)}
                                value={note}
                              />
                            </Form.Group>
                            <Form.Group>
                              <Row>
                                <Form.Group className="col-md-6">
                                  <Form.Label>Start Date</Form.Label>
                                  <Form.Control
                                    type="date"
                                    id="birthday"
                                    name="birthday"
                                    default={startEmployementDate}
                                    value={startEmployementDate}
                                    onChange={(e) =>
                                      setStartEmployementDate(e.target.value)
                                    }
                                  />
                                </Form.Group>

                                <Form.Group className="col-md-6">
                                  <Form.Label>End Date</Form.Label>
                                  <Form.Control
                                    type="date"
                                    id="birthday"
                                    name="birthday"
                                    minDate={startEmployementDate}
                                    onChange={(e) =>
                                      setEndEmployementDate(e.target.value)
                                    }
                                    value={endEmployementDate}
                                  />
                                </Form.Group>
                              </Row>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>

                <Tab eventKey="1" title="Services">
                  <div className="add-services">
                    <p className="text-muted ">
                      Assign services this staff member can perform.
                    </p>
                    <Form>
                      <div className="row">
                        <div className="col-md-12">
                          <Form.Group>
                            <InputGroup.Prepend>
                              <InputGroup.Checkbox
                                checked={selectAll}
                                onChange={handleSelectAll}
                              />
                              <Form.Label className="sel-1">
                                Select All
                              </Form.Label>
                            </InputGroup.Prepend>
                          </Form.Group>
                        </div>

                        {/* {Object.keys(services).map((el) => (
                          <>
                            {' '}
                            <div className="col-sm-4">
                              <Form.Group>
                                <InputGroup.Prepend>
                                  <InputGroup.Checkbox />
                                  <Form.Label>
                                    {services[el].servicename}
                                  </Form.Label>
                                </InputGroup.Prepend>
                              </Form.Group>
                            </div>
                          </>
                        ))} */}
                        {services.map((item, i) => (
                          <div className="col-sm-4" key={i}>
                            <Form.Group>
                              <InputGroup.Prepend>
                                <InputGroup.Checkbox
                                  checked={item.checked}
                                  value={item._id}
                                  onClick={(e) => handleCheck(e, item)}
                                />
                                <Form.Label>{item.servicename}</Form.Label>
                              </InputGroup.Prepend>
                            </Form.Group>
                          </div>
                        ))}
                      </div>
                    </Form>
                  </div>
                </Tab>

                <Tab eventKey="2" title="Commission">
                  <div className="add-commission">
                    <Form>
                      <div className="row">
                        <div className="col-sm-6">
                          <Form.Label>Service Commission</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              placeholder="0.00"
                              type="number"
                              aria-label="Amount (to the nearest dollar)"
                              onChange={(e) =>
                                setServiceCommission(e.target.value)
                              }
                              value={serviceCommission}
                            />
                          </InputGroup>
                        </div>

                        {/* <div className="col-sm-4">
                          <Form.Label>PRODUCT COMMISSION</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              placeholder="0.00"
                              aria-label="Amount (to the nearest dollar)"
                            />
                          </InputGroup>
                        </div> */}

                        <div className="col-sm-6">
                          <Form.Label>Voucher Commission</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              placeholder="0.00"
                              type="number"
                              aria-label="Amount (to the nearest dollar)"
                              onChange={(e) =>
                                setVoucherCommission(e.target.value)
                              }
                              value={voucherCommission}
                            />
                          </InputGroup>
                        </div>
                      </div>
                    </Form>
                  </div>
                </Tab>
              </Tabs>

              <div className="row">
                <div className="col-md-12 ">
                  <div className="text-center mt">
                    <Button className="s-btn" type="submit">
                      Save
                    </Button>
                    <Button className="s-btn c-tn" type="submit">
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </PartnerWrapper>
  );
};

export default AddStaff;
