import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { Button } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import { toast } from 'react-toastify';
import moment from 'moment';
import PartnerWrapper from '../partnerwrapper/wrapper';
import Discount from '../../../assets/discount.svg';
import { partnerInstance } from '../../../config/axios';
import { validateDiscountType } from '../../../function/validate';
import './style.css';

const DiscountType = () => {
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [discountName, setDiscountName] = useState('');
  const [discountValue, setDiscountValue] = useState('');
  const [invoiceno, setInvoiceNo] = useState('');
  const [errors, setErrors] = useState({});
  const [voucherSale, setVoucherSale] = useState(true);
  const [serviceSale, setServiceSale] = useState(true);
  const [discountType, setDiscountType] = useState('%');
  const [discountTypes, setDiscountTypes] = useState([]);
  const currentDate = moment(new Date()).format('YYYY-MM-DD');
  const [discountId, setDiscountId] = useState('');

  useEffect(() => {
    getDiscountType();
  }, []);

  // get discount type
  const getDiscountType = async () => {
    const discount = await partnerInstance().get('/getDiscountType');
    const { code, discountTypeData } = discount.data;
    if (code === 200) {
      setDiscountTypes(discountTypeData);
      // setDiscountName(discountData.discountName);
      // setDiscountValue(discountData.discountValue);
      // setDiscountType(discountData.discountType);
      // setInvoiceNo(invoiceSequencingData.invoiceno);
    }
  };

  // called on change of discount type (% or INR)
  const handleDiscountType = (e) => {
    setDiscountType(e);
  };
  // add discount type
  const addDiscountType = async (e) => {
    e.preventDefault();
    const payload = {
      discountName,
      discountValue,
      discountType,
      voucherSale,
      serviceSale,
      currentDate,
    };
    const validation = validateDiscountType({
      discountName,
      discountValue,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    const discountData = await partnerInstance().post(
      '/addDiscountType',
      payload,
    );
    const { code, msg } = discountData.data;
    if (code === 200) {
      getDiscountType();
      toast.success(msg, { containerId: 'B' });
      setShow(false);
      // getInvoiceSequencing();
      // history.push('/partners/staff');
      clearValues();
    } else {
      toast.error(msg, { containerId: 'B' });
    }
  };
  const clearValues = () => {
    setDiscountName('');
    setDiscountValue('');
  };
  // Close edit modal
  const handleHide = () => {
    setShowEdit(false);
    setShowDelete(false);
  };

  // For pass data to edit modal
  const handleShowEdit = (
    id,
    discountname,
    discountvalue,
    discountvaluetype,
  ) => {
    setDiscountId(id);
    setDiscountName(discountname);
    setDiscountValue(discountvalue);
    setDiscountType(discountvaluetype);
    setShowEdit(true);
    setErrors('');
  };

  const handleShowDelete = (
    id,
  ) => {
    setDiscountId(id);
    setShowDelete(true);
   
  };

  return (
    <PartnerWrapper>
      <div className="discount-type">
        <div className="container">
          <div className="row">
            <div className="col-md-12">

                  <h2 className="admin-title">Discount Types
     
                     <Button onClick={handleShow}>Add Discount</Button>
                  </h2>
              
              <div className="partner-table dic-info">
                {discountTypes.length > 0 ? (
                  <>
                    <Table responsive="lg">
                      <thead>
                        <tr>
                          <th>Discount Name</th>
                          <th>Discount Value</th>
                          <th>Discount Value Type</th>
                          <th>Discount Date</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {discountTypes.map((el, i) => (
                          <tr>
                            <td>{el.discountname}</td>
                            <td>{el.discountvalue}</td>
                            <td>{el.discountvaluetype}</td>
                            <td>{el.discountdate}</td>
                            <td>
                              <Button
                                onClick={() => handleShowEdit(
                                  el._id,
                                  el.discountname,
                                  el.discountvalue,
                                  el.discountvaluetype,
                                  el.taxrate,
                                )}
                              >
                                <i className="fa fa-pencil-square-o" />
                                {' '}
                               
                              </Button>
                            </td>
                            <td>
                              <Button
                                onClick={() => handleShowDelete(
                                  el._id,
                                )}
                              >
                                <i className="fa fa-trash" />
                                {' '}
                               
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </>
                ) : (
                  <>
                    <Card>
                      <Card.Img variant="top" />
                      <Card.Body>
                        <Card.Title />
                        <Card.Text>
                          <div className="tax-head">
                            <img src={Discount} className="mb-2" />
                            <h5>No Discount</h5>
                            <p className="text-muted ">
                              You do not have any discounts yet, create new
                              discount to apply it on checkout
                            </p>
                            <Button
                              onClick={handleShow}
                            >
                              {' '}
                              Add Discount
                            </Button>
                          </div>
                          <div>
                            <h1 />
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} className="discount-12">
        <Modal.Header closeButton>
          <div />
          <Modal.Title id="example-custom-modal-styling-title ">
            New Discount Type
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={addDiscountType}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Discount name</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                placeholder="Invoice"
                onChange={(e) => setDiscountName(e.target.value)}
                value={discountName}
              />
              {errors.discountName && (
                <p className="error">{errors.discountName}</p>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Label>Discount value</Form.Label>
              <Row>
                <Col md={7}>
                  <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                      <InputGroup.Text>{discountType}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      id="inlineFormInputGroup"
                      type="number"
                      placeholder=""
                      onChange={(e) => setDiscountValue(e.target.value)}
                      value={discountValue}
                    />
                  </InputGroup>
                </Col>
                <Col md={4}>
                  <div
                    className="btn-group btn-group-toggle"
                    data-toggle="buttons"
                  >
                    {discountType === 'INR' ? (
                      <>
                        <Button
                          onClick={(e) => handleDiscountType(e.target.value)}
                          value="INR"
                          className="btn btn-primary active1"
                        >
                          INR
                        </Button>
                        <Button
                          onClick={(e) => handleDiscountType(e.target.value)}
                          value="%"
                          className="btn "
                        >
                          %
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          onClick={(e) => handleDiscountType(e.target.value)}
                          value="INR"
                          className="btn "
                        >
                          INR
                        </Button>
                        <Button
                          onClick={(e) => handleDiscountType(e.target.value)}
                          value="%"
                          className="btn btn-primary active1"
                        >
                          %
                        </Button>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
              {errors.discountValue && (
                <p className="error">{errors.discountValue}</p>
              )}
            </Form.Group>

            <Form.Group>
              <InputGroup.Prepend>
                <InputGroup.Checkbox
                  onChange={() => setServiceSale(!serviceSale)}
                  checked={serviceSale}
                />
                <Form.Label className="">Discount on Service</Form.Label>
              </InputGroup.Prepend>
            </Form.Group>

            <Form.Group>
              <InputGroup.Prepend>
                <InputGroup.Checkbox
                  onChange={() => setVoucherSale(!voucherSale)}
                  checked={voucherSale}
                />
                <Form.Label className="">Discount on Voucher</Form.Label>
              </InputGroup.Prepend>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={showEdit} onHide={handleHide} className="discount-12">
        <Modal.Header closeButton>
          <div />
          <Modal.Title id="example-custom-modal-styling-title ">
            EDIT DISCOUNT TYPE
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <EditDiscountType
            discountId={discountId}
            discountName={discountName}
            discountValue={discountValue}
            discountType={discountType}
            setDiscountName={setDiscountName}
            setDiscountValue={setDiscountValue}
            setDiscountType={setDiscountType}
            setShowEdit={setShowEdit}
            getDiscountType={() => getDiscountType()}
            setErrors={setErrors}
            errors={errors}
            handleDiscountType={handleDiscountType}
          />
        </Modal.Body>
        <Modal.Footer />
      </Modal>

      <Modal show={showDelete} onHide={handleHide} className="discount-12">
        <Modal.Header closeButton>
          <div />
          <Modal.Title id="example-custom-modal-styling-title ">
            DELETE DISCOUNT TYPE
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <DeleteDiscountType
            discountId={discountId}
            setShowDelete={setShowDelete}
            getDiscountType={() => getDiscountType()}
          />
        </Modal.Body>
        <Modal.Footer />
      </Modal>

    </PartnerWrapper>
  );
};

export default DiscountType;

function EditDiscountType({
  handleDiscountType,
  discountId,
  discountName,
  setDiscountName,
  discountValue,
  setDiscountValue,
  discountType,
  setDiscountType,
  setShowEdit,
  getDiscountType,
  setErrors,
  errors,
}) {
  console.log('discountType', discountType);
  // console.log(setShowEdit, categoryId);
  // const [categoryname, setCategoryname] = useState('');
  // const [categorydescription, setCategorydescription] = useState('');
  // const [errors, setErrors] = useState({});
  const updateDiscountType = async (e) => {
    e.preventDefault();
    const payloadd = {
      discountName,
      discountValue,
      discountType,
    };
    const validation = validateDiscountType({
      discountName,
      discountValue,
      discountType,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }

    const updateDiscount = await partnerInstance().put(
      `/editDiscountType/${discountId}`,
      payloadd,
    );
    const { code, msg } = updateDiscount.data;
    if (code === 200) {
      setShowEdit(false);
      getDiscountType();
      toast.success(msg, { containerId: 'B' });
      clearValues();
    } else if (code === 409) {
      toast.error(msg, { containerId: 'B' });
    } else {
      toast.error(msg, { containerId: 'B' });
    }
  };
  const clearValues = () => {
    setDiscountName('');
    setDiscountValue('');
  };

  return (
    <Form onSubmit={updateDiscountType}>
      <Form.Group>
        <Form.Label>Discount name</Form.Label>
        <Form.Control
          size="lg"
          type="text"
          placeholder="Invoice"
          onChange={(e) => setDiscountName(e.target.value)}
          value={discountName}
        />
        {errors.discountName && <p className="error">{errors.discountName}</p>}
      </Form.Group>

      <Form.Group>
        <Form.Label>Discount value</Form.Label>
        <Row>
          <Col md={7}>
            <InputGroup className="mb-2">
              <InputGroup.Prepend>
                <InputGroup.Text>{discountType}</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                id="inlineFormInputGroup"
                placeholder=""
                onChange={(e) => setDiscountValue(e.target.value)}
                value={discountValue}
              />
              {errors.discountValue && (
                <p className="error">{errors.discountValue}</p>
              )}
            </InputGroup>
          </Col>
          <Col md={4}>
            <div className="btn-group btn-group-toggle" data-toggle="buttons">
              <Button
                onClick={(e) => handleDiscountType(e.target.value)}
                value="INR"
                className="btn btn-primary active1"
              >
                INR
              </Button>
              <Button
                onClick={(e) => handleDiscountType(e.target.value)}
                value="%"
                className="btn "
              >
                %
              </Button>
            </div>
          </Col>
        </Row>
      </Form.Group>

      {/* <Form.Group>
        <InputGroup.Prepend>
          <InputGroup.Checkbox
            onChange={() => setServiceSale(!serviceSale)}
            checked={serviceSale}
          />
          <Form.Label className="">Enable For Service Sales</Form.Label>
        </InputGroup.Prepend>
      </Form.Group>

      <Form.Group>
        <InputGroup.Prepend>
          <InputGroup.Checkbox
            onChange={() => setVoucherSale(!voucherSale)}
            checked={voucherSale}
          />
          <Form.Label className="">Enable For Voucher Sales</Form.Label>
        </InputGroup.Prepend>
      </Form.Group> */}
      <Button variant="primary" type="submit">
        Save
      </Button>
    </Form>
  );
}

function DeleteDiscountType({
  discountId,
  getDiscountType,
  setShowDelete,
}) {
  const deleteIds = async (e) => {
    e.preventDefault();
    const payloadd = {
      deleteId: discountId,
    };
   console.log(payloadd)
    const deleteDiscount = await partnerInstance().post(
      '/deleteDiscountType/',
      payloadd,
    );

    const { code, msg } = deleteDiscount.data;
    if (code === 200) {
      setShowDelete(false);
      getDiscountType();
      toast.success(msg, { containerId: 'B' });
    } else if (code === 409) {
      toast.error(msg, { containerId: 'B' });
    } else {
      toast.error(msg, { containerId: 'B' });
    }
  };

  return (
    <Form onSubmit={deleteIds}>
    <p>Are You sure you want to delete ?</p>
      <Button variant="primary" type="submit">
        Delete
      </Button>
    </Form>
  );
}
