import React, { useEffect, useState } from 'react';
import './header.css';
import { Navbar, Form, Dropdown, Button, FormControl } from 'react-bootstrap';
import logo from '../../assets/logo.png';
import toggle from '../../assets/menu.png';
import close from '../../assets/close.png';
import user from '../../assets/user.jpg';
import { Link } from 'react-router-dom';
// import { userInstance } from '../../config/axios';

const Header = ({ isLogin, LogOut, username }) => {
  //   const [username, setUsername] = useState('');
  const [menutoggle, setMenuToggle] = useState(false);
  const [menu, setMenu] = useState(false);
  const handleMenu = (e) => {
    if (e === `open`) {
      setMenu(true);
    } else if (e === `close`) {
      setMenu(false);
    } else if (e === `toggle`) {
      setMenu(!menu);
    }
  };
  //   useEffect(() => {
  //     const getData = async () => {
  //       const data = await userInstance().get('/validateToken');
  //       let { code, userData } = data.data;
  //       console.log('headerdata-->', data.data);
  //       if (code === 200) {
  //         setUsername(userData.firstname);
  //       }
  //       //   else {
  //       //     toast.error(msg, { containerId: 'B' });
  //       //   }
  //     };
  //     getData();
  //   }, [username]);

  return (
    <div className="header">
      <div className="container">
        <Navbar expand="lg">
          <div className="header-left">
            <div className="toggle">
              <a
                href="javaScript:void(0)"
                onClick={() => setMenuToggle(!menutoggle)}
              >
                <img src={toggle} alt="menu" />
              </a>
            </div>

            <Navbar.Brand href="/">
              <img src={logo} />
            </Navbar.Brand>
          </div>

          <div className="header-right">
            <div className="search-container">
              <Form inline>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">London</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="/">America</Dropdown.Item>
                    <Dropdown.Item href="/">China</Dropdown.Item>
                    <Dropdown.Item href="/">Russia</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <FormControl
                  type="text"
                  placeholder="Search for a service or venue"
                  className="mr-sm-2"
                />
                <Button>
                  <i className="fa fa-search"></i>
                </Button>
              </Form>
            </div>

            <div className="author__access_area">
              {/* Not Login */}

              {/* <ul className="d-flex list-unstyled align-items-center">
                            <li>
                                <Link to="/login" className="access-link">Log in</Link>
                                <span> | </span>
                                <Link to="/register" className="access-link" >Sign up</Link>
                            </li>
                        </ul> */}

              {/* After Login */}
              {isLogin ? (
                <div className="my-profile-menu">
                  <div className="p-pic">
                    <img src={user} alt="" />
                  </div>
                  <div className="p-name">
                    <h5>{username}</h5>
                    <div className={`nav-item dropdown ${menu ? `show` : ``}`}>
                      <a
                        className="nav-link dropdown-toggle"
                        onClick={() => handleMenu(`toggle`)}
                      >
                        My Profile
                      </a>
                      <div className={`dropdown-menu ${menu ? `show` : ``}`}>
                        <a className="dropdown-item">My Profile</a>
                        <a className="dropdown-item" onClick={() => LogOut()}>
                          Logout
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <ul className="d-flex list-unstyled align-items-center">
                  <li>
                    <Link to="/" className="access-link">
                      Log in
                    </Link>
                    <span> | </span>
                    <Link to="/" className="access-link">
                      Sign up
                    </Link>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </Navbar>
      </div>

      <div className={`sidebar-wrapper ${menutoggle ? `expand` : ``}`}>
        <div className="sidebar-menu">
          <div className={`close-icon ${menutoggle ? `show` : ``}`}>
            <a
              href="javaScript:void(0)"
              onClick={() => setMenuToggle(!menutoggle)}
            >
              <img src={close} alt="close" />
            </a>
          </div>

          <div className="side-nav-content">
            <div className="row ">
              <div className="col-lg-5 col-xl-6 col-12">
                <ul className="main-navigation">
                  <li>
                    <Link to="/">Customer support</Link>
                  </li>
                  <li>
                    <Link to="/">Download our app</Link>
                  </li>
                  <li>
                    <Link to="/">English</Link>
                  </li>
                  <li>
                    <Link to="/">FOR PROFESSIONALS</Link>
                  </li>
                  <li>
                    <Link to="/">Pricing</Link>
                  </li>
                  <li>
                    <Link to="/">Careers at Omar</Link>
                  </li>
                </ul>
              </div>

              <div className="col-lg-7 col-xl-6 col-12">
                <div className="axil-contact-info-inner">
                  <div className="axil-contact-info">
                    <address className="address">
                      <span className="title">Contact Information</span>
                      <p>
                        Lorem ipsum, Ap #76-756 <br></br> Sit Rd, Azusa New York
                      </p>
                    </address>
                    <address className="address">
                      <span className="title">
                        We're Available 24/ 7. Call Now.
                      </span>
                      <p>
                        <a className="tel" href="tel:8884562790">
                          <i className="fa fa-phone"></i>(888) 123-45678
                        </a>
                      </p>
                      <p>
                        <a className="tel" href="tel:12125553333">
                          <i className="fa fa-fax"></i>(121) 987-654321
                        </a>
                      </p>
                    </address>
                  </div>

                  <div className="axil-contact-info">
                    <h5 className="title">Find us here</h5>
                    <div className="contact-social-share d-flex align-items-center">
                      <ul className="social-share style-rounded">
                        <li>
                          <a href="#">
                            <i className="fa fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-linkedin"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
