import React, { useState } from 'react';
import './register.css';
import { Form, Button, Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { userInstance } from '../../config/axios';
import { validateRegister } from '../../function/validate';
import { countrycode } from '../partners/register/code';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneCode, setPhoneCode] = useState('+91');
  const [errors, setErrors] = useState({});
  const [checked2, setChecked2] = useState(false);
  const [passType, setPassType] = useState('password');

  const signUp = async (e) => {
    e.preventDefault();
    const payload = {
      firstname,
      lastname,
      email,
      password,
      phoneCode,
      phone,
    };
    const validation = validateRegister({
      firstname: firstname,
      lastname: lastname,
      email: email,
      phone: phone,
      password: password,
      checked2: checked2,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    const saveData = await userInstance().post('/signup', payload);
    const { code, msg } = saveData.data;
    if (code === 200) {
      toast.success(msg, { containerId: 'B' });
      clearValues();
    } else if (code === 409) {
      toast.error(msg, { containerId: 'B' });
    } else {
      toast.error(msg, { containerId: 'B' });
    }
  };
  const clearValues = () => {
    setEmail('');
    setPassword('');
    setFirstname('');
    setLastname('');
    setChecked2('');
    setPhone('');
  };
  const selectPhoneCode = (item) => {
    setPhoneCode(item);
  };

  return (
    <div className="signup">
      <div className="register-section">
        <div className="container back-color">
          <div className="rigister-tab">
            <Form onSubmit={signUp}>
              <div className="row">
                <div className="col-md-12">
                  <div className="register-form">
                    <h1>Sign up to book</h1>
                    <div className="rigister-tab">
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group controlId="formBasicFirst">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Your first name"
                              name="firstName"
                              autocomplete="off"
                              onChange={(e) => setFirstname(e.target.value)}
                              value={firstname}
                            />
                            {errors.firstname && (
                              <p className="error">{errors.firstname}</p>
                            )}
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Your last name"
                              name="nickName"
                              autocomplete="off"
                              onChange={(e) => setLastname(e.target.value)}
                              value={lastname}
                            />
                            {errors.lastname && (
                              <p className="error">{errors.lastname}</p>
                            )}
                          </Form.Group>
                        </div>

                        <div className="col-md-12">
                          <Form.Group controlId="formBasicPassword">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Your email address"
                              name="email"
                              autoComplete="off"
                              onChange={(e) => setEmail(e.target.value)}
                              value={email}
                            />
                            {errors.email && (
                              <p className="error">{errors.email}</p>
                            )}
                          </Form.Group>
                        </div>

                        <div className="col-md-12">
                          <Form.Group controlId="formBasicPassword">
                            <Form.Label>Mobile number</Form.Label>
                            <div className="number-fields">
                              <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                  {phoneCode}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {countrycode.map((el) => {
                                    return (
                                      <Dropdown.Item
                                        key={el.id}
                                        onClick={() => {
                                          selectPhoneCode(el.code);
                                        }}
                                      >
                                        {' '}
                                        {el.code}
                                      </Dropdown.Item>
                                    );
                                  })}
                                </Dropdown.Menu>
                              </Dropdown>
                              <Form.Control
                                type="number"
                                placeholder="Your mobile number"
                                name="phone"
                                autocomplete="off"
                                onChange={(e) => setPhone(e.target.value)}
                                value={phone}
                              />
                            </div>
                            {errors.phone && (
                              <p className="error">{errors.phone}</p>
                            )}
                          </Form.Group>
                        </div>

                        <div className="col-md-12">
                          <Form.Group controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <div className="password-show">
                              <Form.Control
                                type={passType}
                                placeholder="Create a password"
                                name="password"
                                autocomplete="off"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                              />
                              <i
                                className={
                                  passType === 'password'
                                    ? 'fa fa-eye-slash'
                                    : 'fa fa-eye'
                                }
                                onClick={() =>
                                  setPassType(
                                    passType === 'password'
                                      ? 'text'
                                      : 'password'
                                  )
                                }
                              ></i>
                            </div>
                            {errors.password && (
                              <p className="error">{errors.password}</p>
                            )}
                          </Form.Group>
                        </div>

                        <div className="col-md-12">
                          <Form.Group
                            controlId="formBasicCheckbox"
                            className="dis-show"
                            name="tnc"
                            checked=""
                          >
                            <Form.Check
                              type="checkbox"
                              name="terms"
                              checked={checked2}
                              onChange={() => setChecked2(!checked2)}
                            />
                            <p>
                              Yes, I agree to the{' '}
                              <Link className="terms-link" to={'/'}>
                                Terms of Service
                              </Link>{' '}
                              and{' '}
                              <Link className="terms-link" to={'/'}>
                                Privacy Policy
                              </Link>
                              .
                            </p>
                          </Form.Group>
                          {errors.checked2 && (
                            <span className="error">{errors.checked2}</span>
                          )}
                        </div>
                        <div className="col-md-12">
                          <div className="text-center">
                            <Button className="s-btn" type="submit">
                              Register
                            </Button>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <p>
                            Already have an account?{' '}
                            <Link className="signup-link" to={'/login'}>
                              Log in
                            </Link>{' '}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
