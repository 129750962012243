import React, { useState, useEffect, useCallback } from 'react';
import { Button, Form, Modal, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import { findAllByTestId } from '@testing-library/react';
import PartnerWrapper from '../partnerwrapper/wrapper';
import staffimg from '../../../assets/partner/staff.png';
import { partnerInstance } from '../../../config/axios';
import { validateService } from '../../../function/validate';
import history from '../../../config/history';

// const j = 1;
const array = [];
const EditService = ({ id }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [view, setView] = useState(false);
  const handleViewClose = () => setView(false);
  const handleViewShow = () => setView(true);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState('');
  const [serviceCategoryId, setServiceCategoryId] = useState('');
  const [serviceName, setServiceName] = useState('');
  const [serviceDescription, setServiceDescription] = useState('');
  const [errors, setErrors] = useState({});
  const [staffs, setStaffs] = useState([]);
  const [staff, setStaff] = useState([]);
  // const [onlineBooking, setOnlineBooking] = useState();
  const [outService, setOutService] = useState();
  const [staffCommission, setStaffCommission] = useState();
  const [extraDurationBefore, setExtraDurationBefore] = useState('15');
  const [extraDurationAfter, setExtraDurationAfter] = useState('15');
  const [voucherSale, setVoucherSale] = useState('');
  const [serviceTax, setServiceTax] = useState('');
  const [voucherExpiry, setVoucherExpiry] = useState('');
  const [duration, setDuration] = useState('60');
  const [priceType, setPriceType] = useState('');
  const [priceFrom, setPriceFrom] = useState('');
  const [specialPrice, setSpecialPrice] = useState('');
  const [ID, setID] = useState('');
  const [editService, setEditService] = useState(false);
  const [hideVoucherBox, setHideVoucherBox] = useState(true);
  const [switchOpen, setSwitchOpen] = useState(false);
  const [disablePriceFrom, setDisablePriceFrom] = useState(false);
  const [disableSpecialPrice, setDisableSpecialPrice] = useState(false);
  const [selectAll, setSelectAll] = useState(true);
  const [validd, setValidd] = useState(true);
  const [serviceId, setServiceId] = useState('');
  const [outServiceExtraTime, setOutServiceExtraTime] = useState('');
  const [isPayment, setIsPayment] = useState(false);
  const [staffPricing, setStaffPricing] = useState([]);

  useEffect(() => {
    const value = queryString.parse(window.location.search);
    const { id } = value;
    setServiceId(id);
    const getCategories = async () => {
      const categoriesData = await partnerInstance().get('/getCategories');
      const { code, categories } = categoriesData.data;
      if (code === 200) {
        setCategories(categories);
      }
    };
    const getService = async () => {
      const idd = {
        serviceid: id,
      };
      setID(id);
      const serviceData = await partnerInstance().post('/getService', idd);
      const { code, service, stafflist } = serviceData.data;
      if (code === 200) {
        setEditService(true);
        setServiceName(service.serviceName);
        setServiceDescription(service.serviceDescription);
        setCategory(service.serviceCategory.categoryname);
        setServiceCategoryId(service.serviceCategory._id);
        // setOnlineBooking(service.onlineBooking);
        setOutService(service.outService);
        setStaff(service.staff);
        setPriceFrom(service.priceFrom);
        setSpecialPrice(service.specialPrice);
        // setStaff([...staff, service.staff]);
        setStaffCommission(service.staffCommission);
        setVoucherExpiry(service.voucherExpiry);
        setExtraDurationAfter(service.extraDurationAfter);
        setExtraDurationBefore(service.extraDurationBefore);
        setDuration(service.duration);
        setServiceTax(service.taxType);
        setIsPayment(service.isPayment);
        setOutServiceExtraTime(service.outServiceExtraTime);
        setStaffPricing(service.staffPricing);
        // setExtraTime(!!service.extraTime);
        // setExtraTimeSwitch(!!service.extraTime);
        // setExtraTimeStatus(service.extraTimeStatus);
        // setService(service);
        if (service.voucherExpiry) {
          setHideVoucherBox(false);
          setSwitchOpen(true);
        }

        stafflist.forEach((el) => {
          const filterSelected = service.staff.filter(
            (ele) => el._id === ele._id
          );

          if (filterSelected.length > 0) {
            el.checked = true;
          }
        });
        setStaffs(stafflist);
        update();
      }
    };

    // const getStaffs = async () => {
    //   const staffsData = await partnerInstance().get('/getStaffs');
    //   const { code, stafflist } = staffsData.data;
    //   if (code === 200) {
    //     stafflist.forEach((el) => {
    //       el.checked = true;
    //     });
    //     setStaffs(stafflist);
    //   }
    // };

    // getStaffs();
    getCategories();
    getService(id);
  }, []);

  function useUpdate() {
    const [, setTick] = useState(0);
    const update = useCallback(() => {
      setTick((tick) => tick + 1);
    }, []);
    return update;
  }
  const update = useUpdate();

  const selectPriceType = (e) => {
    setPriceType(e);
    if (e === 'free') {
      setDisablePriceFrom(true);
      setDisableSpecialPrice(true);
    } else {
      setDisablePriceFrom(false);
      setDisableSpecialPrice(false);
    }
  };

  const selectVoucherExpiry = (e) => {
    setVoucherExpiry(e.target.value);
  };

  const handleCheck = (e, el) => {
    const oldState = [...staffs];
    oldState.forEach((element) => {
      const { checked, _id } = element;
      if (el._id === _id) {
        element.checked = !checked;
      }
    });
    setStaffs(staffs);
    update();
  };
  const handleSelectAll = (e) => {
    setSelectAll(!selectAll);
    staffs.forEach((el) => {
      if (selectAll) {
        staffs.forEach((element) => {
          el.checked = false;
          array.pop(element);
        });
        setStaffs(array);
      } else {
        staffs.forEach((el) => {
          el.checked = true;
          array.push(el._id);
        });
        setStaff(staffs);
        update();
      }
    });
    setStaffs(staffs);
    update();
  };

  // const AddStaff = (e) => {
  //   setStaff([...staff, e.target.value]);
  // };
  let valid = true;
  const updateService = async (e) => {
    e.preventDefault();
    // const priceplan = {
    //   duration: duration,
    //   priceType: priceType,
    //   price: price,
    //   specialPrice: specialPrice,
    // };
    const arrayofid = [];
    e.preventDefault();
    // Try to understand this code simple stuff
    const filterSelectedIds = staffs.filter((el) => el.checked);
    filterSelectedIds.forEach((el) => {
      arrayofid.push(el._id);
    });

    let voucherexpiry;
    if (!switchOpen) {
      voucherexpiry = '';
    } else {
      voucherexpiry = voucherExpiry;
    }
    if (parseInt(priceFrom) <= parseInt(specialPrice)) {
      // setValid(false);
      valid = false;
      setValidd(valid);
    } else {
      // setValid(true);
      valid = true;
      setValidd(valid);
    }
    let outserviceextratime;
    if (outService) {
      outserviceextratime = outServiceExtraTime;
    } else {
      outserviceextratime = '';
    }

    const payload = {
      serviceId,
      serviceName,
      serviceDescription,
      serviceCategory: serviceCategoryId,
      extraDurationBefore,
      extraDurationAfter,
      duration,
      priceFrom,
      specialPrice,
      staff: arrayofid,
      voucherSale,
      staffCommission,
      taxType: serviceTax,
      voucherExpiry: voucherexpiry,
      outService,
      outServiceExtraTime: outserviceextratime,
      staffPricing,
      isPayment,
    };
    const validation = validateService({
      servicename: serviceName,
      category: serviceCategoryId,
      staff: arrayofid,
      priceFrom,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }

    const saveData = await partnerInstance().put(`/editService/${ID}`, payload);
    const { code, msg } = saveData.data;
    if (code === 200) {
      toast.success(msg, { containerId: 'B' });
      history.push('/partners/services');
      // clearValues();
    } else if (code === 409) {
      toast.error(msg, { containerId: 'B' });
    } else {
      toast.error(msg, { containerId: 'B' });
    }
  };

  const deleteService = async (id) => {
    const deleteService = await partnerInstance().post('/deleteService', {
      deleteId: id,
    });
    const { code, msg } = deleteService.data;
    if (code === 200) {
      toast.error(msg, { containerId: 'B' });
      history.push('/partners/services');
    }
  };
  // const clearValues = () => {
  //   setTreatmentType('');
  // };

  return (
    <PartnerWrapper>
      <div className="single-services">
        <div className="container">
          <h2 className="admin-title">
            {editService ? 'Edit Service' : 'Create a service'}
            <Link to="/partners/services">
              <i className="fa fa-angle-left" /> Back
            </Link>
          </h2>

          <div className="single-services-content">
            <Form onSubmit={updateService}>
              <div className="row">
                <div className="col-md-6">
                  <div className="service-card">
                    <div className="service-body">
                      <Form.Group controlId="formBasicloginone">
                        <Form.Label>Service Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Service name"
                          name="name"
                          autoComplete="off"
                          onChange={(e) => setServiceName(e.target.value)}
                          value={serviceName}
                        />
                        {errors.servicename && (
                          <p className="error">{errors.servicename}</p>
                        )}
                      </Form.Group>

                      <Form.Group controlId="formBasicloginone">
                        <Form.Label>Select Service Category</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={(e) => setServiceCategoryId(e.target.value)}
                          value={serviceCategoryId}
                        >
                          {/* <option value="">Select Category</option> */}
                          {categories.map((el, i) => (
                            <>
                              <option key={i} value={el._id}>
                                {el.categoryname}
                              </option>
                            </>
                          ))}
                        </Form.Control>
                        {errors.category && (
                          <p className="error">{errors.category}</p>
                        )}
                      </Form.Group>

                      <Form.Group controlId="formBasicloginone">
                        <Form.Label>Service Description</Form.Label>
                        <Form.Control
                          placeholder="Add a short description"
                          as="textarea"
                          rows="3"
                          onChange={(e) =>
                            setServiceDescription(e.target.value)
                          }
                          value={serviceDescription}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="service-card">
                    <div className="service-header">
                      <h2>Service Setup</h2>
                    </div>
                    <div className="service-body">
                      <div className="pricing-option">
                        <h5>Pricing</h5>

                        <div className="pricing-input">
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Duration</Form.Label>
                            <Form.Control
                              as="select"
                              onChange={(e) => setDuration(e.target.value)}
                              value={duration}
                            >
                              <option value="5">5min</option>
                              <option value="10">10min</option>
                              <option value="15">15min</option>
                              <option value="20">20min</option>
                              <option value="25">25min</option>
                              <option value="30">30min</option>
                              <option value="35">35min</option>
                              <option value="40">40min</option>
                              <option value="45">45min</option>
                              <option value="50">50min</option>
                              <option value="55">55min</option>
                              <option value="60" selected>
                                1h
                              </option>
                              <option value="65">1h 5min</option>
                              <option value="70">1h 10min</option>
                              <option value="75">1h 15min</option>
                              <option value="80">1h 20min</option>
                              <option value="85">1h 25min</option>
                              <option value="90">1h 30min</option>
                              <option value="95">1h 35min</option>
                              <option value="100">1h 40min</option>
                              <option value="105">1h 45min</option>
                              <option value="110">1h 50min</option>
                              <option value="115">1h 55min</option>
                              <option value="120">2h</option>
                              <option value="135">2h 15min</option>
                              <option value="150">2h 30min</option>
                              <option value="165">2h 45min</option>
                              <option value="180">3h</option>
                              <option value="195">3h 15min</option>
                              <option value="210">3h 30min</option>
                              <option value="225">3h 45min</option>
                              <option value="240">4h</option>
                              <option value="255">4h 15min</option>
                              <option value="310">4h 30min</option>
                              <option value="325">4h 45min</option>
                              <option value="340">5h</option>
                              <option value="355">5h 15min</option>
                              <option value="410">5h 30min</option>
                              <option value="425">5h 45min</option>
                              <option value="440">6h</option>
                              <option value="455">6h 15min</option>
                              <option value="510">6h 30min</option>
                              <option value="525">6h 45min</option>
                              <option value="540">7h</option>
                              <option value="555">7h 15min</option>
                              <option value="610">7h 30min</option>
                              <option value="625">7h 45min</option>
                              <option value="640">8h</option>
                              <option value="655">8h 15min</option>
                              <option value="710">8h 30min</option>
                              <option value="725">8h 45min</option>
                              <option value="740">9h</option>
                              <option value="755">9h 15min</option>
                              <option value="810">9h 30min</option>
                              <option value="825">9h 45min</option>
                              <option value="840">10h</option>
                              <option value="855">10h 15min</option>
                              <option value="910">10h 30min</option>
                              <option value="925">10h 45min</option>
                              <option value="940">11h</option>
                              <option value="955">11h 15min</option>
                              <option value="1010">11h 30min</option>
                              <option value="1025">11h 45min</option>
                              <option value="1040">12h</option>
                            </Form.Control>
                          </Form.Group>

                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Price Type</Form.Label>
                            <Form.Control
                              as="select"
                              onChange={(e) => selectPriceType(e.target.value)}
                            >
                              <option value="from">From</option>
                              <option value="free">Free</option>
                              <option value="fixed" default>
                                Fixed
                              </option>
                            </Form.Control>
                          </Form.Group>

                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Extra Time (Before)</Form.Label>
                            <Form.Control
                              as="select"
                              onChange={(e) =>
                                setExtraDurationBefore(e.target.value)
                              }
                              // onClick={addExtraDuration}
                              value={extraDurationBefore}
                            >
                              <option value="5">5min</option>
                              <option value="10">10min</option>
                              <option value="15" selected>
                                {' '}
                                15min
                              </option>
                              <option value="20">20min</option>
                              <option value="25">25min</option>
                              <option value="30">30min</option>
                              <option value="35">35min</option>
                              <option value="40">40min</option>
                              <option value="45">45min</option>
                              <option value="50">50min</option>
                              <option value="55">55min</option>
                              <option value="60">1h</option>
                              <option value="65">1h 5min</option>
                              <option value="70">1h 10min</option>
                              <option value="75">1h 15min</option>
                              <option value="80">1h 20min</option>
                              <option value="85">1h 25min</option>
                              <option value="90">1h 30min</option>
                              <option value="95">1h 35min</option>
                              <option value="100">1h 40min</option>
                              <option value="105">1h 45min</option>
                              <option value="110">1h 50min</option>
                              <option value="115">1h 55min</option>
                              <option value="120">2h</option>
                              <option value="135">2h 15min</option>
                              <option value="150">2h 30min</option>
                              <option value="165">2h 45min</option>
                              <option value="180">3h</option>
                              <option value="195">3h 15min</option>
                              <option value="210">3h 30min</option>
                              <option value="225">3h 45min</option>
                              <option value="240">4h</option>
                              <option value="255">4h 15min</option>
                              <option value="310">4h 30min</option>
                              <option value="325">4h 45min</option>
                              <option value="340">5h</option>
                              <option value="355">5h 15min</option>
                              <option value="410">5h 30min</option>
                              <option value="425">5h 45min</option>
                              <option value="440">6h</option>
                              <option value="455">6h 15min</option>
                              <option value="510">6h 30min</option>
                              <option value="525">6h 45min</option>
                              <option value="540">7h</option>
                              <option value="555">7h 15min</option>
                              <option value="610">7h 30min</option>
                              <option value="625">7h 45min</option>
                              <option value="640">8h</option>
                              <option value="655">8h 15min</option>
                              <option value="710">8h 30min</option>
                              <option value="725">8h 45min</option>
                              <option value="740">9h</option>
                              <option value="755">9h 15min</option>
                              <option value="810">9h 30min</option>
                              <option value="825">9h 45min</option>
                              <option value="840">10h</option>
                              <option value="855">10h 15min</option>
                              <option value="910">10h 30min</option>
                              <option value="925">10h 45min</option>
                              <option value="940">11h</option>
                              <option value="955">11h 15min</option>
                              <option value="1010">11h 30min</option>
                              <option value="1025">11h 45min</option>
                              <option value="1040">12h</option>
                            </Form.Control>
                          </Form.Group>

                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Extra Time (After)</Form.Label>
                            <Form.Control
                              as="select"
                              onChange={(e) =>
                                setExtraDurationAfter(e.target.value)
                              }
                              // onClick={addExtraDuration}
                              value={extraDurationAfter}
                            >
                              <option value="5">5min</option>
                              <option value="10">10min</option>
                              <option value="15" selected>
                                {' '}
                                15min
                              </option>
                              <option value="20">20min</option>
                              <option value="25">25min</option>
                              <option value="30">30min</option>
                              <option value="35">35min</option>
                              <option value="40">40min</option>
                              <option value="45">45min</option>
                              <option value="50">50min</option>
                              <option value="55">55min</option>
                              <option value="60">1h</option>
                              <option value="65">1h 5min</option>
                              <option value="70">1h 10min</option>
                              <option value="75">1h 15min</option>
                              <option value="80">1h 20min</option>
                              <option value="85">1h 25min</option>
                              <option value="90">1h 30min</option>
                              <option value="95">1h 35min</option>
                              <option value="100">1h 40min</option>
                              <option value="105">1h 45min</option>
                              <option value="110">1h 50min</option>
                              <option value="115">1h 55min</option>
                              <option value="120">2h</option>
                              <option value="135">2h 15min</option>
                              <option value="150">2h 30min</option>
                              <option value="165">2h 45min</option>
                              <option value="180">3h</option>
                              <option value="195">3h 15min</option>
                              <option value="210">3h 30min</option>
                              <option value="225">3h 45min</option>
                              <option value="240">4h</option>
                              <option value="255">4h 15min</option>
                              <option value="310">4h 30min</option>
                              <option value="325">4h 45min</option>
                              <option value="340">5h</option>
                              <option value="355">5h 15min</option>
                              <option value="410">5h 30min</option>
                              <option value="425">5h 45min</option>
                              <option value="440">6h</option>
                              <option value="455">6h 15min</option>
                              <option value="510">6h 30min</option>
                              <option value="525">6h 45min</option>
                              <option value="540">7h</option>
                              <option value="555">7h 15min</option>
                              <option value="610">7h 30min</option>
                              <option value="625">7h 45min</option>
                              <option value="640">8h</option>
                              <option value="655">8h 15min</option>
                              <option value="710">8h 30min</option>
                              <option value="725">8h 45min</option>
                              <option value="740">9h</option>
                              <option value="755">9h 15min</option>
                              <option value="810">9h 30min</option>
                              <option value="825">9h 45min</option>
                              <option value="840">10h</option>
                              <option value="855">10h 15min</option>
                              <option value="910">10h 30min</option>
                              <option value="925">10h 45min</option>
                              <option value="940">11h</option>
                              <option value="955">11h 15min</option>
                              <option value="1010">11h 30min</option>
                              <option value="1025">11h 45min</option>
                              <option value="1040">12h</option>
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Price (from)</Form.Label>
                            <Form.Control
                              disabled={disablePriceFrom}
                              type="number"
                              placeholder="0.00"
                              onChange={(e) => setPriceFrom(e.target.value)}
                              value={priceFrom}
                            />
                            {errors.priceFrom && (
                              <p className="error">{errors.priceFrom}</p>
                            )}
                          </Form.Group>

                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Promotion Price</Form.Label>
                            <Form.Control
                              disabled={disableSpecialPrice}
                              type="number"
                              placeholder="0.00"
                              onChange={(e) => setSpecialPrice(e.target.value)}
                              value={specialPrice}
                            />
                            {!validd ? (
                              <p className="error">
                                Promotion price should be less than price
                              </p>
                            ) : (
                              <></>
                            )}
                          </Form.Group>
                        </div>
                      </div>

                      <div
                        className="add-pricing-option"
                        onClick={handleViewShow}
                      >
                        <span>
                          <i className="fa fa-plus" /> Advanced pricing option
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="select-all-staff">
                          <Form.Check
                            type="checkbox"
                            label="Select all"
                            id="selectall"
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                        </div>

                        <div className="staff-list">
                          {staffs.map((item, i) => (
                            <div className="staff-item">
                              <Form.Check
                                type="checkbox"
                                id="staff1"
                                key={i}
                                checked={item.checked}
                                value={item._id}
                                onClick={(e) => handleCheck(e, item)}
                              />
                              <img src={staffImg} alt="" />
                              {item.firstname}
                            </div>
                          ))}

                        </div>
                        {errors.staff && (
                          <p className="error">{errors.staff}</p>
                        )} */}
                <div className="col-md-6">
                  <div className="service-card">
                    <div className="service-header">
                      <h2>Out Service</h2>
                      <p>Is this an Out Service?</p>
                    </div>
                    <div className="service-body">
                      <div className="online-booking">
                        <label className="switch">
                          <input
                            type="checkbox"
                            onChange={() => setOutService(!outService)}
                            checked={outService}
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </div>
                    {!outService ? null : (
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Out service Extra Time</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={(e) =>
                            setOutServiceExtraTime(e.target.value)
                          }
                          value={outServiceExtraTime}
                        >
                          <option value="5">5min</option>
                          <option value="10">10min</option>
                          <option value="15">15min</option>
                          <option value="20">20min</option>
                          <option value="25">25min</option>
                          <option value="30">30min</option>
                          <option value="35">35min</option>
                          <option value="40">40min</option>
                          <option value="45">45min</option>
                          <option value="50">50min</option>
                          <option value="55">55min</option>
                          <option value="60" selected>
                            1h
                          </option>
                          <option value="65">1h 5min</option>
                          <option value="70">1h 10min</option>
                          <option value="75">1h 15min</option>
                          <option value="80">1h 20min</option>
                          <option value="85">1h 25min</option>
                          <option value="90">1h 30min</option>
                          <option value="95">1h 35min</option>
                          <option value="100">1h 40min</option>
                          <option value="105">1h 45min</option>
                          <option value="110">1h 50min</option>
                          <option value="115">1h 55min</option>
                          <option value="120">2h</option>
                          <option value="135">2h 15min</option>
                          <option value="150">2h 30min</option>
                          <option value="165">2h 45min</option>
                          <option value="180">3h</option>
                          <option value="195">3h 15min</option>
                          <option value="210">3h 30min</option>
                          <option value="225">3h 45min</option>
                          <option value="240">4h</option>
                          <option value="255">4h 15min</option>
                          <option value="310">4h 30min</option>
                          <option value="325">4h 45min</option>
                          <option value="340">5h</option>
                          <option value="355">5h 15min</option>
                          <option value="410">5h 30min</option>
                          <option value="425">5h 45min</option>
                          <option value="440">6h</option>
                          <option value="455">6h 15min</option>
                          <option value="510">6h 30min</option>
                          <option value="525">6h 45min</option>
                          <option value="540">7h</option>
                          <option value="555">7h 15min</option>
                          <option value="610">7h 30min</option>
                          <option value="625">7h 45min</option>
                          <option value="640">8h</option>
                          <option value="655">8h 15min</option>
                          <option value="710">8h 30min</option>
                          <option value="725">8h 45min</option>
                          <option value="740">9h</option>
                          <option value="755">9h 15min</option>
                          <option value="810">9h 30min</option>
                          <option value="825">9h 45min</option>
                          <option value="840">10h</option>
                          <option value="855">10h 15min</option>
                          <option value="910">10h 30min</option>
                          <option value="925">10h 45min</option>
                          <option value="940">11h</option>
                          <option value="955">11h 15min</option>
                          <option value="1010">11h 30min</option>
                          <option value="1025">11h 45min</option>
                          <option value="1040">12h</option>
                        </Form.Control>
                      </Form.Group>
                    )}
                  </div>
                  <div className="service-card">
                    <div className="service-header">
                      <h2>Payment</h2>
                      <p>Is payment required for this service?</p>
                    </div>
                    <div className="service-body">
                      <div className="online-booking">
                        <label className="switch">
                          <input
                            type="checkbox"
                            onChange={() => setIsPayment(!isPayment)}
                            checked={isPayment}
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="service-card">
                    <div className="service-header">
                      <h2>Associate</h2>
                      <p>Who can performe this service?</p>
                    </div>

                    <div className="service-body">
                      <div className="staff-section">
                        <div className="select-all-staff">
                          <Form.Check
                            type="checkbox"
                            label="Select all"
                            id="selectall"
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                        </div>
                        <div className="staff-list">
                          {staffs.map((item, i) => (
                            <div className="staff-item">
                              <Form.Check
                                type="checkbox"
                                id="staff1"
                                checked={item.checked}
                                value={item._id}
                                onClick={(e) => handleCheck(e, item)}
                              />
                              <img src={staffimg} alt="" />
                              {item.firstname}
                            </div>
                          ))}
                          {/* {errors.staff && (
                            <p className="error">{errors.staff}</p>
                          )} */}
                        </div>

                        <div className="staff-copmmision">
                          <h5>Associate commission</h5>
                          <p>Can the associate get commission?</p>

                          <div className="online-booking">
                            <label className="switch">
                              <input
                                type="checkbox"
                                onChange={() =>
                                  setStaffCommission(!staffCommission)
                                }
                                checked={staffCommission}
                              />
                              <span className="slider round" />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="service-card">
                    <div className="service-header">
                      <h2>TAX AND VOUCHERS</h2>
                      <p>Tax (Included in price)</p>
                    </div>

                    <div className="service-body">
                      <div className="sales-setting">
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Tax Type</Form.Label>
                          <Form.Control
                            as="select"
                            onChange={(e) => setServiceTax(e.target.value)}
                            value={serviceTax}
                          >
                            <option value="free" selected>
                              Tax Free
                            </option>
                            <option value="No">Custom Tax</option>
                          </Form.Control>
                        </Form.Group>

                        <h5>Voucher sales</h5>

                        <div className="online-booking">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() => {
                                setHideVoucherBox(!hideVoucherBox);
                                setSwitchOpen(!switchOpen);
                              }}
                              checked={switchOpen}
                            />
                            <span className="slider round" />
                          </label>
                          Allow voucher
                        </div>
                        <Form.Group
                          controlId="formBasicEmail"
                          hidden={hideVoucherBox}
                        >
                          <Form.Label>Voucher expiry duration</Form.Label>
                          <Form.Control
                            as="select"
                            onChange={(e) => setVoucherExpiry(e.target.value)}
                            value={voucherExpiry}
                          >
                            <option value="6month">Default 6 Month</option>
                            <option value="14days">14 days</option>
                            <option value="1month">1 month</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="login-button service-save">
                    <Button type="submit" className="l-btn">
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        className="advance-pricing-popup"
        show={view}
        onHide={handleViewClose}
      >
        <AddPricing
          staffs={staffs}
          handleViewClose={handleViewClose}
          staffs={staffs}
          priceType={priceType}
          selectPriceType={selectPriceType}
          duration={duration}
          setDuration={setDuration}
          // staffDuration={staffDuration}
          // setStaffDuration={setStaffDuration}
          updateService={updateService}
          disablePriceFrom={disablePriceFrom}
          disableSpecialPrice={disableSpecialPrice}
          setPriceFrom={setPriceFrom}
          priceFrom={priceFrom}
          setSpecialPrice={setSpecialPrice}
          specialPrice={specialPrice}
          staffPricing={staffPricing}
          setStaffPricing={setStaffPricing}
        />
      </Modal>
    </PartnerWrapper>
  );
};

export default EditService;
function AddPricing({
  setPriceFrom,
  priceFrom,
  setSpecialPrice,
  specialPrice,
  updateService,
  staffs,
  handleViewClose,
  duration,
  setDuration,
  priceType,
  selectPriceType,
  staffDuration,
  disableSpecialPrice,
  disablePriceFrom,
  staffPricing,
  setStaffPricing,
}) {
  const editStaffPrice = (e) => {
    e.preventDefault();

    handleViewClose();
  };
  const selectStaffDuration = (e, i, id) => {
    const newArr = [...staffPricing];
    newArr[i].staffDuration = e;
    setStaffPricing(newArr);
  };
  const selectStaffPrice = (e, i, id) => {
    const newArr = [...staffPricing];
    newArr[i].staffPrice = e;
    setStaffPricing(newArr);
  };

  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title>Service Pricing</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="advance-pricing-option">
          <Form onSubmit={editStaffPrice}>
            <div className="advance-pricing-box">
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Duration</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => setDuration(e.target.value)}
                  value={duration}
                >
                  <option value="5">5min</option>
                  <option value="10">10min</option>
                  <option value="15">15min</option>
                  <option value="20">20min</option>
                  <option value="25">25min</option>
                  <option value="30">30min</option>
                  <option value="35">35min</option>
                  <option value="40">40min</option>
                  <option value="45">45min</option>
                  <option value="50">50min</option>
                  <option value="55">55min</option>
                  <option value="60" selected>
                    1h
                  </option>
                  <option value="65">1h 5min</option>
                  <option value="70">1h 10min</option>
                  <option value="75">1h 15min</option>
                  <option value="80">1h 20min</option>
                  <option value="85">1h 25min</option>
                  <option value="90">1h 30min</option>
                  <option value="95">1h 35min</option>
                  <option value="100">1h 40min</option>
                  <option value="105">1h 45min</option>
                  <option value="110">1h 50min</option>
                  <option value="115">1h 55min</option>
                  <option value="120">2h</option>
                  <option value="135">2h 15min</option>
                  <option value="150">2h 30min</option>
                  <option value="165">2h 45min</option>
                  <option value="180">3h</option>
                  <option value="195">3h 15min</option>
                  <option value="210">3h 30min</option>
                  <option value="225">3h 45min</option>
                  <option value="240">4h</option>
                  <option value="255">4h 15min</option>
                  <option value="310">4h 30min</option>
                  <option value="325">4h 45min</option>
                  <option value="340">5h</option>
                  <option value="355">5h 15min</option>
                  <option value="410">5h 30min</option>
                  <option value="425">5h 45min</option>
                  <option value="440">6h</option>
                  <option value="455">6h 15min</option>
                  <option value="510">6h 30min</option>
                  <option value="525">6h 45min</option>
                  <option value="540">7h</option>
                  <option value="555">7h 15min</option>
                  <option value="610">7h 30min</option>
                  <option value="625">7h 45min</option>
                  <option value="640">8h</option>
                  <option value="655">8h 15min</option>
                  <option value="710">8h 30min</option>
                  <option value="725">8h 45min</option>
                  <option value="740">9h</option>
                  <option value="755">9h 15min</option>
                  <option value="810">9h 30min</option>
                  <option value="825">9h 45min</option>
                  <option value="840">10h</option>
                  <option value="855">10h 15min</option>
                  <option value="910">10h 30min</option>
                  <option value="925">10h 45min</option>
                  <option value="940">11h</option>
                  <option value="955">11h 15min</option>
                  <option value="1010">11h 30min</option>
                  <option value="1025">11h 45min</option>
                  <option value="1040">12h</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label>Price Type</Form.Label>
                <Form.Control
                  as="select"
                  value={priceType}
                  onChange={(e) => selectPriceType(e.target.value)}
                >
                  <option value="from">From</option>
                  <option value="free">Free</option>
                  <option value="fixed" default>
                    Fixed
                  </option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  disabled={disablePriceFrom}
                  placeholder="0.00"
                  onChange={(e) => setPriceFrom(e.target.value)}
                  value={priceFrom}
                />
                {/* {errors.priceFrom && (
                  <p className="error">{errors.priceFrom}</p>
                )} */}
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label>Promotion Price</Form.Label>
                <Form.Control
                  disabled={disableSpecialPrice}
                  placeholder="0.00"
                  onChange={(e) => setSpecialPrice(e.target.value)}
                  value={specialPrice}
                />
              </Form.Group>
            </div>

            <div className="special-price-associate">
              <h4>Special pricing per associate</h4>
              {staffPricing.map((el, i) => (
                <div className="associate-row">
                  <div className="associate-user">
                    <img src={staffimg} alt="" />
                    <p>{el.staffName}</p>
                  </div>

                  <div className="price-associate">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Associate Price</Form.Label>
                      <Form.Control
                        placeholder="0.00"
                        onChange={(e) =>
                          selectStaffPrice(e.target.value, i, el.staffId)
                        }
                        value={el.staffPrice}
                      />
                    </Form.Group>
                  </div>

                  <div className="duratiion-associate">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Duration</Form.Label>
                      <Form.Control
                        as="select"
                        value={el.staffDuration}
                        onClick={(e) =>
                          selectStaffDuration(e.target.value, i, el.staffId)
                        }
                      >
                        <option value="5">5min</option>
                        <option value="10">10min</option>
                        <option value="15">15min</option>
                        <option value="20">20min</option>
                        <option value="25">25min</option>
                        <option value="30">30min</option>
                        <option value="35">35min</option>
                        <option value="40">40min</option>
                        <option value="45">45min</option>
                        <option value="50">50min</option>
                        <option value="55">55min</option>
                        <option value="60" selected>
                          1h
                        </option>
                        <option value="65">1h 5min</option>
                        <option value="70">1h 10min</option>
                        <option value="75">1h 15min</option>
                        <option value="80">1h 20min</option>
                        <option value="85">1h 25min</option>
                        <option value="90">1h 30min</option>
                        <option value="95">1h 35min</option>
                        <option value="100">1h 40min</option>
                        <option value="105">1h 45min</option>
                        <option value="110">1h 50min</option>
                        <option value="115">1h 55min</option>
                        <option value="120">2h</option>
                        <option value="135">2h 15min</option>
                        <option value="150">2h 30min</option>
                        <option value="165">2h 45min</option>
                        <option value="180">3h</option>
                        <option value="195">3h 15min</option>
                        <option value="210">3h 30min</option>
                        <option value="225">3h 45min</option>
                        <option value="240">4h</option>
                        <option value="255">4h 15min</option>
                        <option value="310">4h 30min</option>
                        <option value="325">4h 45min</option>
                        <option value="340">5h</option>
                        <option value="355">5h 15min</option>
                        <option value="410">5h 30min</option>
                        <option value="425">5h 45min</option>
                        <option value="440">6h</option>
                        <option value="455">6h 15min</option>
                        <option value="510">6h 30min</option>
                        <option value="525">6h 45min</option>
                        <option value="540">7h</option>
                        <option value="555">7h 15min</option>
                        <option value="610">7h 30min</option>
                        <option value="625">7h 45min</option>
                        <option value="640">8h</option>
                        <option value="655">8h 15min</option>
                        <option value="710">8h 30min</option>
                        <option value="725">8h 45min</option>
                        <option value="740">9h</option>
                        <option value="755">9h 15min</option>
                        <option value="810">9h 30min</option>
                        <option value="825">9h 45min</option>
                        <option value="840">10h</option>
                        <option value="855">10h 15min</option>
                        <option value="910">10h 30min</option>
                        <option value="925">10h 45min</option>
                        <option value="940">11h</option>
                        <option value="955">11h 15min</option>
                        <option value="1010">11h 30min</option>
                        <option value="1025">11h 45min</option>
                        <option value="1040">12h</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                </div>
              ))}
            </div>

            <div className="associate-pricing-buttons">
              <Button variant="default">Cancel</Button>
              <Button type="submit" onHide={handleViewClose}>
                Save
              </Button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </div>
  );
}
