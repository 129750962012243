import React, { useState, useEffect } from 'react';
import {
  Router, Route, Switch, Redirect,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import history from './config/history';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import Home from './components/home/home';
import Login from './components/login/login';
import Register from './components/register/register';
import Forget from './components/forget/forget';
import StoreListing from './components/store/strorelisting';
import StoreDetail from './components/store/storedetail';
import PartnerLogin from './components/partners/login/login';
import PartnerRegister from './components/partners/register/register';
import PartnerForget from './components/partners/forget/forget';
import AddStaff from './components/partners/partnerstaff/addstaff';
import EditStaff from './components/partners/partnerstaff/editstaff';
import SetUp from './components/partners/setup/setup';
import AccountSetting from './components/partners/setup/accountsetting';
import Invoice from './components/partners/setup/invoice';
import InvoiceSequencing from './components/partners/setup/invoicesequencing';
import Taxes from './components/partners/setup/taxes';
import PaymentTaxes from './components/partners/setup/paymenttaxes';
import DiscountType from './components/partners/setup/discounttype';
import PartnerDashboard from './components/partners/partnerdashboard/dashboard';
import PartnerServices from './components/partners/partnerservices/services';
import AddServices from './components/partners/partnerservices/addservice';
import EditService from './components/partners/partnerservices/editservice';
import EditPackage from './components/partners/partnerservices/editpackage';
import SingleServices from './components/partners/partnerservices/singleservice';
import PackageServices from './components/partners/partnerservices/package';
import PartnerStaff from './components/partners/partnerstaff/staff';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { userInstance, partnerInstance } from './config/axios';
import './responsive.css';
import queryString from 'query-string';
import { UserProvider } from './context/context';

const App = () => {
  const [username, setUsername] = useState('');
  const [partnerId, setPartnerId] = useState('');
  const [currency, setCurrency] = useState('');
  const [isLogin, setIsLogin] = useState(localStorage.getItem('usertoken'));
  const [isPartnerLogin, setIsPartnerLogin] = useState(
    localStorage.getItem('partnertoken'),
  );
  const PartnerLogOut = () => {
    localStorage.removeItem('partnertoken');
    localStorage.removeItem('authtoken');
    setIsPartnerLogin(null);
    history.push('/partners/login');
  };
  const user = {
    username, partnerId, isLogin, setIsLogin, isPartnerLogin, setIsPartnerLogin, PartnerLogOut, currency,
  };

  const LogOut = () => {
    localStorage.removeItem('usertoken');
    setIsLogin(null);
  };

  useEffect(() => {
    const VerifyRoute = async () => {
      const value = queryString.parse(window.location.search);
      const userid = value.id;
      const { type } = value;
      const payload = { userid };
      if (type === 'partner') {
        const data = await partnerInstance().post('/verify', payload);
        const { code, token, authToken} = data.data;
        if (code === 200) {
          localStorage.setItem('partnertoken', token);
          localStorage.setItem('authtoken',authToken);
          localStorage.setItem('userid',userid);
          window.location.href = '/partners/dashboard';
        }
      } else {
        const data = await userInstance().post('/verify', payload);
        const { code, token,authToken} = data.data;
        if (code === 200) {
          localStorage.setItem('usertoken', token);
          localStorage.setItem('authtoken',authToken);
          localStorage.setItem('userid',userid);
          window.location.href = '/';
        }
      }
    };
    //   const PartnerVerifyRoute = async () => {
    //     const value = queryString.parse(window.location.search);
    //     const userid = value.id;
    //     const type = value.type;
    //     console.log("userid-->", userid)
    //     const payload = { userid };
    //     console.log("payload", payload)
    //     const data = await partnerInstance().post('/verify', payload);
    //     let { code, token } = data.data;
    //     if (code === 200) {
    //      localStorage.setItem('partnertoken', token);
    //      window.location.href = '/';
    //     }
    //   };
    // PartnerVerifyRoute();
    VerifyRoute();
    getData();
    getPartnerData();
  }, []);

  const getData = async () => {
    const userdata = await userInstance().get('/validateToken');
    const { code, userData } = userdata.data;
    if (code === 200) {
      setUsername(userData.firstname);
    }
  };
  const getPartnerData = async () => {
    const partnerdata = await partnerInstance().get('/validateToken');
    const { code, userData } = partnerdata.data;
    if (code === 200) {
      setUsername(userData.firstname);
      setCurrency(userData.currency);
      setPartnerId(userData._id);
    }
  };

  const RegisterRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => (localStorage.getItem('usertoken') ? (
        <Redirect to="/" />
      ) : (
        <Component {...props} {...rest} />
      ))}
    />
  );

  const LoginRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => (localStorage.getItem('usertoken') ? (
        <Redirect to="/" />
      ) : (
        <Component {...props} {...rest} />
      ))}
    />
  );

  const PartnerRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => (localStorage.getItem('partnertoken') ? (
        <Component {...props} {...rest} />
      ) : (
        <Redirect to="/partners/login" />
      ))}
    />
  );

  return (
    <div className="App">
      <div className="main-wrapper">
        <>
          <Router history={history}>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
            <Header
              isLogin={isLogin}
              setIsLogin={setIsLogin}
              LogOut={LogOut}
              username={username}
            />
            <main>
              <div className="main_content">
                <Switch>
                  <UserProvider value={user}>
                    <Route
                      exact
                      path="/"
                      render={() => <Home />}
                      isLogin={isLogin}
                      setIsLogin={setIsLogin}
                    />
                    <LoginRoute
                      exact
                      path="/login"
                      component={() => <Login setIsLogin={setIsLogin} />}
                    />
                    <RegisterRoute
                      exact
                      path="/register"
                      component={() => <Register setIsLogin={setIsLogin} />}
                    />
                    <Route exact path="/forget" render={() => <Forget />} />
                    <Route exact path="/verify" />

                    <Route
                      exact
                      path="/partners/login"
                      render={() => <PartnerLogin />}
                    />
                    <Route
                      exact
                      path="/partners/register"
                      render={() => <PartnerRegister />}
                    />
                    <Route
                      exact
                      path="/partners/forget"
                      render={() => <PartnerForget />}
                    />
                    <PartnerRoute
                      exact
                      path="/partners/dashboard"
                      component={() => (
                        <PartnerDashboard />
                      )}
                    />
                    <PartnerRoute
                      exact
                      path="/partners/services"
                      component={() => <PartnerServices />}
                    />
                    <PartnerRoute
                      exact
                      path="/partners/addservices"
                      component={() => <AddServices />}
                    />
                    <PartnerRoute
                      exact
                      path="/partners/editservice"
                      component={() => <EditService />}
                    />
                    <PartnerRoute
                      exact
                      path="/partners/editpackage"
                      component={() => <EditPackage />}
                    />
                    <PartnerRoute
                      exact
                      path="/partners/addsingleservices"
                      component={() => <SingleServices />}
                    />
                    <PartnerRoute
                      exact
                      path="/partners/addpackage"
                      component={() => <PackageServices />}
                    />

                    <PartnerRoute
                      exact
                      path="/partners/staff"
                      component={() => <PartnerStaff />}
                    />

                    <PartnerRoute
                      exact
                      path="/partners/addstaff"
                      component={() => <AddStaff />}
                    />
                    <PartnerRoute
                      exact
                      path="/partners/editstaff"
                      component={() => <EditStaff />}
                    />

                    <PartnerRoute
                      exact
                      path="/partners/setup"
                      component={() => <SetUp />}
                    />

                    <PartnerRoute
                      exact
                      path="/partners/accountsetting"
                      component={() => <AccountSetting />}
                    />
                    <PartnerRoute
                      exact
                      path="/partners/invoice"
                      component={() => <Invoice />}
                    />
                    <PartnerRoute
                      exact
                      path="/partners/invoice-sequencing"
                      component={() => <InvoiceSequencing />}
                    />

                    <PartnerRoute
                      exact
                      path="/partners/taxes"
                      component={() => <Taxes />}
                    />
                    <PartnerRoute
                      exact
                      path="/partners/payment-taxes"
                      component={() => <PaymentTaxes />}
                    />
                    <PartnerRoute
                      exact
                      path="/partners/discount-type"
                      component={() => <DiscountType />}
                    />

                    <Route exact path="/store" render={() => <StoreListing />} />
                    <Route exact path="/storedetail" render={() => <StoreDetail />} />

                  </UserProvider>
                </Switch>
              </div>
            </main>
            <Footer />
          </Router>
        </>
      </div>
    </div>
  );
};

export default App;
