import React, { useState, useEffect, useContext } from 'react';
import { Button, Form, Modal, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { partnerInstance } from '../../../config/axios';
import PartnerWrapper from '../partnerwrapper/wrapper';
import { validateCategory } from '../../../function/validate';
import PartnerContext from '../../../context/context';

const PartnerServices = () => {
  const [show, setShow] = useState(false);
  const [services, setServices] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { currency } = useContext(PartnerContext);
  const [isCat, setIsCat] = useState([]);

  console.log('currency', currency);
  const getServices = async () => {
    const servicesData = await partnerInstance().get('/getServices');
    const { code, services: Services } = servicesData.data;
    if (code === 200) {
      setServices(Services);
      console.log('response', Services);
      {
        services.map((el, i) => {
          setIsCat(el.categoryname);
        });
      }
    }
  };
  useEffect(() => {
    getServices();
  }, []);

  return (
    <PartnerWrapper>
      <div className="partner-services">
        <div className="container">
          <h2 className="admin-title">
            Services
            <div className="header-buttons">
              <DropdownButton
                className="dark-btn"
                id="dropdown-basic-button"
                title="Add New"
              >
                {console.log('isCAt', isCat.length)}
                {services.length > 0 ? (
                  <>
                    {' '}
                    <Link to="/partners/addservices">New Service</Link>
                    <Dropdown.Item
                      href="javaScript:void(0);"
                      onClick={handleShow}
                    >
                      New Category
                    </Dropdown.Item>{' '}
                  </>
                ) : (
                  <>
                    {' '}
                    <Dropdown.Item
                      href="javaScript:void(0);"
                      onClick={handleShow}
                    >
                      New Category
                    </Dropdown.Item>{' '}
                  </>
                )}
              </DropdownButton>
            </div>
          </h2>

          <div className="partner-services-content">
            <ServiceList
              getServices={getServices}
              services={services}
              handleShow={handleShow}
              currency={currency}
            />
          </div>
        </div>
      </div>

      <Modal className="category-popup" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>New Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddCategory
            validateCategory={validateCategory}
            getServices={getServices}
            setShow={setShow}
          />
        </Modal.Body>
      </Modal>
    </PartnerWrapper>
  );
};

export default PartnerServices;

const ServiceList = ({ services, handleShow, getServices, currency }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [categoryId, setCategoryId] = useState('');
  const [categoryname, setCategoryName] = useState('');
  const [categorydescription, setCategorydescription] = useState('');
  const editService = (id) => {
    window.location.href = `/partners/editservice/?id=${id}`;
  };
  const editPackage = (id) => {
    window.location.href = `/partners/editpackage/?id=${id}`;
  };
  const deleteCategory = async (id) => {
    const deleteCategory = await partnerInstance().post('/deleteCategory', {
      deleteId: id,
    });
    const { code } = deleteCategory.data;
    if (code === 200) {
      getServices();
    }
  };

  const handleShowEdit = (id, name, description) => {
    setCategoryId(id);
    setCategoryName(name);
    setCategorydescription(description);
    setShowEdit(true);
  };

  const handleHide = () => {
    setShowEdit(false);
  };
  const total = 0;
  const pricetotal = 0;
  const durationtotal = 0;
  let hours;
  let minutes;
  let h;
  let m;
  // let durationn = 0;
  // let discount = 0;
  const discountType = '';
  console.log(services, 'services+++');

  return (
    <div>
      {services.length > 0 ? (
        services.map((el, i) => (
          <div>
            <div className="service-list">
              <div className="action-icon-img">
                <h4>{el.categoryname}</h4>

                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <i className="fa fa-ellipsis-h" aria-hidden="true" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="javaScript:void(0);"
                      onClick={() =>
                        handleShowEdit(
                          el._id,
                          el.categoryname,
                          el.categorydescription
                        )
                      }
                    >
                      Edit Category
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="javaScript:void(0);"
                      onClick={() => deleteCategory(el._id)}
                    >
                      Delete Category
                    </Dropdown.Item>

                    <Dropdown.Item
                      href={`/partners/addservices?catid=${el._id}&catname=${el.categoryname}`}
                    >
                      Add New Service
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="service-list-header">
                <div className="service-name">Service Name</div>
                <div className="service-hour">Duration</div>
                <div className="service-extra">Extra Time (Before)</div>
                <div className="service-extra">Extra Time (After)</div>
                <div className="service-discount">Dicount</div>
                <div className="service-price">Price</div>
              </div>
              <>
                {el.serviceid.map((ee, j) => (
                  <>
                    <div
                      className="service-list-items-multiple-service"
                      onClick={() => editService(ee._id)}
                    >
                      {/* <h4>{ee.serviceName}</h4> */}
                      <div className="service-list-items">
                        <div className="service-name">
                          <span>{ee.serviceName}</span>
                        </div>

                        <div className="service-hour">
                          <span>
                            {Math.floor(parseInt(ee.duration) / 60)}
                            h, {parseInt(ee.duration) % 60}
                            min
                          </span>
                        </div>

                        <div className="service-extra">
                          {Math.floor(parseInt(ee.extraDurationBefore) / 60)}
                          h, {parseInt(ee.extraDurationBefore) % 60}
                          min
                        </div>
                        <div className="service-extra">
                          {Math.floor(parseInt(ee.extraDurationAfter) / 60)}
                          h, {parseInt(ee.extraDurationAfter) % 60}
                          min
                        </div>
                        <div className="service-discount">
                          <span>
                            {currency} {ee.priceFrom}
                          </span>
                        </div>
                        <div className="service-price">
                          <span>
                            {currency} {ee.priceFrom - ee.specialPrice}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </>

              {/* packge ServiceList */}

              <>
                {el.packageid.map((ef, l) => (
                  <>
                    <div
                      className="service-list-items-multiple-service"
                      onClick={() => editPackage(ef._id)}
                    >
                      <>
                        <>
                          <>
                            <div className="service-list-items">
                              <div className="service-name">
                                {ef.packagename}

                                <span>{ef.serviceid.length} Services</span>
                              </div>

                              <div className="service-hour">
                                {/* <span>{currentValue.duration}</span> */}
                                <span>
                                  {Math.floor(
                                    parseInt(ef.packageDuration) / 60
                                  )}
                                  h {parseInt(ef.packageDuration) % 60}
                                  min
                                  {/* {ef.serviceid.forEach((el) => {
                                    el.priceplan.map((ele) => {
                                      durationtotal += parseInt(ele.duration);
                                    });
                                  })}
                                  {Math.floor(parseInt(durationtotal) / 60)}h
                                  {parseInt(durationtotal) % 60}
                                  min */}
                                </span>
                              </div>

                              <div className="service-extra">
                                {' '}
                                {Math.floor(
                                  parseInt(ef.packageDurationBefore) / 60
                                )}
                                h {parseInt(ef.packageDurationBefore) % 60}
                                min
                              </div>
                              <div className="service-extra">
                                {' '}
                                {Math.floor(
                                  parseInt(ef.packageDurationAfter) / 60
                                )}
                                h {parseInt(ef.packageDurationAfter) % 60}
                                min
                              </div>

                              {/* {ef.packageSpecialPrice ? (
                                <>
                                  <div className="service-discount">
                                    <span>₹{ef.packagePrice}</span>
                                  </div>
                                  <div className="service-price">
                                    <span>₹{ef.packageSpecialPrice}</span>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="service-price">
                                    <span>
                                      ₹{' '}
                                      {ef.priceType === 'Custom pricing'
                                        ? ef.packageSpecialPrice -
                                          parseInt(ef.retailPrice)
                                        : ef.priceType === 'Percentage discount'
                                        ? ef.packageSpecialPrice -
                                          (ef.packageSpecialPrice *
                                            parseInt(ef.retailPrice)) /
                                            100
                                        : 0}
                                    </span>
                                  </div>
                                </>
                              )} */}
                              <div className="service-discount">
                                <span>
                                  {currency}
                                  {ef.retailPrice}
                                  {/* {ef.packageSpecialPrice} */}
                                  {/* {ef.priceType === 'Custom pricing'
                                    ? ef.packageSpecialPrice -
                                      parseInt(ef.retailPrice)
                                    : ef.priceType === 'Percentage discount'
                                    ? ef.packageSpecialPrice -
                                      (ef.packageSpecialPrice *
                                        parseInt(ef.retailPrice)) /
                                        100
                                    : 0} */}
                                </span>
                              </div>
                              {/* <div className="service-price">
                                <span>
                                  {currency}
                                  {ef.packagePrice - ef.packageSpecialPrice}
                                </span>
                              </div> */}
                              <div className="service-price">
                                <span>
                                  {currency}{' '}
                                  {ef.priceType === 'Custom pricing'
                                    ? parseInt(ef.retailPrice)
                                    : ef.priceType === 'Percentage discount'
                                    ? ef.packagePrice -
                                      (ef.packagePrice *
                                        parseInt(ef.retailPrice)) /
                                        100
                                    : 0}
                                </span>
                              </div>

                              {/* </React.Fragment>
                                            );
                                          },
                                          0
                                        )} */}
                              {/* <div className="service-hour">
                                          <span>
                                            {eg.priceplan[0].duration}
                                          </span>
                                        </div> */}

                              {/* <div className="service-discount">
                                          <span>
                                            ₹{eg.priceplan[0].specialPrice}
                                          </span>
                                        </div>

                                        <div className="service-price">
                                          <span>₹{eg.priceplan[0].price}</span>
                                        </div> */}
                              {/* </React.Fragment>
                                    );
                                  })} */}
                            </div>
                          </>
                        </>
                      </>
                    </div>
                  </>
                ))}
                <Modal
                  className="category-popup"
                  show={showEdit}
                  onHide={handleHide}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Edit Category</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <EditCategory
                      validateCategory={validateCategory}
                      categoryId={categoryId}
                      categoryDescription={categorydescription}
                      setCategorydescription={setCategorydescription}
                      categoryName={categoryname}
                      setCategoryName={setCategoryName}
                      setShowEdit={setShowEdit}
                      getServices={getServices}
                    />
                  </Modal.Body>
                </Modal>
              </>
              {/* end package list */}
            </div>{' '}
          </div>
        ))
      ) : (
        <>
          {' '}
          <div className="no-result">
            <h4>No Services Found</h4>
          </div>
        </>
      )}
    </div>
  );
};

function EditCategory({
  validateCategory,
  setShowEdit,
  categoryId,
  categoryName,
  categoryDescription,
  setCategorydescription,
  setCategoryName,
  getServices,
}) {
  // console.log(setShowEdit, categoryId);
  // const [categoryname, setCategoryname] = useState('');
  // const [categorydescription, setCategorydescription] = useState('');
  const [errors, setErrors] = useState({});
  const editCategory = async (e) => {
    e.preventDefault();
    const payloadd = {
      categoryName,
      categoryDescription,
    };
    const validation = validateCategory({
      categoryname: categoryName,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    const saveCategory = await partnerInstance().put(
      `/editCategory/${categoryId}`,
      payloadd
    );
    const { code, msg } = saveCategory.data;
    if (code === 200) {
      // setCategoryname(categoryAdded.categoryname);
      getServices();
      toast.success(msg, { containerId: 'B' });
      // clearValues();
      setShowEdit(false);
    } else if (code === 409) {
      toast.error(msg, { containerId: 'B' });
    } else {
      toast.error(msg, { containerId: 'B' });
    }
  };
  // const clearValues = () => {
  //   setCategoryname('');
  //   setCategorydescription('');
  // };
  return (
    <div className="add-category">
      <Form onSubmit={editCategory}>
        <Form.Group controlId="formBasicloginone">
          <Form.Label>Category name</Form.Label>
          <Form.Control
            type="text"
            placeholder="e.g. Hair Services"
            name="category"
            autoComplete="off"
            onChange={(e) => setCategoryName(e.target.value)}
            value={categoryName}
          />
          {errors.categoryname && (
            <p className="error">{errors.categoryname}</p>
          )}
        </Form.Group>

        <Form.Group controlId="formBasicloginone">
          <Form.Label>Category descripotion</Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            onChange={(e) => setCategorydescription(e.target.value)}
            value={categoryDescription}
          />
        </Form.Group>

        <div className="login-button">
          <Button type="submit" className="l-btn">
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
}

const AddCategory = ({ setShow, getServices, validateCategory }) => {
  const [errors, setErrors] = useState({});
  const [categoryname, setCategoryname] = useState('');
  const [categorydescription, setCategorydescription] = useState('');
  const addCategory = async (e) => {
    e.preventDefault();
    const payload = {
      categoryname,
      categorydescription,
    };
    const validation = validateCategory({
      categoryname,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }

    const saveCategory = await partnerInstance().post('/addCategory', payload);
    const { code, msg } = saveCategory.data;
    if (code === 200) {
      // setCategoryname(categoryAdded.categoryname);
      getServices();
      toast.success(msg, { containerId: 'B' });

      // clearValues();
      setShow(false);
    } else if (code === 409) {
      toast.error(msg, { containerId: 'B' });
    } else {
      toast.error(msg, { containerId: 'B' });
    }
  };
  // const clearValues = () => {
  //   setCategoryname('');
  //   setCategorydescription('');
  // };
  return (
    <div className="add-category">
      <Form onSubmit={addCategory}>
        <Form.Group controlId="formBasicloginone">
          <Form.Label>Category name</Form.Label>
          <Form.Control
            type="text"
            placeholder="e.g. Hair Services"
            name="category"
            autoComplete="off"
            onChange={(e) => setCategoryname(e.target.value)}
            value={categoryname}
          />
          {errors.categoryname && (
            <p className="error">{errors.categoryname}</p>
          )}
        </Form.Group>

        <Form.Group controlId="formBasicloginone">
          <Form.Label>Category descripotion</Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            onChange={(e) => setCategorydescription(e.target.value)}
            value={categorydescription}
          />
        </Form.Group>

        <div className="login-button">
          <Button type="submit" className="l-btn">
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};
