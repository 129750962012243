import React, { useState, useEffect, useCallback } from 'react';
import {
  Form,
  Table,
  Dropdown,
  Button,
  Tab,
  FormControl,
} from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import moment from 'moment';
import PartnerWrapper from '../partnerwrapper/wrapper';
import { partnerInstance } from '../../../config/axios';
// import { countrycode } from '../partners/register/code';
import { validateStaff } from '../../../function/validate';
import history from '../../../config/history';
import { countrycode } from '../register/code';

const array = [];
const EditStaff = () => {
  const [email, setEmail] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [staffTitle, setStaffTitle] = useState('');
  const [note, setNote] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [errors, setErrors] = useState({});
  const [checked2, setChecked2] = useState(false);
  const [userPermission, setUserPermission] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [appointmentBooking, setAppointmentBooking] = useState();
  const [serviceid, setServiceId] = useState([]);
  const [services, setServices] = useState([]);
  const [serviceCommission, setServiceCommission] = useState('');
  const [voucherCommission, setVoucherCommission] = useState('');
  const [selectAll, setSelectAll] = useState(true);
  const [checked, setChecked] = useState(false);
  const currentDate = moment(new Date()).format('YYYY-MM-DD');
  const [startEmployementDate, setStartEmployementDate] = useState(currentDate);
  const [endEmployementDate, setEndEmployementDate] = useState('');
  const [ID, setID] = useState('');
  const [selectedService, setSelectedService] = useState([]);
  // const [phoneCodeList, setPhoneCodeList] = useState([]);

  useEffect(() => {
    const arrayofid = [];
    const value = queryString.parse(window.location.search);
    const { id } = value;
    const getStaff = async () => {
      const idd = {
        staffid: id,
      };
      setID(id);
      const staffData = await partnerInstance().post('/getStaff', idd);
      console.log('staffData',staffData)
      const { code, stafflist, servicelist } = staffData.data;
      if (code === 200) {
        setEmail(stafflist.email);
        setFirstname(stafflist.firstname);
        setLastname(stafflist.lastname);
        setStaffTitle(stafflist.staffTitle);
        setNote(stafflist.note);
        setPhone(stafflist.phone);
        setPhoneCode(stafflist.phoneCode);
        // setStaff([...staff, service.staff]);
        setStartEmployementDate(stafflist.startEmployementDate);
        setEndEmployementDate(stafflist.endEmployementDate);
        setUserPermission(stafflist.userpermissiontype);
        setAppointmentBooking(stafflist.appointmentBooking);
        setServiceCommission(parseInt(stafflist.servicecommission,10));
        setVoucherCommission(parseInt(stafflist.vouchercommission,10));
        setSelectedService(stafflist.serviceid);
        const countryy = await partnerInstance().get('/getPartnerAccount');
        const { partnerData } = countryy.data;
        countrycode.map((el, i) => {
          partnerData.address.forEach((e) => {
            if (el.name === e.country) {
              setPhoneCode(el.code);
            }
          });
        });
        servicelist.forEach((el) => {
          const filterSelected = stafflist.serviceid.filter(
            (ele) => ele === el._id,
          );
          if (filterSelected.length > 0) {
            el.checked = true;
          }
        });
        setServices(servicelist);
        update();
      }
    };

    getStaff();
  }, []);
  console.log('arrayofid', services);

  const updateStaff = async (e) => {
    const arrayofid = [];
    e.preventDefault();
    // Try to understand this code simple stuff
    const filterSelectedIds = services.filter((el) => el.checked);
    filterSelectedIds.forEach((el) => {
      arrayofid.push(el._id);
    });

    const payload = {
      firstname,
      lastname,
      email,
      staffTitle,
      note,
      phone,
      phoneCode,
      userPermission,
      startDate,
      endDate,
      appointmentBooking,
      serviceid: arrayofid,
      servicecommission: serviceCommission,
      vouchercommission: voucherCommission,
      startEmployementDate,
      endEmployementDate,
      // checked: false,
    };
    console.log('payload in edit',payload)
    const validation = validateStaff({
      firstname,
      email,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    const saveData = await partnerInstance().put(`/editStaff/${ID}`, payload);
    const { code, msg } = saveData.data;
    if (code === 200) {
      toast.success(msg, { containerId: 'B' });
      history.push('/partners/staff');
      clearValues();
    } else if (code === 409) {
      toast.error(msg, { containerId: 'B' });
    } else {
      toast.error(msg, { containerId: 'B' });
    }
  };
  const clearValues = () => {
    setEmail('');
    setFirstname('');
    setLastname('');
    setChecked2('');
    setPhone('');
  };
  function useUpdate() {
    const [, setTick] = useState(0);
    const update = useCallback(() => {
      setTick((tick) => tick + 1);
    }, []);
    return update;
  }

  const update = useUpdate();

  const handleCheck = (e, el) => {
    const oldState = [...services];
    oldState.forEach((element) => {
      const { checked, _id } = element;
      if (el._id === _id) {
        element.checked = !checked;
      }
    });
    setServices(services);
    update();
  };

  const handleSelectAll = (e) => {
    console.log('select all=----', e);
    setSelectAll(!selectAll);
    services.forEach((el) => {
      if (selectAll) {
        services.forEach((element) => {
          el.checked = false;
          array.pop(element);
        });
        setServices(array);
      } else {
        services.forEach((el) => {
          el.checked = true;
          array.push(el._id);
        });
        setServiceId(services);
        update();
      }
    });
    setServices(services);
    update();
  };
  const selectPhoneCode = (item) => {
    setPhoneCode(item);
  };

  // const handleMenu = (e) => {
  //   console.log(e.target.checked, 'checkedddd');
  // };

  return (
    <PartnerWrapper>
      <div className="add-staff">
        <div className="container">
          <h2 className="admin-title">Edit Staff</h2>

          <div className="add-staff-content">
            <Form onSubmit={updateStaff}>
              <Tabs defaultActiveKey="0" id="staff-tab-example">
                <Tab eventKey="0" title="Details">
                  <div className="add-staff-details">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6">
                            <Form.Group controlId="formBasicFirst">
                              <Form.Label>First Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Your first name"
                                name="firstName"
                                autocomplete="off"
                                onChange={(e) => setFirstname(e.target.value)}
                                value={firstname}
                              />
                              {errors.firstname && (
                                <p className="error">{errors.firstname}</p>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-md-6">
                            <Form.Group controlId="formBasicEmail">
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Your last name"
                                name="nickName"
                                autocomplete="off"
                                onChange={(e) => setLastname(e.target.value)}
                                value={lastname}
                              />
                            </Form.Group>
                          </div>

                          <div className="col-md-12">
                            <Form.Group controlId="formBasicPassword">
                              <Form.Label>Mobile number</Form.Label>
                              <div className="number-fields">
                                <Dropdown>
                                  <Dropdown.Toggle id="dropdown-basic">
                                    {phoneCode}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {countrycode.map((el) => (
                                      <Dropdown.Item
                                        key={el.id}
                                        onClick={() => {
                                          selectPhoneCode(el.code);
                                        }}
                                      >
                                        {' '}
                                        {el.code}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                                <Form.Control
                                  type="number"
                                  placeholder="Your mobile number"
                                  name="phone"
                                  autocomplete="off"
                                  onChange={(e) => setPhone(e.target.value)}
                                  value={phone}
                                />
                              </div>
                            </Form.Group>
                          </div>

                          <div className="col-md-12">
                            <Form.Group controlId="formBasicPassword">
                              <Form.Label>Email address</Form.Label>
                              <Form.Control
                                type="email"
                                placeholder="Your email address"
                                name="email"
                                autoComplete="off"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                              />
                              {errors.email && (
                              <p className="error">{errors.email}</p>
                              )}
                            </Form.Group>
                          </div>

                          <div className="col-md-12">
                            <Form.Group controlId="formBasicEmail">
                              <Form.Label>User Permission</Form.Label>
                              <Form.Control
                                as="select"
                                onChange={(e) => setUserPermission(e.target.value)}
                                value={userPermission}
                                selected={userPermission}
                              >
                                <option value="no">No Access</option>
                                <option value="basic">Basic</option>
                                <option value="low">Low</option>
                                <option value="medium">Medium</option>
                                <option value="high">High</option>
                              </Form.Control>
                            </Form.Group>
                          </div>

                          <div className="col-md-12">
                            <div className="online-booking">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onChange={() => setAppointmentBooking(!appointmentBooking)}
                                  checked={appointmentBooking}
                                  // onClick={handleMenu}
                                />
                                <span className="slider round" />
                              </label>
                              Enable Appointments Booking
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-12">
                            <Form.Group controlId="formBasicPassword">
                              <Form.Label>Staff Title</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Staff Title"
                                name="text"
                                autoComplete="off"
                                onChange={(e) => setStaffTitle(e.target.value)}
                                value={staffTitle}
                              />
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Notes</Form.Label>
                              <textarea
                                className="form-control text optional employee-modal-note"
                                rows="12"
                                placeholder="Add Notes"
                                name="employee[notes]"
                                id="employee_notes"
                                onChange={(e) => setNote(e.target.value)}
                                value={note}
                              />
                            </Form.Group>
                            <Form.Group>
                              <Row>
                                <Form.Group className="col-md-6">
                                  <Form.Label>Start Date</Form.Label>
                                  <Form.Control
                                    type="date"
                                    id="birthday"
                                    name="birthday"
                                    onChange={(e) => setStartEmployementDate(e.target.value)}
                                    value={startEmployementDate}
                                  />
                                </Form.Group>

                                <Form.Group className="col-md-6">
                                  <Form.Label>End Date</Form.Label>
                                  <Form.Control
                                    type="date"
                                    id="birthday"
                                    name="birthday"
                                    onChange={(e) => setEndEmployementDate(e.target.value)}
                                    value={endEmployementDate}
                                  />
                                </Form.Group>
                              </Row>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>

                <Tab eventKey="1" title="Services">
                  <div className="add-services">
                    <p className="text-muted ">
                      Assign services this staff member can perform.
                    </p>
                    <Form>
                      <div className="row">
                        <div className="col-md-12">
                          <Form.Group>
                            <InputGroup.Prepend>
                              <InputGroup.Checkbox
                                checked={selectAll}
                                onChange={handleSelectAll}
                              />
                              <Form.Label className="sel-1">
                                Select All
                              </Form.Label>
                            </InputGroup.Prepend>
                          </Form.Group>
                        </div>

                        {/* {Object.keys(services).map((el) => (
                          <>
                            {' '}
                            <div className="col-sm-4">
                              <Form.Group>
                                <InputGroup.Prepend>
                                  <InputGroup.Checkbox />
                                  <Form.Label>
                                    {services[el].servicename}
                                  </Form.Label>
                                </InputGroup.Prepend>
                              </Form.Group>
                            </div>
                          </>
                        ))} */}

                        {services.map((item, i) => (
                          <div className="col-sm-4" key={i}>
                            <Form.Group>
                              <InputGroup.Prepend>
                                <InputGroup.Checkbox
                                  checked={item.checked}
                                  value={item._id}
                                  onClick={(e) => handleCheck(e, item)}
                                />
                                <Form.Label>{item.servicename}</Form.Label>
                              </InputGroup.Prepend>
                            </Form.Group>
                          </div>
                        ))}
                      </div>
                    </Form>
                  </div>
                </Tab>

                <Tab eventKey="2" title="Commission">
                  <div className="add-commission">
                    <Form>
                      <div className="row">
                        <div className="col-sm-4">
                          <Form.Label>SERVICE COMMISSION</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              placeholder="0.00"
                              type="number"
                              aria-label="Amount (to the nearest dollar)"
                              onChange={(e) => setServiceCommission(e.target.value)}
                              value={serviceCommission}
                            />
                          </InputGroup>
                        </div>

                        {/* <div className="col-sm-4">
                          <Form.Label>PRODUCT COMMISSION</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              placeholder="0.00"
                              aria-label="Amount (to the nearest dollar)"
                            />
                          </InputGroup>
                        </div> */}

                        <div className="col-sm-4">
                          <Form.Label>VOUCHER COMMISSION</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              placeholder="0.00"
                              type="number"
                              aria-label="Amount (to the nearest dollar)"
                              onChange={(e) => setVoucherCommission(e.target.value)}
                              value={voucherCommission}
                            />
                          </InputGroup>
                        </div>
                      </div>
                    </Form>
                  </div>
                </Tab>
              </Tabs>

              <div className="row">
                <div className="col-md-12 ">
                  <div className="text-center mt">
                    <Button className="s-btn" type="submit">
                      Save
                    </Button>
                    <Button className="s-btn c-tn" type="submit">
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </PartnerWrapper>
  );
};

export default EditStaff;
