import React, { useEffect, useState } from "react";
import {Button, Form} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { validateForget, validateReset } from '../../../function/validate';
import { partnerInstance } from '../../../config/axios';
import history from '../../../config/history';
import queryString from 'query-string';


const PartnerForget = () => {
    const [email, setEmail] = useState('');
  const [errors, setErrors] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [reseterrors, setResetErrors] = useState(false);
  const [id, setId] = useState(false);
  useEffect(() => {
    const getData = async () => {
      const value = queryString.parse(window.location.search);
      const idd = value.id;
      console.log('id', idd);
      if (idd) {
        setId(true);
      }
    };
    getData();
  }, []);
  const forgetPassword = async (e) => {
    e.preventDefault();
    console.log('forgetpa-->');
    const validation = validateForget({
      email,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    let payload = { email };
    let saveData = await partnerInstance().post('/forgetPass', payload);
    let { code, msg } = saveData.data;
    const value = queryString.parse(window.location.search);
    const idd = value.id;
    console.log('id', idd);
    if (idd) {
      setId(true);
    }
    if (code === 200) {
      setEmail('');
      toast.success(msg, { containerId: 'B' });
    } else {
      toast.error(msg, { containerId: 'B' });
    }
  };
  const resetPassword = async (e) => {
    e.preventDefault();

    const validation = validateReset({
      password: password,
      repeatPassword: confirmPassword,
    });
    setResetErrors(validation.reseterrors);
    if (!validation.isValid) {
      return;
    }
    const token = window.location.href.split('id=')[1];
    console.log('password', password);
    console.log('token', token);
    if (token) {
      let payload = { password: password, token: token };
      let saveData = await partnerInstance().post('/reset', payload);
      let { code, msg } = saveData.data;
      if (code === 200) {
        setPassword('');
        setConfirmPassword('');
        toast.success(msg, { containerId: 'B' });
        history.push('/partners/login');
      } else {
        toast.error(msg, { containerId: 'B' });
      }
    } else {
      setResetErrors({ unexpected: 'Invalid request' });
    }
  };
  return (
    <div className="forget-password-section">
      <div className="forget-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {id ? (
                <div className="forget-form login">
                  <h1>Reset Password?</h1>
                  <div className="reset-box">
                    <Form onClick={resetPassword}>
                      <Form.Group controlId="formBasicloginone">
                        <Form.Control
                          type="password"
                          placeholder="New Password"
                          name="password"
                          value={password}
                          autoComplete="off"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        {reseterrors.password && (
                          <span className="error">{reseterrors.password}</span>
                        )}
                      </Form.Group>

                      <Form.Group controlId="formBasicloginone">
                        <Form.Control
                          type="password"
                          placeholder="Confirm Password"
                          name="confirmPassword"
                          value={confirmPassword}
                          autoComplete="off"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        {reseterrors.repeatPassword && (
                          <span className="error">
                            {reseterrors.repeatPassword}
                          </span>
                        )}
                      </Form.Group>
                      <Button className="reset-btn">Continue</Button>
                    </Form>
                  </div>
                </div>
              ) : (
                <>
                  <div className="forget-form login">
                    <h1>Forget Password?</h1>
                    <p>Enter your email address to reset your password</p>
                    <div className="forget-box">
                      <Form onClick={forgetPassword}>
                        <Form.Group controlId="formBasicloginone">
                          <Form.Control
                            type="email"
                            placeholder="Email address"
                            name="email"
                            value={email}
                            autoComplete="off"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {errors.email && (
                            <span className="error">{errors.email}</span>
                          )}
                        </Form.Group>
                        <Button className="reset-btn">Reset Password</Button>
                      </Form>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
  
  export default PartnerForget;