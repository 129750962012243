import React, { useState, useEffect } from 'react';
import './home.css';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { toast } from 'react-toastify';
import category1 from '../../assets/beauty.jpg';
import category2 from '../../assets/hair.jpg';
import category3 from '../../assets/nails.jpg';
import category4 from '../../assets/barbar.jpg';
import category5 from '../../assets/eyebrow.jpg';
import category6 from '../../assets/massage.jpg';
import category7 from '../../assets/therapy.jpg';
import category8 from '../../assets/spa.jpg';
import store1 from '../../assets/venue1.jpg';
import store2 from '../../assets/venue2.jpg';
import store3 from '../../assets/venue3.jpg';
import store4 from '../../assets/venue4.jpg';
import store5 from '../../assets/venue5.jpg';
import store6 from '../../assets/venue6.jpg';
import user from '../../assets/user.jpg';
import { websiteInstance } from '../../config/axios';

const Home = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const getBusinessCategories = async () => {
      const categoriesData = await websiteInstance().get(
        '/getBusinessCategories'
      );
      const { code, Categories } = categoriesData.data;
      console.log(Categories);
      if (code === 200) {
        setCategories(Categories);
      }
    };
    getBusinessCategories();
  }, []);
  return (
    <div className="home-wrapper">
      <div className="home-banner">
        <div className="container">
          <div className="banner-content">
            <h2>Appointment Made Easy</h2>
            <p>
              Aenean lacinia bibendum nulla sed consectetur. Nulla vitae elit
              libero, a pharetra augue.
            </p>
          </div>
        </div>
      </div>


    <div className="featured-store">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <h2 className="title">
                Featured <span>Store</span>
              </h2>

              <div className="row">
                <div className="col-md-12">
                  <div className="featured-video">
                    <ReactPlayer
                      light={false}
                      width="100%"
                      height="557px"
                      url="https://www.youtube-nocookie.com/embed/AXRZjCske8I?controls=0"
                    />
                  </div>
                </div>
              </div>

              {/* <div className="row">
                                <div className="col-md-4">
                                    <div className="featured-store-box">
                                        <div className="featured-store-thumb">
                                            <img src={store1} alt="store"/>
                                        </div>
                                        <div className="featured-store-info">
                                            <h2>Le Fix</h2>
                                            <p>45 Newman Street, London</p>
                                            <div className="featured-rating">
                                                <p><i className="fa fa-star"></i> <span>4.8 Great</span> 97 ratings</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="featured-store-box">
                                        <div className="featured-store-thumb">
                                           <img src={store2} alt="store"/>
                                        </div>
                                        <div className="featured-store-info">
                                            <h2>The Barbery</h2>
                                            <p>29 Webber Street, London</p>
                                            <div className="featured-rating">
                                                <p><i className="fa fa-star"></i> <span>5.0 Great</span> 245 ratings</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="featured-store-box">
                                        <div className="featured-store-thumb">
                                            <img src={store3} alt="store"/>
                                        </div>
                                        <div className="featured-store-info">
                                            <h2>Wellnesss Massage</h2>
                                            <p>152 Newcavendish, London</p>
                                            <div className="featured-rating">
                                                <p><i className="fa fa-star"></i> <span>4.8 Great</span> 155 ratings</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="featured-store-box">
                                        <div className="featured-store-thumb">
                                            <img src={store4} alt="store"/>
                                        </div>
                                        <div className="featured-store-info">
                                            <h2>ELP Barbershop</h2>
                                            <p>45 Newman Street, London</p>
                                            <div className="featured-rating">
                                                <p><i className="fa fa-star"></i> <span>4.8 Great</span> 97 ratings</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="featured-store-box">
                                        <div className="featured-store-thumb">
                                            <img src={store5} alt="store"/>
                                        </div>
                                        <div className="featured-store-info">
                                            <h2>Douce</h2>
                                            <p>29 Webber Street, London</p>
                                            <div className="featured-rating">
                                                <p><i className="fa fa-star"></i> <span>5.0 Great</span> 245 ratings</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="featured-store-box">
                                        <div className="featured-store-thumb">
                                            <img src={store6} alt="store"/>
                                        </div>
                                        <div className="featured-store-info">
                                            <h2>The Nail Shop</h2>
                                            <p>152 Newcavendish, London</p>
                                            <div className="featured-rating">
                                                <p><i className="fa fa-star"></i> <span>4.8 Great</span> 155 ratings</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
            </div>

            <div className="col-md-3">
              <h2 className="title">Testimonial</h2>

              <div className="scrollable-testimonial">
                <div className="testimonial-box">
                  <div className="testimonial-thumb">
                    <img src={user} alt="store" />
                  </div>
                  <div className="testimonial-info">
                    <h2>Charlie Rothes</h2>
                    <span>Businessman</span>
                    <p>
                      Aenean lacinia bibendum nulla sed consectetur. Nulla vitae
                      elit libero.
                    </p>
                  </div>
                </div>

                <div className="testimonial-box">
                  <div className="testimonial-thumb">
                    <img src={user} alt="store" />
                  </div>
                  <div className="testimonial-info">
                    <h2>Charlie Rothes</h2>
                    <span>Businessman</span>
                    <p>
                      Aenean lacinia bibendum nulla sed consectetur. Nulla vitae
                      elit libero.
                    </p>
                  </div>
                </div>

                <div className="testimonial-box">
                  <div className="testimonial-thumb">
                    <img src={user} alt="store" />
                  </div>
                  <div className="testimonial-info">
                    <h2>Charlie Rothes</h2>
                    <span>Businessman</span>
                    <p>
                      Aenean lacinia bibendum nulla sed consectetur. Nulla vitae
                      elit libero.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
