import React, { useState, useEffect } from 'react';
import { Button, Form, Modal, Dropdown, DropdownButton } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import cover from '../../assets/store/cover.jpg';
import store from '../../assets/store/store.jpg';
import store2 from '../../assets/store/store2.jpg';
import store3 from '../../assets/store/store3.jpg';
import store4 from '../../assets/store/store4.jpg';
import './store.css';
import { websiteInstance } from '../../config/axios';

const StoreListing = () => {
  const handlePageNumber = (number) => {
    getBusinessPartner(number);
  };

  const active = 2;
  const items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item
        key={number}
        // onClick={() => handlePageNumber(number)}
        active={number === active}
      >
        {number}
      </Pagination.Item>
    );
  }

  const [menu, setMenu] = useState(false);
  const [partners, setPartners] = useState([]);
  const [limit, setLimit] = useState(5);
  const [skip, setSkip] = useState(0);

  const nextPage = () => {
    setSkip(skip + limit);
  };

  const previousPage = () => {
    setSkip(skip - limit);
  };

  useEffect(() => {
    getBusinessPartner();
  }, [menu, skip, limit]);
  const value = queryString.parse(window.location.search);
  const { categoryid, businessname } = value;

  const getBusinessPartner = async () => {
    const payload = {
      categoryid,
      businessname,
      skip,
      limit,
    };
    const partnersData = await websiteInstance().post(
      '/getPartnersBycategory',
      payload
    );
    const { code, businesspartners } = partnersData.data;
    console.log(businesspartners);
    if (code === 200) {
      setPartners(businesspartners);
    }
  };

  const handleMenu = (e) => {
    setMenu(e.target.checked);
    console.log(e.target.checked, 'checkedddd');
  };

  return (
    <div className="store-listing">
      <div
        className="store-listing-cover"
        style={{ backgroundImage: `url(${cover})` }}
      >
        <div className="container">
          <h2>Beauty Salon</h2>
        </div>
      </div>

      <div className="store-listing-content">
        <div className="container">
          <div className="store-listing-filter">
            <div className="store-search-result">
              <p>88 results for Beauty Salon in London</p>
            </div>

            <div className="store-filter-box">
              <div className="online-booking">
                Map
                <label className="switch">
                  <input type="checkbox" onClick={handleMenu} />
                  <span className="slider round" />
                </label>
              </div>

              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic-nearest">
                  Nearest
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="/">Nearest</Dropdown.Item>
                  <Dropdown.Item href="/">Top Rated</Dropdown.Item>
                  <Dropdown.Item href="/">Newest</Dropdown.Item>
                  <Dropdown.Item href="/">Lowest</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic-gender">
                  Men and Women
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="/">Men and Women</Dropdown.Item>
                  <Dropdown.Item href="/">Men only</Dropdown.Item>
                  <Dropdown.Item href="/">Women only</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className={`store-location ${menu ? 'show' : ''}`}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d317718.69319292053!2d-0.3817765050863085!3d51.528307984912544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a00baf21de75%3A0x52963a5addd52a99!2sLondon%2C%20UK!5e0!3m2!1sen!2sin!4v1602672614482!5m2!1sen!2sin"
              width="100%"
              height="400"
              frameBorder="0"
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            />
          </div>

          <div className="store-listing-view">
            <Store partners={partners} />
          </div>

          <div className="store-pagination">
            {/* <Pagination>{items}</Pagination> */}
            <Pagination>
              {partners && partners.length < 5 ? (
                <>
                  {' '}
                  <Pagination.First onClick={previousPage} />{' '}
                </>
              ) : (
                <>
                  {skip === 0 ? (
                    <>
                      {' '}
                      <Pagination.Last onClick={nextPage} />{' '}
                    </>
                  ) : (
                    <>
                      {' '}
                      <Pagination.First onClick={previousPage} />
                      {/* <Pagination.Item active>{index}</Pagination.Item> */}
                      <Pagination.Last onClick={nextPage} />
                    </>
                  )}
                </>
              )}
              {/* <Pagination.First onClick={previousPage} /> */}

              {/* <Pagination.Item active>{index}</Pagination.Item> */}

              {/* <Pagination.Last onClick={nextPage} /> */}
            </Pagination>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreListing;

const Store = ({ partners }) => (
  <>
    {partners.map((el, i) => (
      <>
        <div className="store-list">
          <div className="store-image">
            <img src={store} alt="store" />
            <div className="store-favorite">
              <i className="fa fa-heart-o" />
            </div>
          </div>

          <div className="store-detail">
            <Link to={`/storedetail/?partnerid=${el._id}`}>
              <h3>{el.companyName}</h3>
            </Link>
            <ul>
              {el.address.map((ele, i) => (
                <>
                  <li>
                    <i className="fa fa-map-marker" /> {ele.locationname}
                    {ele.country}
                  </li>
                </>
              ))}

              <li>
                <i className="fa fa-phone" /> ({el.phoneCode}) {el.phone}
              </li>
            </ul>
            <div className="store-rating">
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star-half" />
              <span>(4.5)</span>
            </div>
          </div>
        </div>
      </>
    ))}
  </>
);
