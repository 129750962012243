import React, { useEffect, useState, useContext } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { validateLogin } from '../../../function/validate';
import { partnerInstance } from '../../../config/axios';
import PartnerContext from '../../../context/context';

const PartnerLogin = () => {
  const { isPartnerLogin, setIsPartnerLogin } = useContext(PartnerContext);
  const [passType, setPassType] = useState('password');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginerrors, setLoginErrors] = useState({});

  const login = async (e) => {
    e.preventDefault();
    const payload = {
      email,
      password,
    };
    const validation = validateLogin({ email, password });
    setLoginErrors(validation.loginerrors);
    if (!validation.isValid) {
      return;
    }
    const loginUser = await partnerInstance().post('/partnerLogin', payload);
    console.log(loginUser)
    const { code, msg, token ,authToken,userid } = loginUser.data;
    if (code === 200) {
      clearValues();
      localStorage.setItem('userid',userid );
      localStorage.setItem('partnertoken', token);
      localStorage.setItem('authtoken',authToken );
      setIsPartnerLogin(true);
      window.location.href = '/partners/dashboard';
      // history.push('/');
      // setIsLogin(true);
    } else {
      toast.error(msg, { containerId: 'B' });
    }
  };
  const clearValues = () => {
    setEmail('');
    setPassword('');
  };

  return (
    <div className="login">
      <div className="login-section">
        <div className="container">
          <div classNmae="row">
            <div className="col-md-12">
              <div className="login-form">
                <h1>Login with email</h1>
                <div className="login-box">
                  <Form onSubmit={login}>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Your email address"
                        name="email"
                        autoComplete="off"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {loginerrors.email && (
                        <p className="error">{loginerrors.email}</p>
                      )}
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <div className="password-show">
                        <Form.Control
                          type={passType}
                          placeholder="Your password"
                          name="password"
                          autoComplete="off"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <i
                          className={
                            passType === 'password'
                              ? 'fa fa-eye-slash'
                              : 'fa fa-eye'
                          }
                          onClick={() =>
                            setPassType(
                              passType === 'password' ? 'text' : 'password'
                            )
                          }
                        />
                      </div>
                      {loginerrors.password && (
                        <p className="error">{loginerrors.password}</p>
                      )}
                    </Form.Group>
                    <div className="forget-password">
                      <Link className="forget-link" to="/partners/forget">
                        Forget Password?
                      </Link>
                    </div>
                    <div className="login-button">
                      <Button type="submit" className="l-btn">
                        Log in
                      </Button>
                    </div>
                  </Form>
                  <p>
                    Don't have partner account yet?{' '}
                    <Link className="signup-link" to="/partners/register">
                      Sign Up
                    </Link>{' '}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerLogin;
