import React, { useState, useEffect } from 'react';
import {
  Button, Form, Modal, Dropdown, DropdownButton,
} from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import queryString from 'query-string';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import cover from '../../assets/store/cover.jpg';
import store1 from '../../assets/venue1.jpg';
import store2 from '../../assets/venue2.jpg';
import store3 from '../../assets/venue3.jpg';
import store4 from '../../assets/venue4.jpg';
import avatar from '../../assets/partner/user.jpg';
import avatar1 from '../../assets/partner/avatar-1.jpg';
import './store.css';
import { websiteInstance } from '../../config/axios';

const StoreDetail = () => {
  const [menu, setMenu] = useState(false);
  const [partner, setPartner] = useState({});
  const [companyName, setCompanyName] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [locationname, setLocationname] = useState('');
  const [country, setCountry] = useState('');
  const [categories, setCategories] = useState([]);

  const value = queryString.parse(window.location.search);
  const { partnerid } = value;

  useEffect(() => {
    getBusinessPartner();
  }, []);
  const getBusinessPartner = async () => {
    const payload = {
      partnerid,
    };
    const partnersData = await websiteInstance().post(
      '/getPartnerByPartnerId',
      payload,
    );
    const { code, partnerData } = partnersData.data;
    console.log('partnerData', partnerData);
    if (code === 200) {
      setPartner(partnerData);
      setCompanyName(partnerData.companyName);
      setPhone(partnerData.phone);
      setPhoneCode(partnerData.phoneCode);
      setCategories(partnerData.categoryid);
      partnerData.address.forEach((el) => {
        setLocationname(el.locationname);
        setCountry(el.country);
      });
    }
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const active = 2;
  const items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>,
    );
  }

  return (
    <div className="single-store">
      <div className="store-slider-container">
        <Slider {...settings}>
          <div className="store-slider">
            <img src={cover} alt="" />
          </div>
          <div className="store-slider">
            <img src={cover} alt="" />
          </div>
          <div className="store-slider">
            <img src={cover} alt="" />
          </div>
          <div className="store-slider">
            <img src={cover} alt="" />
          </div>
          <div className="store-slider">
            <img src={cover} alt="" />
          </div>
          <div className="store-slider">
            <img src={cover} alt="" />
          </div>
        </Slider>
      </div>

      <div className="store-detail-content">
        <div className="container">
          <div className="store-about">
            <div className="row">
              <div className="col-md-8">
                <div className="store-detail-info">
                  <h2>
                    {companyName}
                    {/* <span>Featured</span> */}
                  </h2>
                  <div className="store-detail">
                    <ul>
                      <li>
                        <i className="fa fa-map-marker" />
                        {' '}
                        {locationname}
                        ,
                        {country}
                      </li>
                      <li>
                        <i className="fa fa-phone" />
                        {' '}
                        (
                        {phoneCode}
                        )
                        {phone}
                      </li>
                    </ul>
                    <div className="store-rating">
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star-half" />
                      <span>(4.5)</span>
                    </div>
                  </div>
                </div>

                <div className="about-store">
                  <h3 className="heading-title">
                    About
                    {companyName}
                  </h3>

                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>

                  <ul>
                    <li>
                      <i className="fa fa-info" />
                      {' '}
                      Instant Confirmation
                    </li>
                    <li>
                      <i className="fa fa-male" />
                      {' '}
                      Unisex Salon
                    </li>
                    <li>
                      <i className="fa fa-globe" />
                      {' '}
                      www.example.com
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-4">
                <div className="book-now">
                  <Button>Book Now</Button>
                </div>

                <div className="opening-hours">
                  <h3>
                    <i className="fa fa-calendar" />
                    {' '}
                    Business Hours
                  </h3>
                  <ul>
                    <li>
                      Monday
                      {' '}
                      <span>10:00 AM - 06:00 PM</span>
                    </li>
                    <li>
                      Tuesday
                      {' '}
                      <span>10:00 AM - 06:00 PM</span>
                    </li>
                    <li>
                      Wednesday
                      {' '}
                      <span>10:00 AM - 06:00 PM</span>
                    </li>
                    <li>
                      Thursday
                      {' '}
                      <span>10:00 AM - 06:00 PM</span>
                    </li>
                    <li>
                      Friday
                      {' '}
                      <span>10:00 AM - 06:00 PM</span>
                    </li>
                    <li>
                      Saturday
                      {' '}
                      <span>Closed</span>
                    </li>
                    <li>
                      Sunday
                      {' '}
                      <span>Closed</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="store-services">
            <div className="row">
              <div className="col-md-12">
                <h3 className="heading-title">Services</h3>

                <div className="store-service-tab">
                  <Tabs
                    defaultActiveKey="Featured"
                    id="uncontrolled-tab-example"
                  >
                    {categories.map((el, i) => (
                      <Tab eventKey={i} title={el.categoryname}>
                        <TabContent serviceid={el.serviceid} />
                      </Tab>
                    ))}

                    {/* <Tab
                      eventKey="Salma Senior Hairstylist"
                      title="Salma Senior Hairstylist"
                    >
                      <TabContent />
                    </Tab>
                    <Tab
                      eventKey="Izacc Advanced Hair Styling"
                      title="Izacc Advanced Hair Styling"
                    >
                      <TabContent />
                    </Tab>
                    <Tab
                      eventKey="Hannah Stylish Cutting"
                      title="Hannah Stylish Cutting"
                    >
                      <TabContent />
                    </Tab>
                    <Tab eventKey="Treatments" title="Treatments">
                      <TabContent />
                    </Tab> */}
                  </Tabs>
                </div>
              </div>
            </div>
          </div>

          <div className="store-featured-service">
            <div className="row">
              <div className="col-md-12">
                <h3 className="heading-title">Featured Services</h3>

                <div className="featured-service-list">
                  <div className="featured-service-box">
                    <div className="service-icon">BT</div>
                    <h5>Body Treatment</h5>
                    <span>Senior Stylist</span>
                  </div>

                  <div className="featured-service-box">
                    <div className="service-icon">AH</div>
                    <h5>Advanced Hair cut</h5>
                    <span>Director</span>
                  </div>

                  <div className="featured-service-box">
                    <div className="service-icon">HM</div>
                    <h5>Head Massage</h5>
                    <span>Stylist</span>
                  </div>

                  <div className="featured-service-box">
                    <div className="service-icon">SH</div>
                    <h5>Stylish Hair Cut</h5>
                    <span>Hair Stylist</span>
                  </div>

                  <div className="featured-service-box">
                    <div className="service-icon">BT</div>
                    <h5>Body Treatment</h5>
                    <span>Senior Stylist</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="store-review">
            <div className="row">
              <div className="col-md-8">
                <div className="comments utf_listing_reviews">
                  <h3 className="heading-title">Reviews</h3>

                  <ul>
                    <li>
                      <div className="avatar">
                        <img src={avatar1} alt="" />
                      </div>
                      <div className="utf_comment_content">
                        <div
                          className="utf_star_rating_section"
                          data-rating="5"
                        >
                          <span className="star" />
                          <span className="star" />
                          <span className="star" />
                          <span className="star" />
                          <span className="star" />
                        </div>

                        <div className="utf_by_comment">
                          Francis Burton
                          <span className="date">
                            <i className="fa fa-clock-o" />
                            {' '}
                            Jan 05, 2019 - 8:52
                            am
                          </span>
                        </div>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Maecenas in pulvinar neque. Nulla finibus
                          lobortis pulvinar. Donec a consectetur nulla. Nulla
                          posuere sapien vitae lectus suscipit, et pulvinar nisi
                          tincidunt. Aliquam erat volutpat.
                        </p>
                      </div>
                    </li>

                    <li>
                      <div className="avatar">
                        <img src={avatar} alt="" />
                      </div>
                      <div className="utf_comment_content">
                        <div
                          className="utf_star_rating_section"
                          data-rating="5"
                        >
                          <span className="star" />
                          <span className="star" />
                          <span className="star" />
                          <span className="star" />
                          <span className="star" />
                        </div>

                        <div className="utf_by_comment">
                          Alex Carley
                          <span className="date">
                            <i className="fa fa-clock-o" />
                            {' '}
                            Jan 012 2019 - 12:45
                            am
                          </span>
                        </div>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Maecenas in pulvinar neque. Nulla finibus
                          lobortis pulvinar. Donec a consectetur nulla. Nulla
                          posuere sapien vitae lectus suscipit, et pulvinar nisi
                          tincidunt. Aliquam erat volutpat.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="store-pagination">
                  <Pagination>{items}</Pagination>
                </div>
              </div>

              <div className="col-md-4">
                <div className="opening-hours">
                  <h3>
                    <i className="fa fa-envelope" />
                    {' '}
                    Add Review
                  </h3>
                  <Form>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="utf_leave_rating margin-bottom-30">
                          <input
                            type="radio"
                            name="rating"
                            id="rating-1"
                            value="1"
                          />
                          <label htmlFor="rating-1" className="fa fa-star" />
                          <input
                            type="radio"
                            name="rating"
                            id="rating-2"
                            value="2"
                          />
                          <label htmlFor="rating-2" className="fa fa-star" />
                          <input
                            type="radio"
                            name="rating"
                            id="rating-3"
                            value="3"
                          />
                          <label htmlFor="rating-3" className="fa fa-star" />
                          <input
                            type="radio"
                            name="rating"
                            id="rating-4"
                            value="4"
                          />
                          <label htmlFor="rating-4" className="fa fa-star" />
                          <input
                            type="radio"
                            name="rating"
                            id="rating-5"
                            value="5"
                          />
                          <label htmlFor="rating-5" className="fa fa-star" />
                          <span>Rating: </span>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          name="name"
                          autoComplete="off"
                        />
                      </div>
                      <div className="col-md-12">
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          name="email"
                          autoComplete="off"
                        />
                      </div>
                      <div className="col-md-12">
                        <Form.Control
                          as="textarea"
                          type="text"
                          placeholder="Your Message"
                          name="message"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <Button>Submit Review</Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>

          <div className="near-venue">
            <h3 className="heading-title">About Emilia Hair Studio</h3>

            <div className="row">
              <div className="col-md-3">
                <div className="featured-store-box">
                  <div className="featured-store-thumb">
                    <img src={store1} alt="store" />
                  </div>
                  <div className="featured-store-info">
                    <h2>Le Fix</h2>
                    <p>45 Newman Street, London</p>
                    <div className="featured-rating">
                      <p>
                        <i className="fa fa-star" />
                        {' '}
                        <span>4.8 Great</span>
                        {' '}
                        97
                        ratings
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="featured-store-box">
                  <div className="featured-store-thumb">
                    <img src={store2} alt="store" />
                  </div>
                  <div className="featured-store-info">
                    <h2>The Barbery</h2>
                    <p>29 Webber Street, London</p>
                    <div className="featured-rating">
                      <p>
                        <i className="fa fa-star" />
                        {' '}
                        <span>5.0 Great</span>
                        {' '}
                        245
                        ratings
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="featured-store-box">
                  <div className="featured-store-thumb">
                    <img src={store3} alt="store" />
                  </div>
                  <div className="featured-store-info">
                    <h2>Wellnesss Massage</h2>
                    <p>152 Newcavendish, London</p>
                    <div className="featured-rating">
                      <p>
                        <i className="fa fa-star" />
                        {' '}
                        <span>4.8 Great</span>
                        {' '}
                        155
                        ratings
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="featured-store-box">
                  <div className="featured-store-thumb">
                    <img src={store4} alt="store" />
                  </div>
                  <div className="featured-store-info">
                    <h2>ELP Barbershop</h2>
                    <p>45 Newman Street, London</p>
                    <div className="featured-rating">
                      <p>
                        <i className="fa fa-star" />
                        {' '}
                        <span>4.8 Great</span>
                        {' '}
                        97
                        ratings
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreDetail;

const TabContent = ({ serviceid }) => {
  console.log('serviceDetail', serviceid);

  return (
    <div className="store-service-tab-content">
      <div className="row">
        <div className="col-md-12">
          <div className="service-selection">
            {serviceid.map((el, i) => (
              <div className="service-list-view">
                <div className="service-checkbox">
                  <Form.Check type="checkbox" label="" />
                </div>
                <div className="service-detail">
                  <div className="service-info">
                    <h4>{el.servicename}</h4>
                    <p>
                      {el.durationTotal}
                      <span>Senior Stylist</span>
                    </p>
                  </div>
                  <div className="service-cost">
                    <h5>
                      $
                      {el.priceTotal}
                    </h5>
                  </div>
                </div>
              </div>
            ))}

            {/* <div className="service-list-view">
              <div className="service-checkbox">
                <Form.Check type="checkbox" label="" />
              </div>
              <div className="service-detail">
                <div className="service-info">
                  <h4>Advanced Cut & Finish - Izacc</h4>
                  <p>
                    1h
                    <span>Senior Stylist</span>
                  </p>
                </div>
                <div className="service-cost">
                  <h5>$50</h5>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
