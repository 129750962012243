import React, { useEffect, useState } from 'react';
import { Table, Button, Tab } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import PartnerWrapper from '../partnerwrapper/wrapper';
import UserPermission from './userpermission';
import CloseStaff from './closestaff';
import StaffMember from './staffmember';
import StaffWorkingHour from './staffworkinghour';
import './staff.css';

const PartnerStaff = () => (
  // const [tab, setTab] = useState('');

  // useEffect(() => {
  //   // const tab = localStorage.setItem('tab');
  // }, []);

  // const handleTab = (e) => {
  //   console.log('id', e);
  //   localStorage.setItem('tab', id);
  // };
  <PartnerWrapper>
    <div className="staff-page">
      <div className="container">
        <h2 className="admin-title">Associates</h2>

        <div className="staff-content">
          <Tabs defaultActiveKey="0" id="uncontrolled-tab-example">
            <Tab eventKey="0" title="Associate Listing">
              <StaffMember />
            </Tab>

            <Tab eventKey="1" title="Shifts Definition">
              <StaffWorkingHour />
            </Tab>
            <Tab eventKey="2" title="Holidays">
              <CloseStaff />
            </Tab>

            
            <Tab eventKey="3" title="Permission level">
              <UserPermission />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  </PartnerWrapper>
);
export default PartnerStaff;
