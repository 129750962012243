import React, { useState } from "react";
import "./login.css";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { validateLogin } from "../../function/validate";
import { userInstance } from "../../config/axios";
//dummy text for code setup
const Login = ({ setIsLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginerrors, setLoginErrors] = useState({});
  const [passType, setPassType] = useState("password");

  const login = async (e) => {
    e.preventDefault();
    let payload = {
      email: email,
      password: password,
    };
    const validation = validateLogin({ email: email, password: password });
    setLoginErrors(validation.loginerrors);
    if (!validation.isValid) {
      return;
    }
    let loginUser = await userInstance().post("/login", payload);
    let { code, msg, token } = loginUser.data;
    if (code === 200) {
      clearValues();
      localStorage.setItem("usertoken", token);
      setIsLogin(true);
      window.location.href = "/";
      // history.push('/');
      // setIsLogin(true);
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };
  const clearValues = () => {
    setEmail("");
    setPassword("");
  };

  return (
    <div className="login">
      <div className="login-section">
        <div className="container">
          <div classNmae="row">
            <div className="col-md-12">
              <div className="login-form">
                <h1>Login with email</h1>
                <div className="login-box">
                  <Form onSubmit={login}>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Your email address"
                        name="email"
                        autoComplete="off"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {loginerrors.email && (
                        <p className="error">{loginerrors.email}</p>
                      )}
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <div className="password-show">
                        <Form.Control
                          type={passType}
                          placeholder="Your password"
                          name="password"
                          autoComplete="off"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <i
                          className={
                            passType === "password"
                              ? "fa fa-eye-slash"
                              : "fa fa-eye"
                          }
                          onClick={() =>
                            setPassType(
                              passType === "password" ? "text" : "password"
                            )
                          }
                        ></i>
                      </div>
                      {loginerrors.password && (
                        <p className="error">{loginerrors.password}</p>
                      )}
                    </Form.Group>
                    <div className="forget-password">
                      <Link className="forget-link" to={"/forget"}>
                        Forget Password?
                      </Link>
                    </div>
                    <div className="login-button">
                      <Button type="submit" className="l-btn">
                        Log in
                      </Button>
                    </div>
                  </Form>
                  <p>
                    Don't have an account yet?{" "}
                    <Link className="signup-link" to={"/register"}>
                      Sign Up
                    </Link>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
