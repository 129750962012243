import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import PartnerWrapper from '../partnerwrapper/wrapper';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
// import FormControl from 'react-bootstrap/FormControl';
import { toast } from 'react-toastify';
import InputGroup from 'react-bootstrap/InputGroup';
import './style.css';
import { Button, Tab } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import { partnerInstance } from '../../../config/axios';
import { validateInvoice } from '../../../function/validate';
import history from '../../../config/history';
import InvoiceSequencing from './invoicesequencing';

const Invoice = () => {
  const [title, setTitle] = useState('');
  const [customLine1, setCustomLine1] = useState('');
  const [customLine2, setCustomLine2] = useState('');
  const [receiptFooter, setReceiptFooter] = useState('');
  const [automaticPrint, setAutomaticPrint] = useState(false);
  const [clientMobile, setClientMobile] = useState(false);
  const [clientAddress, setClientAddress] = useState(false);
  const [errors, setErrors] = useState({});
  useEffect(() => {
    const getInvoice = async () => {
      const invoiceData = await partnerInstance().get('/getInvoice');
      const { code, invoice } = invoiceData.data;
      if (code === 200) {
        setTitle(invoice.title);
        setCustomLine1(invoice.customline1);
        setCustomLine2(invoice.customline2);
        setReceiptFooter(invoice.footer);
        setClientMobile(invoice.clientmobile);
        setClientAddress(invoice.clientaddress);
        setAutomaticPrint(invoice.automaticprint);
      }
    };

    getInvoice();
  }, []);

  const addInvoiceTemplate = async (e) => {
    e.preventDefault();
    const payload = {
      title,
      customline1: customLine1,
      customline2: customLine2,
      footer: receiptFooter,
      automaticprint: automaticPrint,
      clientmobile: clientMobile,
      clientaddress: clientAddress,
    };
    const validation = validateInvoice({
      title,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    const updateTemplate = await partnerInstance().put('/editInvoice', payload);
    const { code, msg } = updateTemplate.data;
    if (code === 200) {
      toast.success(msg, { containerId: 'B' });
      history.push('/partners/setup');
      // clearValues();
    } else {
      toast.error(msg, { containerId: 'B' });
    }
  };

  return (
    <PartnerWrapper>
      <div className="invoice staff-page">
        <div className="container">
          <div className="row">

            <div className="col-md-12">




            <div className="invoice-tab-content">
              <Tabs defaultActiveKey="0" id="invoice-tab">

                <Tab eventKey="0" title="Invoice Configuration">
                  <Form onSubmit={addInvoiceTemplate}>
                  <Card className="inv-12">
                    <Card.Img variant="top" />
                    <Card.Body>
                      <Card.Text>
                      <div className="row">
                      <div className="col-md-6">
                        <Form.Group>
                          <Form.Label>Invoice Title</Form.Label>

                          <Form.Control
                            size="lg"
                            type="text"
                            placeholder="Invoice"
                            onChange={(e) => setTitle(e.target.value)}
                            value={title}
                          />
                          {errors.title && (
                          <p className="error">{errors.title}</p>
                          )}
                        </Form.Group>
                        </div>

                        <div className="col-md-6">
                        <Form.Group>
                          <Form.Label>Invoice Line 1</Form.Label>

                          <Form.Control
                            size="lg"
                            type="text"
                            placeholder=""
                            onChange={(e) => setCustomLine1(e.target.value)}
                            value={customLine1}
                          />
                        </Form.Group>
                        </div>
                        <div className="col-md-6">
                        <Form.Group>
                          <Form.Label>Invoice Line 2</Form.Label>

                          <Form.Control
                            size="lg"
                            type="text"
                            placeholder=""
                            onChange={(e) => setCustomLine2(e.target.value)}
                            value={customLine2}
                          />
                        </Form.Group>
                        </div>
                        <div className="col-md-6">
                        <Form.Group>
                          <Form.Label>Invoice Footer</Form.Label>

                          <Form.Control
                            size="lg"
                            type="text"
                            placeholder=""
                            onChange={(e) => setReceiptFooter(e.target.value)}
                            value={receiptFooter}
                          />
                        </Form.Group>
                          </div>

                          
                          <div className="col-md-12">
                        <Form.Group>
                          <InputGroup.Prepend>
                          <div className="online-booking">
                          <label className="switch">
                            <input
                              type="checkbox"
                            />
                            <span className="slider round" />
                          </label>
                        </div>
                            <Form.Label className="">
                              Print receipts upon sale completion
                            </Form.Label>
                          </InputGroup.Prepend>
                        </Form.Group>
                        </div>

                    <div className="col-md-12">
                        <Form.Group>
                          <InputGroup.Prepend>
                          <div className="online-booking">
                          <label className="switch">
                            <input
                              type="checkbox"
                            />
                            <span className="slider round" />
                          </label>
                        </div>
                            <Form.Label className="">
                              Customer contact visible on invoices
                            </Form.Label>
                          </InputGroup.Prepend>
                        </Form.Group>
                        </div>
                        </div>

                      </Card.Text>
                    </Card.Body>

                    <div className="account-save mt-2 col-md-12">
                      <Button type="submit" className="btn btn-primary">
                        Save
                      </Button>
                    </div>
                  </Card>
                </Form>
                </Tab>

                <Tab eventKey="1" title="Invoice Sequencing">
                      <InvoiceSequencing />
                </Tab>
              </Tabs>
            </div>




              
            </div>
          </div>
        </div>
      </div>
    </PartnerWrapper>
  );
};

export default Invoice;
