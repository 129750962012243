import React, { useState, useEffect, useContext } from 'react';
import menu from '../../../assets/partner/menu-icon.png';
import admin from '../../../assets/partner/user.jpg';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import PartnerContext from '../../../context/context';

const PartnerHeader = ({
  setMenuToggle,
  menutoggle,
}) => {
  const { isPartnerLogin, setIsPartnerLogin } = useContext(PartnerContext);
  const { PartnerLogOut } = useContext(PartnerContext);
  const { username } = useContext(PartnerContext);
  return (
    <div className="admin-header">
      <div className="menu-toggle">
        <span onClick={() => setMenuToggle(!menutoggle)}>
          <img src={menu} alt="Menu" />
        </span>
      </div>
      {isPartnerLogin ? (
        <>
          <div className="admin-info">
            <img src={admin} alt="Admin" />
            <DropdownButton id="dropdown-basic-button" title={username}>
              <Dropdown.Item href="/">My Profile</Dropdown.Item>
              <Dropdown.Item href="/">Settings</Dropdown.Item>
              <Dropdown.Item onClick={() => PartnerLogOut()}>
                Logout
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </>
      ) : (
        <>
          <div className="admin-info">
            <Dropdown.Item href="/">My Profile</Dropdown.Item>
          </div>
        </>
      )}
    </div>
  );
};

export default PartnerHeader;
