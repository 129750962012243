import React from "react";
import {Link} from "react-router-dom"
import "./footer.css";



const Footer = () => {
    return (
        <div className="footer">

<footer className="footer-dark">
    <section className="section-b-space">
        <div className="container">
            <div className="row">
                <div className="col-md-4">
                    <div className="sub-title">
                        <div className="footer-title">
                            <h4>Location</h4></div>
                            <div className="footer-contant" >
                                <p>Moon Street Light Avenue, 14/50 Jupiter 345-659</p>
                            </div>
                        </div>
                    
                </div>
                <div className="col-md-4">
                    <div className="sub-title">
                        <div className="footer-title">
                            <h4>Follow</h4></div>
                            <div className="footer-social">
                            <ul>
                                <li><Link to="/"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                                <li><Link to="/"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>                                
                                <li><Link to="/"><i className="fa fa-instagram" aria-hidden="true"></i></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="sub-title">
                        <div className="footer-title">
                            <h4>Contact</h4></div>
                            <div className="footer-contant">
                            <ul className="contact-list">
                                <li><a href="#">info@apoointlee.com</a></li>
                                <li>123-456-7898</li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div className="sub-footer ">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <ul>
                        <li><Link to="/">Booking terms</Link></li>
                        <li><Link to="/">Privacy policy</Link></li>                                
                        <li><Link to="/">Website terms</Link></li>
                        <li><Link to="/">Careers</Link></li>                                
                        <li><Link to="/">Customer support</Link></li>
                    </ul>
                    <div className="footer-end">
                        <p><i className="fa fa-copyright" aria-hidden="true"></i> 2020 Appointlee All rights reserved</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
          
        </div>
    );
  };
  
export default Footer;