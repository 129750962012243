import React, { useState, useEffect } from 'react';
import { Table, Button, Tab } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { toast } from 'react-toastify';
import Closedatemodal from './closedatemodal';
import { partnerInstance } from '../../../config/axios';

const CloseStaff = () => {
  const [dateModal, setDateModal] = useState(false);
  const [item, setItem] = useState({});
  const [data, setData] = useState([]);
  const [call, setCall] = useState(false);
  useEffect(() => {
    fetchUsers();
    // changes made by kashif
  }, [dateModal,call]);
  const fetchUsers = async () => {
    const response = await partnerInstance().get('/listCloseDate');
    setData(response.data);
  };

  const confirmPopup = (id) => {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to delete this Closed Date.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            removeClosedDate(id);
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const removeClosedDate = async (id) => {
    setCall(false);
    const response = await partnerInstance().delete(`/deleteclosedDate/${id}`);
    if (response) {
      toast.success('closed Date Deleted Successfully', {
        containerId: 'B',
      });
      setCall(true);
    }
  };
  return (
    <div className="close-staff">
      <div className="container">
        <div className="close-date user-12">
          <Button
            onClick={() => {
              setDateModal(true);
              setItem(null);
            }}
          >
            New Closed Date
          </Button>
        </div>
        <div className="partner-table">
          <Table responsive="lg">
          <thead>
            <tr>
              <th>From Date</th>
              <th>To Date</th>
              <th>No. of days</th>
              <th>Locations</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, i) => (
              <tr>
                <td>{`${item.from}`}</td>
                <td>{`${item.to}`}</td>
                <td>
                  {Math.round(
                    (new Date(item.to) - new Date(item.from))
                      / (1000 * 60 * 60 * 24),
                  )}
                </td>
                <td>Websul</td>
                <td>{item.description}</td>
                <td>
                    <Button
                      onClick={() => {
                        setDateModal(true);
                        setItem(item);
                      }}
                    ><i className="fa fa-edit"></i></Button>
                     <Button
                    variant="danger"
                      onClick={() => {
                        confirmPopup(item._id);
                      }}
                    ><i className="fa fa-trash"></i></Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        </div>
      </div>
      <Closedatemodal show={dateModal} setShow={setDateModal} item={item} />
    </div>
  );
};

export default CloseStaff;
