import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { Button } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import PartnerWrapper from '../partnerwrapper/wrapper';
import { validatePaymentType } from '../../../function/validate';
import './style.css';
import { partnerInstance } from '../../../config/axios';

const PaymentTaxes = () => {
  const [showEdit, setShowEdit] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [paymentname, setPaymentname] = useState('');
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [paymentTypeId, setPaymentTypeId] = useState('');
  const [errors, setErrors] = useState({});

  const handleClose = () => {
    setShow(false);
    setShowEdit(false);
  };
  useEffect(() => {
    getPaymentType();
  }, []);
  const getPaymentType = async () => {
    const paymentData = await partnerInstance().get('/getPaymentType');
    const { code, paymentType } = paymentData.data;
    if (code === 200) {
      setPaymentTypes(paymentType);
    }
  };

  const addPaymenttype = async (e) => {
    e.preventDefault();
    const payload = {
      paymentname,
    };
    const validation = validatePaymentType({
      paymentname,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    const paymenttype = await partnerInstance().post(
      '/addPaymentType',
      payload,
    );
    const { code, msg } = paymenttype.data;
    if (code === 200) {
      toast.success(msg, { containerId: 'B' });
      setPaymentname('');
      setShow(false);
      getPaymentType();
      // getInvoiceSequencing();
      // history.push('/partners/staff');
      // clearValues();
    } else {
      toast.error(msg, { containerId: 'B' });
    }
  };
  const handleShowEdit = (id, paymentname) => {
    setPaymentTypeId(id);
    setPaymentname(paymentname);
    setShowEdit(true);
    setErrors('');
  };

  return (
    <PartnerWrapper>
      <div className="payment-tax">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="admin-title">Payment Types
                <Button onClick={handleShow}>Add Payment Type</Button>
              </h2>
              <div className="partner-table">
                <Table responsive="lg">
                <thead>
                  <tr>
                    <th>Seriel No.</th>
                    <th>Payment Type</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentTypes.map((el, i) => (
                    <tr>
                      <td>
                        1
                      </td>
                      <td>{el.paymentname}</td>
                      <td> 
                        <Button>
                           <i className="fa fa-pencil-square-o" />
                        </Button>
                        <Button>
                           <i className="fa fa-trash" />
                        </Button>
                      </td>
                     
                    </tr>
                  ))}
                </tbody>
              </Table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} className="pay-12">
        <Modal.Header closeButton>
          <div />
          <Modal.Title id="example-custom-modal-styling-title ">
            Add Payment Type
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={addPaymenttype}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Payment Type Name</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                placeholder="Cash"
                onChange={(e) => setPaymentname(e.target.value)}
                value={paymentname}
              />
              {errors.paymentname && (
                <p className="error">{errors.paymentname}</p>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={showEdit} onHide={handleClose} className="pay-12">
        <Modal.Header closeButton>
          <div />
          <Modal.Title id="example-custom-modal-styling-title ">
            Edit Payment Type
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <EditPaymentType
            paymentTypeId={paymentTypeId}
            paymentname={paymentname}
            setPaymentname={setPaymentname}
            setShowEdit={setShowEdit}
            getPaymentType={() => getPaymentType()}
            setErrors={setErrors}
            errors={errors}
          />
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    </PartnerWrapper>
  );
};

export default PaymentTaxes;

function EditPaymentType({
  paymentTypeId,
  paymentname,
  setShowEdit,
  setPaymentname,
  getPaymentType,
  setErrors,
  errors,
}) {
  // console.log(setShowEdit, categoryId);
  // const [categoryname, setCategoryname] = useState('');
  // const [categorydescription, setCategorydescription] = useState('');
  // const [errors, setErrors] = useState({});
  const updatePaymentType = async (e) => {
    console.log(e);
    try {
      e.preventDefault();
      const payloadd = {
        paymentname,
      };
      const validation = validatePaymentType({
        paymentname,
      });
      console.log('validation', validation);
      setErrors(validation.errors);
      if (!validation.isValid) {
        return;
      }

      const updatepaymenttype = await partnerInstance().put(
        `/editPaymentType/${paymentTypeId}`,
        payloadd,
      );
      const { code, msg } = updatepaymenttype.data;
      if (code === 200) {
        // setCategoryname(categoryAdded.categoryname);

        setShowEdit(false);
        getPaymentType();
        toast.success(msg, { containerId: 'B' });
        clearValues();
      } else if (code === 409) {
        toast.error(msg, { containerId: 'B' });
      } else {
        toast.error(msg, { containerId: 'B' });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const clearValues = () => {
    setPaymentname('');
  };
  return (
    <Form onSubmit={updatePaymentType}>
      <Form.Group>
        <Form.Label>Payment name</Form.Label>
        <Form.Control
          size="lg"
          type="text"
          placeholder="Tax name"
          onChange={(e) => setPaymentname(e.target.value)}
          value={paymentname}
        />
        {errors.paymentname && <p className="error">{errors.paymentname}</p>}
      </Form.Group>

      <Button variant="primary" type="submit">
        Update
      </Button>
    </Form>
  );
}
