import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Button, Form, Modal, InputGroup, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import { partnerInstance } from '../../../config/axios';
import PartnerWrapper from '../partnerwrapper/wrapper';
import { validatePackage } from '../../../function/validate';
import history from '../../../config/history';
import PartnerContext from '../../../context/context';

const j = 1;

const PackageServices = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [view, setView] = useState(false);
  const handleViewClose = () => setView(false);
  const handleViewShow = () => setView(true);
  const [categories, setCategories] = useState([]);
  const [services, setServices] = useState([]);
  const [servicename, setServicename] = useState('');
  const [serviceid, setServiceId] = useState([]);
  const [category, setCategory] = useState('');
  const [packageCategoryId, setPackageCategoryId] = useState('');
  const [packagedescription, setPackagedescription] = useState('');
  const [packagegender, setPackageGender] = useState('everyone');
  const [errors, setErrors] = useState({});
  const [onlineBooking, setOnlineBooking] = useState(true);
  const [scheduleType, setScheduleType] = useState('sequence');
  const [pricingPanel, setPricingPanel] = useState([50]);
  const [retailPrice, setRetailPrice] = useState('');
  const [priceType, setPriceType] = useState('Service pricing');
  const [packagename, setPackagename] = useState('');
  const [serviceDuration, setServiceDuration] = useState('');
  const [servicePrice, setServicePrice] = useState('');
  const [servicePanel, setServicePanel] = useState([]);
  // const [priceTotal, setPriceTotal] = useState('');
  const [pricingTotal, setPricingTotal] = useState(0);
  const [specialPrice, setPackageSpecialPrice] = useState('');
  const [packageDuration, setPackageDuration] = useState('');
  const [packagePrice, setPackagePrice] = useState('');
  // console.log('setPackageCategoryId', packageCategoryid);
  const [valid, setValid] = useState(true);
  const [discountedValue, setDiscountedValue] = useState('');
  // let [pricetotal, setPricetotal] = useState(0);
  const [totalPrice, setTotalPrice] = useState('');
  const [categoriesCopy, setCategoriesCopy] = useState([]);
  const [servicesCopy, setServicesCopy] = useState([]);
  const [serviceDurationBefore, setServiceDurationBefore] = useState('');
  const [serviceDurationAfter, setServiceDurationAfter] = useState('');
  const { currency } = useContext(PartnerContext);

  useEffect(() => {
    const getCategories = async () => {
      const categoriesData = await partnerInstance().get('/getCategories');
      const { code, categories: Categories } = categoriesData.data;
      if (code === 200) {
        setCategories(Categories);
        setCategoriesCopy(Categories);
      }
    };
    const value = queryString.parse(window.location.search);
    const { catid, catname } = value;
    if (catid) {
      setCategory(catname);
      setPackageCategoryId(catid);
    }

    const getServices = async () => {
      const payloadd = {
        catId: packageCategoryId,
      };
      // console.log('payloadd', payloadd);
      // const catId = packageCategoryid;
      // console.log('catId', catId);
      const servicesData = await partnerInstance().post(
        '/getServicesByCategory',
        payloadd
      );
      const { code, services: Services } = servicesData.data;
      if (code === 200) {
        console.log('Services', Services);
        const dataa = [Services];
        setServices(dataa);
        setServicesCopy(dataa);
      }
    };

    getCategories();
    getServices();
  }, [packageCategoryId]);

  function useUpdate() {
    const [, setTick] = useState(0);
    const update = useCallback(() => {
      setTick((tick) => tick + 1);
    }, []);
    return update;
  }

  const update = useUpdate();

  const handleCustomPrice = (e) => {
    // setRetailPrice(e);
    if (pricetotal <= e) {
      // valid = false;
      setValid(false);

      update();
    } else {
      setValid(true);
      // console.log(pricetotal, e);
      const discount = pricetotal - e;
      setDiscountedValue(discount);
      // setPricetotal(e);
      pricetotal = e;

      update();
    }
  };
  const handlePercentDiscount = (e) => {
    if (e > 100) {
      // valid = false;
      setValid(false);
      update();
    } else {
      setValid(true);

      const discount = pricetotal - (pricetotal * e) / 100;
      setDiscountedValue(discount);
      pricetotal -= discount;

      update();
    }
  };

  const addMoreService = async (ee) => {
    console.log('ee=>', ee);
    setServiceDuration(ee.duration);
    // setServiceId(item._id);
    const oldStatee = [...serviceid];
    oldStatee.push([ee._id]);
    setServiceId(oldStatee);
    setServicename(ee.serviceName);
    setServicePrice(ee.priceFrom);
    setServiceDurationBefore(ee.extraDurationBefore);
    setServiceDurationAfter(ee.extraDurationAfter);
    // setPriceTotal(item.priceTotal);
    const oldState = [...servicePanel];
    oldState.push({
      serviceDuration: ee.duration,
      servicename: ee.serviceName,
      servicePrice: ee.priceFrom,
      serviceDurationBefore: ee.extraDurationBefore,
      serviceDurationAfter: ee.extraDurationAfter,
    });
    setServicePanel(oldState);
    setView(false);
  };

  const deleteService = (id) => {
    const oldStatee = [...serviceid];
    // oldStatee.pop();
    oldStatee.splice(id, 1);
    setServiceId([...oldStatee]);
    const oldState = [...servicePanel];
    // oldState.pop();
    oldState.splice(id, 1);
    setServicePanel([...oldState]);
    // const oldState = [...servicePanel];
    // // oldState.pop(id);
    // oldState.splice(id, 1);
    // setServicePanel([...oldState]);
  };
  const addPackage = async (e) => {
    e.preventDefault();
    let packagePrice = 0;
    let packageDuration = 0;
    let packageSpecialPrice = 0;
    let packageDurationAfter = 0;
    let packageDurationBefore = 0;
    // pricingPanel.map((item, i) => {
    //   priceTotal += item.priceFrom;
    //   durationTotal += item.duration;
    //   specialPriceTotal += item.specialPrice;
    // });
    servicePanel.map((elem, i) => {
      console.log('elem=>', elem);
      packagePrice += parseInt(elem.servicePrice);
      packageDuration += parseInt(elem.serviceDuration);
      packageSpecialPrice += parseInt(elem.serviceSpecialPrice);
      packageDurationBefore += parseInt(elem.serviceDurationBefore);
      packageDurationAfter += parseInt(elem.serviceDurationAfter);
      // setPackageDurationBefore(parseInt(packageDurationBefore));
      // setPackageDurationAfter(parseInt(packageDurationAfter));
      setPackageSpecialPrice(parseInt(packageSpecialPrice));
      setPackageDuration(parseInt(packageDuration));
      setPackagePrice(parseInt(packagePrice));
    });
    let retailprice;
    if (retailPrice === '') {
      retailprice = packagePrice;
    } else {
      retailprice = retailPrice;
    }

    const payload = {
      packagename,
      packagedescription,
      packageCategoryId,
      onlineBooking,
      packagegender,
      scheduleType,
      priceType,
      retailPrice: retailprice,
      serviceid,
      packagePrice,
      packageDuration,
      packageSpecialPrice,
      packageDurationBefore,
      packageDurationAfter,
    };
    console.log('payload', payload);

    const validation = validatePackage({
      packagename,
      category: packageCategoryId,
      servicePanel,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    if (!valid) {
      return;
    }
    const saveData = await partnerInstance().post('/addPackage', payload);
    const { code, msg } = saveData.data;
    if (code === 200) {
      history.push('/partners/services');
      toast.success(msg, { containerId: 'B' });
      // clearValues();
    } else if (code === 409) {
      toast.error(msg, { containerId: 'B' });
    } else {
      toast.error(msg, { containerId: 'B' });
    }
  };
  let pricetotal = 0;
  const tduration = 0;
  const totaldur = 0;
  let h;
  let m;
  let hour;
  let minute;
  let dur = 0;

  return (
    <PartnerWrapper>
      <div className="package-services">
        <div className="container">
          <h2 className="admin-title">
            Bundle Definition{' '}
            <Link to="/partners/addservices">
              <i className="fa fa-angle-left" /> Back
            </Link>
          </h2>

          <div className="package-services-content">
            <Form onSubmit={addPackage}>
              <div className="row">
                <div className="col-md-6">
                  <div className="service-card">
                    <div className="service-body">
                      <Form.Group controlId="formBasicloginone">
                        <Form.Label>Bundle Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Bundle Name"
                          name="name"
                          autoComplete="off"
                          onChange={(e) => setPackagename(e.target.value)}
                          value={packagename}
                        />
                        {errors.packagename && (
                          <p className="error">{errors.packagename}</p>
                        )}
                      </Form.Group>

                      <Form.Group controlId="formBasicloginone">
                        <Form.Label>Bundle Category</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={(e) => setPackageCategoryId(e.target.value)}
                          value={packageCategoryId}
                        >
                          <option value="">Select Category</option>
                          {categories.map((el, i) => (
                            <>
                              <option key={i} value={el._id}>
                                {el.categoryname}
                              </option>
                            </>
                          ))}
                        </Form.Control>
                        {errors.category && (
                          <p className="error">{errors.category}</p>
                        )}
                      </Form.Group>

                      <Form.Group controlId="formBasicloginone">
                        <Form.Label>Bundle Description</Form.Label>
                        <Form.Control
                          placeholder="Add a short description"
                          as="textarea"
                          rows="3"
                          onChange={(e) =>
                            setPackagedescription(e.target.value)
                          }
                          value={packagedescription}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="service-card">
                    <div className="service-header">
                      <h2>Services</h2>
                      <p>Add services to your bundle</p>
                    </div>

                    <div className="service-body">
                      <div className="select-services">
                        {/* After Select the service this div will sjow selected services */}
                        <div className="select-categories">
                          {servicePanel.map((el, i) => {
                            pricetotal += parseInt(el.servicePrice);
                            dur += parseInt(el.serviceDuration);
                            // setTotalPrice(pricetotal);
                            return (
                              <>
                                <div className="select-list">
                                  <div>
                                    <h5>{el.servicename}</h5>

                                    <>
                                      <p>
                                        {/* {hours}h {minutes}min,{' '}
                                          {ele.pricingName} */}
                                        {Math.floor(
                                          parseInt(el.serviceDuration) / 60
                                        )}
                                        h {parseInt(el.serviceDuration) % 60}
                                        min
                                      </p>
                                    </>

                                    <div className="add-service-price">
                                      <span>
                                        {currency} {el.servicePrice}
                                      </span>
                                      <i
                                        onClick={() => deleteService(i)}
                                        className="fa fa-times"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                          {errors.servicePanel && (
                            <p className="error">{errors.servicePanel}</p>
                          )}
                        </div>

                        <div className="add-pricing-option">
                          <span onClick={handleViewShow}>
                            <i className="fa fa-plus" /> Select service
                          </span>
                          <div>
                            {/* {setRetailPrice(pricetotal)} */}
                            Total: ₹{pricetotal} ({Math.floor(dur / 60)}
                            h,
                            {dur % 60}
                            min)
                          </div>
                        </div>
                      </div>

                      <div className="extra-option">
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Appointment Definition</Form.Label>
                          <Form.Control
                            as="select"
                            onChange={(e) => setScheduleType(e.target.value)}
                            value={scheduleType}
                          >
                            <option value="sequence">
                              Services performed at the same time
                            </option>
                            <option value="parallel">
                              Services performed in parallel
                            </option>
                          </Form.Control>
                          <p className="treatment-info">
                            Services can be booked by sequential order, or in
                            parallel with the same start time. Learn more about
                            packages.
                          </p>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="service-card pdd-bt-89">
                    <div className="service-header">
                      <h2>Price</h2>
                    </div>

                    <div className="service-body">
                      <div className="service-pricing">
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Choose a Pricing Method</Form.Label>
                          <Form.Control
                            as="select"
                            onChange={(e) => setPriceType(e.target.value)}
                            value={priceType}
                          >
                            <option value="Service pricing">
                              Same as Service Price
                            </option>
                            <option value="Custom pricing">
                              Custom Pricing
                            </option>
                            <option value="Percentage discount">
                              Percentage Discount
                            </option>
                            <option value="Free">Free</option>
                          </Form.Control>
                        </Form.Group>
                        {console.log('pricetotal', pricetotal)}

                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Bundle Price</Form.Label>
                          <InputGroup>
                            {priceType === 'Free' ? (
                              <>
                                {' '}
                                <InputGroup.Prepend>
                                  <InputGroup.Text>₹</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                  placeholder="0.00"
                                  type="number"
                                  onChange={(e) =>
                                    setRetailPrice(e.target.value)
                                  }
                                  value={pricetotal}
                                  disabled
                                />{' '}
                                <p>Discounted By {pricetotal}</p>
                              </>
                            ) : priceType === 'Custom pricing' ? (
                              <>
                                {' '}
                                <InputGroup.Prepend>
                                  <InputGroup.Text>₹</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                  placeholder="0.00"
                                  type="number"
                                  onChange={(e) =>
                                    setRetailPrice(e.target.value) &
                                    handleCustomPrice(e.target.value)
                                  }
                                />
                                {/* {`discounted by ${discountedValue}`} */}
                                {discountedValue
                                  ? `discounted by ${discountedValue}`
                                  : ''}
                                {!valid ? (
                                  <p className="error">
                                    Enter a Price less than {pricetotal}
                                  </p>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : priceType === 'Percentage discount' ? (
                              <>
                                <InputGroup.Prepend>
                                  <InputGroup.Text>%</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                  placeholder="0.00"
                                  onChange={(e) =>
                                    setRetailPrice(e.target.value) &
                                    handlePercentDiscount(e.target.value)
                                  }
                                />
                                {discountedValue
                                  ? `discounted by ${discountedValue}`
                                  : ''}
                                {/* {`discounted by ${discountedValue}`} */}
                                {!valid ? (
                                  <p className="error">
                                    Please Enter value 100 or less
                                  </p>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <>
                                {' '}
                                <InputGroup.Prepend>
                                  <InputGroup.Text>₹</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                  placeholder="0.00"
                                  onChange={(e) =>
                                    setRetailPrice(e.target.value)
                                  }
                                  value={pricetotal}
                                  disabled
                                />
                              </>
                            )}
                          </InputGroup>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="login-button service-save">
                    <Button type="submit" className="l-btn">
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>

      <Modal
        className="category-popup select-service-popup"
        show={view}
        onHide={handleViewClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Services</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SelectServices
            services={services}
            setView={setView}
            setServiceId={setServiceId}
            setServicename={setServicename}
            setServiceDuration={setServiceDuration}
            setServicePrice={setServicePrice}
            setServicePanel={setServicePanel}
            servicePanel={servicePanel}
            addMoreService={addMoreService}
            servicesCopy={servicesCopy}
            setServices={setServices}
            currency={currency}
            setServiceDurationAfter={setServiceDurationAfter}
            setServiceDurationBefore={setServiceDurationBefore}
          />
        </Modal.Body>
      </Modal>
    </PartnerWrapper>
  );
};

export default PackageServices;

const SelectServices = ({
  currency,
  setServices,
  servicesCopy,
  services,
  setView,
  addMoreService,
  setServicename,
  setServiceId,
  setServiceDuration,
  setServicePrice,
  setServiceDurationBefore,
  setServiceDurationAfter,
  servicePanel,
  setServicePanel,
}) => {
  console.log('modal services', services);
  const [searchKeyword, setSearchKeyword] = useState('');
  const selectService = (ee, item) => {
    setServiceId(item._id);
    setServicename(item.servicename);
    setServiceDuration(ee.duration);
    setServicePrice(ee.priceFrom);
    setServiceDurationAfter(ee.extraDurationAfter);
    setServiceDurationBefore(ee.extraDurationBefore);
    setView(false);
  };
  console.log('servbic', services);
  const serviceSearch = (e) => {
    setSearchKeyword(e);
    const filteredServices = servicesCopy.filter((services) =>
      services.serviceid.filter(
        (el) => el.servicename.toLowerCase().indexOf(e.toLowerCase()) !== -1
      )
    );

    setServices(filteredServices);
    console.log('filtered services', filteredServices);
  };

  return (
    <div className="add-category">
      {services.length > 0 ? (
        <Form>
          <Form.Group controlId="formBasicloginone">
            <Form.Control
              type="text"
              placeholder="Search Service"
              name="category"
              autoComplete="off"
              value={searchKeyword}
              onChange={(e) => serviceSearch(e.target.value)}
            />
          </Form.Group>

          <div className="select-categories">
            {services.map((item, i) => (
              <Accordion>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                      {item.categoryname} <i className="fa fa-angle-right" />
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      {item.serviceid.length > 0 ? (
                        <>
                          {item.serviceid.map((ee, i) => (
                            <>
                              <div className="select-list">
                                {/* <div>
                                  <Form.Check type="checkbox" id="hair" />
                              </div> */}

                                <div
                                  onClick={() => {
                                    addMoreService(ee);
                                  }}
                                >
                                  <h5>{ee.serviceName}</h5>
                                  <p>
                                    {' '}
                                    {Math.floor(
                                      parseInt(ee.duration) / 60
                                    )} h, {parseInt(ee.duration) % 60}
                                    min
                                  </p>

                                  <div className="add-service-price">
                                    <span>
                                      {currency} {ee.priceFrom}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        <p>Please Add Service in this category</p>
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            ))}
          </div>

          {/* <div className="login-button">
  <Button type="submit" className="l-btn">
    Save
  </Button>
</div> */}
        </Form>
      ) : (
        <>
          <p>Please select category First</p>
        </>
      )}
    </div>
  );
};
