import React, { useState, useEffect, useCallback } from 'react';
import {
  Form,
  Table,
  Dropdown,
  Button,
  Modal,
  Tab,
  FormControl,
} from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { toast } from 'react-toastify';
import moment from 'moment';
import PartnerWrapper from '../partnerwrapper/wrapper';
import { partnerInstance } from '../../../config/axios';
// import { countrycode } from '../partners/register/code';
import { validateStaff, validateShiftTiming } from '../../../function/validate';
import history from '../../../config/history';
import './staff.css';

const StaffWorkingHour = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const [email, setEmail] = useState('');
  const [staffname, setStaffname] = useState('');
  // const [lastname, setLastname] = useState('');
  // const [staffTitle, setStaffTitle] = useState('');
  // const [note, setNote] = useState('');
  const currentDate = moment(new Date()).format('YYYY-MM-DD');
  const [startDate, setStartDate] = useState(currentDate);

  // const [country, setCountry] = useState('');
  const [staffs, setStaffs] = useState([]);
  const [staff, setStaff] = useState({});
  const [shift, setShift] = useState([]);
  const [staffId, setStaffId] = useState('');
  const [shiftId, setShiftId] = useState('');
  const [singleShift, setSingleShift] = useState([]);
  const [starttime, setStarttime] = useState('');
  const [endtime, setEndtime] = useState('');
  const [shiftStart, setShiftStart] = useState('');
  const [shiftEnd, setShiftEnd] = useState('');
  const [shiftPanel, setShiftPanel] = useState([
    {
      starttime: '09:00AM',
      endtime: '06:00PM',
    },
  ]);
  const [datePanel, setDatePanel] = useState([]);
  const [shiftRepeat, setShiftRepeat] = useState('weekly');
  const [shiftDate, setShiftDate] = useState('');
  const [weekList, setWeekList] = useState([]);
  const [limit, setLimit] = useState(7);
  const [skip, setSkip] = useState(0);
  const [staffShift, setStaffShift] = useState([]);
  const [shiftList, setShiftList] = useState([]);
  const [errors, setErrors] = useState({});
  const [firstDay, setFirstDay] = useState('');
  const [lastDay, setLastDay] = useState('');
  const [reflectChange, setReflectChange] = useState(false);
  const [numberOfMonths, setNumberOfMonths] = useState(2);
  // const [totalMinute, setTotalMinute] = useState('');
  // const [totalHour, setTotalHour] = useState('');
  const date = new Date();
  date.setDate(date.getDate() + 6);
  const [endDate, setEndDate] = useState(date);
  //   function addDays(dateObj, numDays) {
  //     dateObj.setDate(dateObj.getDate() + numDays);
  //     return dateObj;
  //   }
  //   const now = currentDate; // Current date.
  //   const nextWeek = addDays(now, 7); // Add 7 days.
  //   const date = new Date();
  //   date.setDate(date.getDate() + 7);
  //   console.log(date, 'next7th date');
  console.log('STARTDATE ', startDate);
  console.log(' ENDDATE', new Date(endDate));
  const arr = [];
  useEffect(() => {
    const daylist = getDaysArray(new Date(startDate), new Date(endDate));
    setWeekList(daylist);

    // str.substring(1, 4);
    const getStaffList = async () => {
      const staffData = await partnerInstance().get('/getStaffs');
      const { code, stafflist } = staffData.data;
      if (code === 200) {
        setStaffs(stafflist);
      }
    };
    getDateRange(startDate, endDate);
    getStaffList();
    getAllShift();
  }, []);

  const getDateRange = (startDate, endDate) => {
    console.log('getdaterange', startDate, endDate);
    const curr = new Date(startDate);
    const next = new Date(endDate);
    // const firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
    // const lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 6));
    console.log('firstday, lastday', curr, next);
    setFirstDay(curr.toString());
    setLastDay(next.toString());
  };

  const getAllShift = async () => {
    const allShiftData = await partnerInstance().get('/getAllShift');
    const { code, shiftlist } = allShiftData.data;
    if (code === 200) {
      setShiftList(shiftlist);
      console.log('getALLShift Response', shiftlist);

      // setSingleShift(shift);
      // setShiftPanel(shift[0].timing);
      //   setStaffname(stafflist.firstname);
      // setStaff(stafflist);
      // setShift(stafflist.shift);
    }
  };
  const getDaysArray = (start, end) => {
    console.log('start end', start, end);
    for (const dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  };
  //   const getAllShift = async () => {
  //     const allShiftData = await partnerInstance().get('/getAllShift');
  //     const { code, shiftlist } = allShiftData.data;
  //     if (code === 200) {
  //       setShiftList(shiftlist);
  //       // setSingleShift(shift);
  //       // setShiftPanel(shift[0].timing);
  //       // setStaffname(stafflist.firstname);
  //       // setStaff(stafflist);
  //       // setShift(stafflist.shift);
  //     }
  //   };

  const handleAfterRange = async () => {
    // setSkip(limit);
    // setLimit(limit + 7);
    setStartDate(endDate);
    const enddate = new Date(endDate);
    enddate.setDate(enddate.getDate() + 6);
    setEndDate(enddate);
    const dayslist = getDaysArray(new Date(endDate), new Date(enddate));
    setWeekList(dayslist);
    getDateRange(new Date(endDate), new Date(enddate));
  };
  const handleBeforeRange = async () => {
    // setSkip(skip - 7);
    // setLimit(skip);
    console.log('start date  & end Date', startDate, endDate);
    setStartDate(endDate);
    const enddate = new Date(startDate);
    enddate.setDate(enddate.getDate() - 6);
    setEndDate(enddate);
    const dayslist = getDaysArray(new Date(enddate), new Date(startDate));
    setWeekList(dayslist);
    getDateRange(new Date(enddate), new Date(startDate));
  };

  const getStaffById = async (id) => {
    const ID = {
      staffid: id,
    };
    const staffData = await partnerInstance().post('/getStaff', ID);
    const { code, stafflist } = staffData.data;
    if (code === 200) {
      const dataa = [stafflist];
      setShiftList(dataa);
      setStaffId(id);
      // setStaffname(stafflist.firstname);
      // setStaff(stafflist);
      // setShift(stafflist.shift);
    }
  };
  const getShiftById = async (shiftdate, staffid) => {
    const ID = {
      shiftDate: shiftdate,
      staffId: staffid,
    };
    const shiftdata = await partnerInstance().post('/getShiftById', ID);
    const { code, shiftData } = shiftdata.data;
    console.log('getShiftById RESPONSE===>', shiftData);
    if (code === 200) {
      //   const dataa = [shiftData];
      setSingleShift(shiftData);
      setShiftPanel(shiftData[0].timing);
    }
  };
  const handleStaffSelect = (id) => {
    getStaffById(id);
  };

  const handleShowEdit = (staffid, shiftdate) => {
    console.log('staffid & date', staffid, date);
    // setShiftId(id);
    setStaffId(staffid);
    setShiftDate(shiftdate);
    getShiftById(shiftdate, staffid);
    setShow(true);
  };

  //   const handleDateRange = async () => {
  //     const oldArray = [...shiftPanel];
  //     oldArray.push({
  //       starttime: '09:00AM',
  //       endtime: '06:00PM',
  //     });
  //     setShiftPanel(oldArray);
  //   };
  let totalhour = 0;
  let totalminute = 0;
  const Duration = (starttime, endtime) => {
    console.log(starttime, endtime);
    const startdur = moment(starttime, 'HH:mm:ss a');
    const enddur = moment(endtime, 'HH:mm:ss a');
    const duration = moment.duration(enddur.diff(startdur));
    const hours = parseInt(duration.asHours());
    const minutes = parseInt(duration.asMinutes()) % 60;
    totalhour += hours;
    totalminute += minutes;
    console.log('totlhour', totalhour);
    // setTotalHour(totalhour);
    // setTotalMinute(totalminute);

    console.log(`${hours} hour and ${minutes} minutes.`);
    return `${totalhour} hour and ${totalminute} minutes.`;
  };

  return (
    <div className="staff-working">
      <div className="container">
        {console.log('shiftList', shiftList)}

        <div className="shift-filter">
          <div className="schedule-filters">
            <div className="schedule-date-toolbar">
              <div className="btn-group js-date-toolbar">
                <div
                  className="btn btn-default navigate"
                  data-action="previous"
                  title="Previous"
                >
                  <i
                    className="fa fa-arrow-left"
                    aria-hidden="true"
                    onClick={handleBeforeRange}
                  />
                </div>
                <div
                  className="btn btn-default hidden-xs navigate active"
                  data-action="today"
                >
                  Today
                </div>
                <div
                  className="btn btn-default select-date"
                  title="Change Date"
                >
                  <span className="display-date">
                    {firstDay.substring(4, 10)} -{lastDay.substring(4, 10)}
                  </span>
                  <input
                    type="text"
                    name="date_from"
                    id="date_from"
                    value=""
                    readOnly="readonly"
                  />
                </div>
                <div
                  className="btn btn-default navigate"
                  data-action="next"
                  title="Next"
                >
                  <i
                    className="fa fa-arrow-right"
                    onClick={handleAfterRange}
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>

            <div className="form-inline">
              <div className="form-group">
                <div className="select-wrapper hidden" />
              </div>
              <div className="form-group">
                <div className="select-wrapper">
                  <select
                    name="employee_id"
                    id="employee_id"
                    className="form-control js-schedule-employee"
                    onChange={(e) => handleStaffSelect(e.target.value)}
                  >
                    <option>All</option>
                    {staffs.map((el, i) => (
                      <>
                        <option key={i} value={el._id}>
                          {el.firstname} {el.lastname}
                        </option>
                      </>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        {console.log('weeklist ,shiftList', weekList, shiftList)}

        <div className="shift-calendar">
          <div className="partner-table">
            <Table bordered responsive="lg">
              <thead>
                <tr>
                  <th>Associates</th>
                  {weekList.map((el, i) => (
                    <>
                      <th key={i}>
                        {new Date(el).toString().substring(0, 10)}
                      </th>
                    </>
                  ))}{' '}
                  {/* <th>Sat 14 Nov</th> */}
                </tr>
              </thead>

              {/* {shiftList.map((elem, i) => {
                elem.shift.map((ell, l) =>
                  console.log(
                    weekList.filter(
                      (e) =>
                        new Date(e).toString().substring(0, 10) ===
                        new Date(ell.date).toString().substring(0, 10)
                    )
                  )
                );
              })} */}
              {console.log('shiftList', shiftList)}

              <tbody>
                {shiftList.map((elem, i) => (
                  <tr>
                    <td>{elem.firstname}</td>

                    {elem.shift.map((ell, l) =>
                      weekList
                        .filter(
                          (e) =>
                            new Date(e).toString().substring(0, 10) ===
                            new Date(ell.date).toString().substring(0, 10)
                        )
                        .map((el, i) => (
                          <>
                            {new Date(el).toString().substring(0, 10) ===
                            new Date(ell.date).toString().substring(0, 10) ? (
                              <td
                                onClick={() =>
                                  handleShowEdit(elem._id, ell.date)
                                }
                              >
                                {' '}
                                {ell.timing.map((e, i) => (
                                  <span>
                                    {e.starttime} - {e.endtime}
                                  </span>
                                ))}
                              </td>
                            ) : (
                              <td
                                className="sunday-shift"
                                onClick={() =>
                                  handleShowEdit(elem._id, ell.date)
                                }
                              >
                                <i className="fa fa-plus" />
                              </td>
                            )}
                          </>
                        ))
                    )}

                    {/* {elem.shift.map((ell, l) =>
                      weekList.map((e, k) =>
                        // eslint-disable-next-line no-unused-expressions
                        new Date(e).toString().substring(0, 10) ===
                        new Date(ell.date).toString().substring(0, 10) ? (
                          <>
                            {console.log(
                              'if',
                              new Date(e).toString().substring(0, 10),
                              new Date(ell.date).toString().substring(0, 10)
                            )}
                            <td>
                              {' '}
                              <span>dd</span>
                            </td>
                          </>
                        ) : (
                          <>
                            {console.log('jj')}
                            <td className="sunday-shift" onClick={handleShow}>
                              <i className="fa fa-plus" />
                            </td>
                          </>
                        )
                      )
                    )} */}

                    {/* <>
                          <td onClick={() => handleShowEdit(elem._id, el)}>
                            {el.timing.map((e, i) => (
                              <span>
                                {e.starttime} -{e.endtime}
                              </span>
                            ))}
                          </td>
                        </> */}

                    {/* <td onClick={() => handleShowEdit(elem._id, el)}>

                          {el.timing.map((e, i) => (
                            <span>
                              {e.starttime} - {e.endtime}
                            </span>
                          ))}
                        </td> */}
                    {/* <td className="sunday-shift" onClick={handleShow}>
                            <i className="fa fa-plus" />
                          </td> */}
                    {/* <td>
                            <span>9:00am -5:00pm</span>
                          </td> */}
                    {/* <td className="sunday-shift" onClick={handleShow}>
                        <i className="fa fa-plus" />
                      </td>
                      <td>
                        <span>9:00am -5:00pm</span>
                      </td>
                      <td>
                        <span>9:00am -5:00pm</span>
                      </td>
                      <td>
                        <span>9:00am -5:00pm</span>
                      </td>
                      <td className="add-shift">
                        <i className="fa fa-plus" />
                      </td>
                      <td>
                        <span>9:00am -5:00pm</span>
                      </td>
                      <td>
                        <span>9:00am -5:00pm</span>
                      </td> */}
                  </tr>
                ))}

                {/* <tr>
                  <td>Syed Websul</td>
                  <td className="sunday-shift">
                    <i className="fa fa-plus" />
                  </td>
                  <td>
                    <span>9:00am -5:00pm</span>
                  </td>
                  <td className="add-shift">
                    <i className="fa fa-plus" />
                  </td>
                  <td>
                    <span>9:00am -5:00pm</span>
                  </td>
                  <td>
                    <span>9:00am -5:00pm</span>
                  </td>
                  <td>
                    <span>9:00am -5:00pm</span>
                  </td>
                  <td>
                    <span>9:00am -5:00pm</span>
                  </td>
                </tr>
                <tr> */}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      <Modal
        className="closedatemodal edithour"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title ">
            Edit {staffname} Hours
          </Modal.Title>
          <span>{new Date(shiftDate).toString().substring(0, 10)}</span>
        </Modal.Header>
        <Modal.Body>
          <EditShift
            shiftId={shiftId}
            // staffId={staffId}
            setShow={setShow}
            shift={shift}
            staffname={staffname}
            singleShift={singleShift}
            setShiftStart={setShiftStart}
            setShiftEnd={setShiftEnd}
            setShiftPanel={setShiftPanel}
            shiftPanel={shiftPanel}
            shiftStart={shiftStart}
            shiftEnd={shiftEnd}
            setStarttime={setStarttime}
            setEndtime={setEndtime}
            endtime={endtime}
            starttime={starttime}
            setShiftRepeat={setShiftRepeat}
            shiftRepeat={shiftRepeat}
            shiftDate={shiftDate}
            setShiftDate={setShiftDate}
            staffId={staffId}
            setWeekList={setWeekList}
            setErrors={setErrors}
            errors={errors}
            setReflectChange={setReflectChange}
          />
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="danger">
                                Delete
                            </Button> */}
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default StaffWorkingHour;

function EditShift({
  shift,
  shiftId,
  setShow,
  staffId,
  staffname,
  singleShift,
  setShiftStart,
  setShiftEnd,
  shiftStart,
  shiftEnd,
  setShiftPanel,
  shiftPanel,
  starttime,
  endtime,
  setShiftRepeat,
  shiftRepeat,
  shiftDate,
  setShiftDate,
  setStarttime,
  setEndtime,
  setWeekList,
  setErrors,
  errors,
  setReflectChange,
}) {
  const selectStartTime = (e, ele) => {
    setStarttime(e.target.value);
    shiftPanel.forEach((el) => {
      if (ele._id === el._id) {
        ele.starttime = e.target.value;
      }
    });
    setShiftPanel(shiftPanel);
  };
  const selectEndTime = (e, ele) => {
    setEndtime(e.target.value);
    shiftPanel.forEach((el) => {
      if (ele._id === el._id) {
        ele.endtime = e.target.value;
      }
    });

    // shiftPanel.forEach((el, j) => {
    //   if (i === j) {
    //     el.endtime = e.target.value;
    //   }
    // });
    setShiftPanel(shiftPanel);
  };
  const addMoreShift = async () => {
    const oldArray = [...shiftPanel];
    oldArray.push({
      starttime: '09:00AM',
      endtime: '06:00PM',
    });
    setShiftPanel(oldArray);
  };
  const deleteShiftPanel = (id) => {
    const oldArray = [...shiftPanel];
    oldArray.pop();
    setShiftPanel([...oldArray]);
  };

  const updateShift = async (e) => {
    e.preventDefault();
    // console.log('shiftpanle Night', shiftPanel, shiftRepeat);
    // const singleShift = [{shiftDate, shiftPanel, shiftRepeat}];
    let shiftdate;
    singleShift.map((el, i) => {
      shiftdate = el.date;
      setShiftDate(el.date);
    });

    const payloadd = {
      shiftDate,
      shiftPanel,
      shiftRepeat,
      staffId,
    };

    const validation = validateShiftTiming({
      shiftPanel,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }

    const updateshift = await partnerInstance().put('/editShift/', payloadd);
    const { code, msg } = updateshift.data;
    if (code === 200) {
      setReflectChange(true);
      setShow(false);
      toast.success(msg, { containerId: 'B' });
    } else if (code === 409) {
      toast.error(msg, { containerId: 'B' });
    } else {
      toast.error(msg, { containerId: 'B' });
    }
  };
  // {
  //   singleShift.map((el, i) => {
  //     setShiftDate(el.date);
  //   });
  // }

  return (
    <>
      <Form onSubmit={updateShift}>
        <Row>
          {/* {el.timing.map((ele) => ( */}

          {shiftPanel.map((ele, i, array) => (
            <>
              <div className="col-md-6">
                <Form.Group>
                  <Form.Label>Shift Start</Form.Label>
                  <Form.Control
                    as="select"
                    value={ele.starttime}
                    // value={shiftPanel[ele].}
                    onChange={(e) => selectStartTime(e, ele)}
                  >
                    {/* <option>{ele.starttime}</option> */}
                    <option value={ele.starttime}>{ele.starttime}</option>
                    <option value="9:05AM">9:05am</option>
                    <option value="9:10AM">9:10am</option>
                    <option value="9:15AM">9:15am</option>
                    <option value="9:20AM">9:20am</option>
                    <option value="5:05PM">5:05pm</option>
                    <option value="5:10PM">5:10pm</option>
                    <option value="5:15PM">5:15pm</option>
                    <option value="5:20PM">5:20pm</option>
                  </Form.Control>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group>
                  <Form.Label>Shift End</Form.Label>
                  {array.length > 1 && (
                    <i
                      onClick={() => deleteShiftPanel(i)}
                      className="fa fa-times cross"
                      aria-hidden="true"
                    />
                  )}
                  <Form.Control
                    as="select"
                    value={ele.endtime}
                    onChange={(e) => selectEndTime(e, ele)}
                  >
                    <option value={ele.endtime}>{ele.endtime}</option>
                    <option value="9:05AM">9:05am</option>
                    <option value="9:10AM">9:10am</option>
                    <option value="9:15AM">9:15am</option>
                    <option value="9:20AM">9:20am</option>
                    <option value="5:05PM">5:05pm</option>
                    <option value="5:10PM">5:10pm</option>
                    <option value="5:15PM">5:15pm</option>
                    <option value="5:20PM">5:20pm</option>
                  </Form.Control>
                </Form.Group>
              </div>
            </>
          ))}

          <div className="col-md-12">
            {/* {errors.shiftPanel && (
                  <p className="error">{errors.shiftPanel}</p>
                )} */}
            <Form.Group>
              <Button className="add-shift" onClick={addMoreShift}>
                Add another shift
              </Button>
            </Form.Group>
          </div>

          <div className="col-md-12">
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>Repeat</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => setShiftRepeat(e.target.value)}
                // value={el.repeat}
              >
                <option value="weekly">Weekly</option>
                <option value="dontrepeat">Don't repeat</option>
              </Form.Control>
            </Form.Group>
          </div>
        </Row>
        <Button type="submit">Submit</Button>
      </Form>
    </>
  );
}
