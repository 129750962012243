import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import PartnerWrapper from '../partnerwrapper/wrapper';
import { partnerInstance } from '../../../config/axios';
import { validateAccountSetting } from '../../../function/validate';
import './style.css';

const AccountSetting = () => {
  const [companyName, setCompanyName] = useState('');
  const [timeZone, setTimezone] = useState('');
  const [timeFormat, setTimeFormat] = useState('');
  const [weekStart, setWeekStart] = useState('');
  const [clientNotificationLanguage, setClientNotificationLanguage] = useState(
    ''
  );
  const [staffNotificationLanguage, setStaffNotificationLanguage] = useState(
    ''
  );
  const [website, setWebsite] = useState('');
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [timezones, setTimezones] = useState({});
  const [address, setAddress] = useState([]);
  const [errors, setErrors] = useState({});
  const [currency, setCurrency] = useState('');

  useEffect(() => {
    const getInvoice = async () => {
      const accountData = await partnerInstance().get('/getPartnerAccount');
      const { code, partnerData } = accountData.data;
      console.log('account', partnerData);
      if (code === 200) {
        setCompanyName(partnerData.companyName);
        setTimezone(partnerData.timezone);
        setTimeFormat(partnerData.timeformat);
        setWeekStart(partnerData.weekStart);
        setClientNotificationLanguage(partnerData.clientNotificationLanguage);
        setStaffNotificationLanguage(partnerData.staffNotificationLanguage);
        setWebsite(partnerData.website);
        setFacebook(partnerData.facebook);
        setInstagram(partnerData.instagram);
        setAddress(partnerData.address);
        setCurrency(partnerData.currency);
      }
    };
    const getTimezones = async () => {
      const timezoneData = await partnerInstance().get('/timezones');
      const { code, timezonesdata } = timezoneData.data;
      if (code === 200) {
        setTimezones(timezonesdata);
      }
    };

    getTimezones();
    getInvoice();
  }, []);

  const updateAccount = async (e) => {
    e.preventDefault();
    const payload = {
      companyName,
      timezone: timeZone,
      timeformat: timeFormat,
      weekStart,
      clientNotificationLanguage,
      staffNotificationLanguage,
      website,
      facebook,
      instagram,
    };
    const validation = validateAccountSetting({
      companyName,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    const accountData = await partnerInstance().put('/partnerAccount', payload);
    const { code, msg } = accountData.data;
    if (code === 200) {
      toast.success(msg, { containerId: 'B' });
      // history.push('/partners/staff');
      // clearValues();
    } else {
      toast.error(msg, { containerId: 'B' });
    }
  };

  return (
    <PartnerWrapper>
      <div className="account-setting">
        <div className="container">
              
          <h2 className="admin-title">Business Partner Setup
          <Link className="text-Primary" to="/partners/setup">
            Back
          </Link></h2>
           <div className="back-setup">
            <Form onSubmit={updateAccount}>
            <div className="row">
              <div className="col-md-12">
                <div className="business-card info-card">
                  <Card>
                    <Card.Img variant="top" />
                    <Card.Body>
                        <Form.Group>
                          <Form.Label>Business Name</Form.Label>

                          <Form.Control
                            size="lg"
                            type="text"
                            placeholder=""
                            onChange={(e) => setCompanyName(e.target.value)}
                            value={companyName}
                          />
                          {errors.companyName && (
                            <p className="error">{errors.companyName}</p>
                          )}
                        </Form.Group>
                        {console.log('address', address, currency)}
                        {address &&
                          address.map((el, i) => (
                            <p className="text-12">
                              Your country is set to{' '}
                              <strong>{el.country}</strong> with{' '}
                              <strong>{currency}</strong> currency. To change
                              these settings, please{' '}
                              <span className="text-primary">
                                contact our support team
                              </span>{' '}
                              .
                            </p>
                          ))}
                      
                    </Card.Body>
                  </Card>
                </div>

               </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                <div className="Time-card info-card">
                  <Card>
                    <Card.Img variant="top" />
                    <Card.Body>
                      <Card.Title>Timezone Settings</Card.Title>
                        <Form.Group>
                          <Form.Label>Time zone</Form.Label>

                          <Form.Control
                            as="select"
                            size="lg"
                            defaultValue={timeZone}
                            selected={timeZone}
                            value={timeZone}
                            onChange={(e) => setTimezone(e.target.value)}
                          >
                            {Object.keys(timezones).map((el, i) => (
                              <option value={timezones[el].name}>
                                {timezones[el].name}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Time format</Form.Label>

                          <Form.Control
                            as="select"
                            size="lg"
                            value={timeFormat}
                            onChange={(e) => setTimeFormat(e.target.value)}
                          >
                            <option>12 hours (e.g. 9:00pm)</option>
                            <option>24 hours (e.g. 21:00pm)</option>
                          </Form.Control>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Week start</Form.Label>

                          <Form.Control
                            as="select"
                            size="lg"
                            value={weekStart}
                            onChange={(e) => setWeekStart(e.target.value)}
                          >
                            <option value="sunday">Sunday</option>
                            <option value="monday">Monday</option>
                            <option value="tuesday">Tuesday</option>
                            <option value="wednesday">Wednesday</option>
                            <option value="thursday">Thursday</option>
                            <option value="friday">Friday</option>
                            <option value="saturday">Saturday</option>
                          </Form.Control>
                        </Form.Group>
                    </Card.Body>
                  </Card>
                </div>
                </div>
              <div className="col-md-6">
                <div className="business-card info-card">
                  <Card>
                    <Card.Img variant="top" />
                    <Card.Body>
                      <Card.Text>
                        <Card.Title>Social Connect</Card.Title>
                        <Form.Group>
                          <Form.Label>Website</Form.Label>

                          <InputGroup className="mb-2 mr-sm-2">
                            <InputGroup.Prepend>
                              <InputGroup.Text><i className="fa fa-globe" /></InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              id="inlineFormInputGroupUsername2"
                              placeholder="Website"
                              onChange={(e) => setWebsite(e.target.value)}
                              value={website}
                            />
                          </InputGroup>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Facebook page</Form.Label>

                          <InputGroup className="mb-2 mr-sm-2">
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                <i className="fa fa-facebook" />
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              id="inlineFormInputGroupUsername2"
                              placeholder="Username"
                              onChange={(e) => setFacebook(e.target.value)}
                              value={facebook}
                            />
                          </InputGroup>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Instagram page</Form.Label>

                          <InputGroup className="mb-2 mr-sm-2">
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                <i className="fa fa-instagram" />
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              id="inlineFormInputGroupUsername2"
                              placeholder="Username"
                              onChange={(e) => setInstagram(e.target.value)}
                              value={instagram}
                            />
                          </InputGroup>
                        </Form.Group>
                      </Card.Text>
                    </Card.Body>
                   
                  </Card>
                </div>
                </div>
              </div>
           <div className="row">
              <div className="account-save mt-2 col-md-12">
                <Button type="submit" className="btn btn-primary">
                  Save
                </Button>
              </div>
              </div>

            </Form>
          </div>
        </div>
      </div>
    </PartnerWrapper>
  );
};

export default AccountSetting;
