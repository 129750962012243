import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Table, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Closedatemodal from './closedatemodal';
import { partnerInstance } from '../../../config/axios';

const StaffMember = () => {
  const [staffs, setStaffs] = useState([]);
  const getStaff = async () => {
    const staffData = await partnerInstance().get('/getStaffs');
    const { code, stafflist } = staffData.data;
    if (code === 200) {
      setStaffs(stafflist);
    }
  };
  useEffect(() => {
    getStaff();
  }, []);
  const editStaff = (id) => {
    window.location.href = `/partners/editstaff/?id=${id}`;
  };
  const deleteStaff = async (id) => {
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure to delete this Staff.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            const deleteStaff = await partnerInstance().post('/deleteStaff/', {
              deleteId: id,
            });
            const { code } = deleteStaff.data;
            if (code === 200) {
              toast.success('Staff Deleted Successfully', {
                containerId: 'B',
              });
              getStaff();
            }
          },
        },
        {
          label: 'No',
          onClick: () => alert('Click No'),
        },
      ],
    });

    const deleteStaff = await partnerInstance().post('/deleteStaff/', {
      deleteId: id,
    });
    const { code } = deleteStaff.data;
    if (code === 200) {
      getStaff();
    }
  };

  return (
    <div className="close-staff">
      <div className="container">
        <div className="close-date user-12">
          <Link to="/partners/addstaff">Add Staff</Link>
        </div>

        <div className="partner-table">
          <Table responsive="lg">
            <thead>
              <tr>
                <th>Name</th>
                <th>Associate Role</th>
                <th>Email</th>
                <th>Mobile No</th>
                <th>Permission level</th>
              </tr>
            </thead>
            <tbody>
              {staffs.map((el) => (
                <tr>
                  <td onClick={() => editStaff(el._id)}>
                    {el.firstname}
                    {' '}
                    {el.lastname}
                  </td>
                  <td onClick={() => editStaff(el._id)}>
                    {el.staffTitle ? el.staffTitle : 'N/A'}
                  </td>

                  <td onClick={() => editStaff(el._id)}>
                    {el.email ? el.email : 'N/A'}
                  </td>
                  <td onClick={() => editStaff(el._id)}>
                    {el.phone ? el.phone : 'N/A'}
                  </td>
                  <td onClick={() => editStaff(el._id)}>
                    {el.userpermissiontype ? el.userpermissiontype : 'N/A'}
                  </td>
                  {el._id !== localStorage.getItem('userid') ? (
                    <td>
                      <Button variant="danger" onClick={() => deleteStaff(el._id)}>Delete</Button>
                    </td>
                  ) : (
                    ''
                  )}
                </tr>

                //  <>  </>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default StaffMember;
